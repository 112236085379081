import React, { useEffect } from "react";
import { View, Card, Text, Image, TouchableOpacity } from "react-native-ui-lib";
import ListingScreen from "../../../screens/MainScreens/CRUD/ListingScreen";
import { api } from "../../../services/api";
import { Alert, DeviceEventEmitter, Platform } from "react-native";
import { CustomSelect } from "../../../mycomponents/DynamicForm";

export const leadfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const LeadMeetingComponent = ({ field, useForm, tKey, tIndex }) => {
        const lead_id = useForm((state) => state.formObject['_id']);

        const props = {
            moduleName: 'LeadMeetings',
            hideWrapper: true,
            extraFilters: { lead_id: lead_id ?? null },
            hideViews: true,
        }

        return <View w-100 flex>
            <ListingScreen hideFAB={true} {...props} title={" "} default_values={{
                lead_id: lead_id,
                is_default_values: true
            }} />
        </View>
    };

    const SalesComponent = ({ field, useForm, tKey, tIndex }) => {
        const lead_id = useForm((state) => state.formObject['_id']);

        const props = {
            moduleName: 'Sales',
            hideWrapper: true,
            extraFilters: { lead_id: lead_id ?? null },
            hideViews: true,
        }

        return <View w-100 flex>
            <ListingScreen hideFAB={true} {...props} title={" "} default_values={{
                lead_id: lead_id,
                is_default_values: true
            }} />
        </View>
    };

    let formFields = [
        {
            label: 'Profile Picture',
            key: 'profile_picture',
            type: 'upload',
            placeholder: 'Upload profile picture',
            visible: true,
            width: '100%',
            mimeType: 'image/*',
            moduleName: moduleName,
            tab: 'Lead Information'
        },
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter Name',
            visible: true,
            width: '100%',
            required: true,
            moduleName: moduleName,
            tab: 'Lead Information'
        },
        {
            label: 'Email',
            key: 'email',
            type: 'text',
            placeholder: 'Enter Email',
            visible: true,
            width: '100%',
            required: true,
            moduleName: moduleName,
            tab: 'Lead Information'
        },
        {
            label: 'Phone',
            key: 'phone',
            type: 'text',
            placeholder: 'Enter Phone',
            visible: true,
            width: '100%',
            required: true,
            showCountryCode: true,
            moduleName: moduleName,
            tab: 'Lead Information',
            onBlur: async ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                if (!isEdit) {
                    const response = await api.customRoute('Leads/action', {
                        action: 'checkLeadWithSameNumber',
                        phone: value
                    });

                    if (response.success) {
                        if (Platform.OS == 'web') {
                            alert(response.message)
                        } else {
                            Alert.alert(response.message)
                        }
                    }
                }

            }
        },
        {
            label: 'Date of Birth',
            key: 'date_of_birth',
            type: 'date',
            placeholder: 'Enter Date of Birth',
            visible: true,
            width: '100%',
            moduleName: moduleName,
            tab: 'Lead Information',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                // update age 
                setObj({
                    age: Math.floor((new Date() - new Date(value)) / 1000 / 60 / 60 / 24 / 365),
                    updateVisiblity: Math.random()
                })
            }
        },
        {
            label: 'Age',
            key: 'age',
            type: 'number',
            placeholder: 'Enter Age',
            visible: (obj) => obj.date_of_birth ? true : false,
            width: '100%',
            moduleName: moduleName,
            tab: 'Lead Information',
            value: value?.date_of_birth ? Math.floor((new Date() - new Date(value?.date_of_birth)) / 1000 / 60 / 60 / 24 / 365) : "",
        },
        {
            label: 'NRIC No',
            key: 'nric_no',
            type: 'text',
            placeholder: 'Enter NRIC No',
            visible: true,
            width: '100%',
            moduleName: moduleName,
            tab: 'Lead Information'
        },
        {
            label: 'Policies Interested In',
            key: 'policy_ids',
            type: 'select',
            placeholder: 'Select Policies Interested In',
            visible: true,
            width: '100%',
            moduleName: moduleName,
            tab: 'Lead Information',
            multiple: true,
            options: response.Policies ? response.Policies.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };

                OpenModalCustom({ newModule: "Policies", field_key: 'policy_ids', array: true, params })
            },
        },
        {
            label: 'Lead Source',
            key: 'lead_source_id',
            type: 'select',
            placeholder: 'Select Lead Source',
            visible: true,
            width: '100%',
            moduleName: moduleName,
            tab: 'Lead Information',
            options: response.LeadSources ? response.LeadSources.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };

                OpenModalCustom({ newModule: "LeadSources", field_key: 'lead_source_id', params })
            },
        },
        {
            label: "Assigned FSC's",
            key: 'assigned_fsc_ids',
            type: 'select',
            placeholder: 'Select Lead Owner',
            visible: true,
            width: '100%',
            required: true,
            moduleName: moduleName,
            multiple: true,
            tab: 'Lead Information',
            options: response.Employees ? response.Employees.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
            group_by: "custom_grouping",
        },
        {
            label: 'Lead Status',
            key: 'lead_status',
            type: 'select',
            placeholder: 'Select Lead Status',
            visible: true,
            width: '100%',
            moduleName: moduleName,
            tab: 'Lead Information',
            options: [
                { label: "Pending", value: 'Pending' },
                { label: "FSCs Assigned", value: 'FSCs Assigned' },
                { label: 'Attempted to Contact', value: 'Attempted to Contact' },
                { label: 'Contacted', value: 'Contacted' },
                { label: 'Replacement', value: 'Replacement' },
                { label: 'Junk Lead', value: 'Junk Lead' },
                { label: 'Lost Lead', value: 'Lost Lead' },
                { label: 'Converted', value: 'Converted' },
                { label: 'Closed', value: 'Closed' },
            ],
            value: 'Pending',
        },

        {
            label: 'FSC Assigned Date',
            key: 'assign_date',
            type: 'date',
            placeholder: 'Enter FSC Assigned Date',
            visible: view ? true : false,
            width: '100%',
            tab: 'Lead Information',
        },
        {
            label: 'FB Username',
            key: 'fb_username',
            type: 'text',
            placeholder: 'Enter FB Username',
            visible: true,
            width: '100%',
            moduleName: moduleName,
            tab: 'Lead Information'
        },
        {
            label: 'IG Username',
            key: 'ig_username',
            type: 'text',
            placeholder: 'Enter IG Username',
            visible: true,
            width: '100%',
            moduleName: moduleName,
            tab: 'Lead Information'
        },
        {
            label: 'Remarks',
            key: 'remarks',
            type: 'editor',
            placeholder: 'Enter Remarks',
            visible: true,
            width: '100%',
            moduleName: moduleName,
            tab: 'Lead Information'
        },
        {
            type: 'custom',
            visible: true,
            width: '100%',
            tab: 'Meetings/Calls',
            component: LeadMeetingComponent,
        },

        {
            type: 'custom',
            visible: true,
            width: '100%',
            tab: 'Sales',
            component: SalesComponent,
        },
    ];

    if (!value?._id) {
        return formFields.filter((field) => field.tab == 'Lead Information');
    }

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '100%'
            });
        });
    }

    return formFields;
}

export const leadMobileCard = ({ item, index, getFieldValue, onRowClick, valueText, columns, DeleteCard, actionFunctions, SidebarStatus }) => {
    return <Card flex style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: 10 }}>
        <TouchableOpacity padding-10 flex onPress={onRowClick ? () => {
            onRowClick && onRowClick(item);
        } : null}>

            <View marginB-10 row spread style={{ width: '100%' }}>
                {columns[0] ? <View center backgroundColor="#9ec1e9" style={{ width: 60, height: 60, borderRadius: 10 }}>
                    {(item.profile_picture) ?
                        <Image source={{
                            uri: item.profile_picture
                        }} style={{ width: 60, height: 60, resizeMode: 'cover', borderRadius: 10 }} /> :
                        <Text style={{ color: 'white', fontSize: 20 }}>{getFieldValue(item, columns[0].field, columns[0]) ? "" + getFieldValue(item, columns[0].field)[0] : ""}</Text>}
                </View> : null}

                <View flex marginL-10>
                    <View row>
                        <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                            {columns[0] ? getFieldValue(item, columns[0].field, columns[0]) : ""}
                        </Text>
                        <View marginL-10>
                            {item.approvalStatus == 'Pending' && <Text style={{ color: '#ffc93a' }}>(Pending)</Text>}
                            {item.approvalStatus == 'Rejected' && <Text style={{ color: '#cc1010' }}>(Rejected)</Text>}
                        </View>
                    </View>

                    <View>
                        <View flex>
                            {columns.length > 2 ? <View row marginB-6 flex>
                                <View>
                                    <Text lightCardTitle>{columns[1] ? columns[1].label : ""}: </Text>
                                </View>
                                <View flex>
                                    {valueText(columns[1])}
                                </View>
                            </View> : null}
                            {columns.length > 3 ? <View row marginB-6 flex>
                                <View>
                                    <Text lightCardTitle>{columns[2] ? columns[2].label : ""}: </Text>
                                </View>
                                <View flex>
                                    {valueText(columns[2])}
                                </View>
                            </View> : null}
                        </View>
                    </View>
                </View>
            </View>

            {columns.length > 4 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
            {columns.length > 4 ? <View style={{ width: '100%' }}>
                {columns.length > 4 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{columns[3] ? columns[3].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(columns[3])}
                    </View>
                </View> : null}
                {columns.length > 5 ? <View marginV-5 row flex>
                    <View>
                        <Text lightCardTitle>{columns[4] ? columns[4].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(columns[4])}
                    </View>
                </View> : null}
            </View> : null}

            {columns.length > 6 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
            {columns.length > 6 ? <View style={{ width: '100%' }}>
                {columns.length > 6 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{columns[5] ? columns[5].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(columns[5])}
                    </View>
                </View> : null}
                {columns.length > 7 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{columns[6] ? columns[6].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(columns[6])}
                    </View>
                </View> : null}
            </View> : null}

            {columns.length > 8 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
            {columns.length > 8 ? <View row spread style={{ width: '100%' }}>
                {columns.length > 8 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{columns[7] ? columns[7].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(columns[7])}
                    </View>
                </View> : null}
                {columns.length > 9 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{columns[8] ? columns[8].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(columns[8])}
                    </View>
                </View> : null}
            </View> : null}

        </TouchableOpacity>
        {actionFunctions ? <TouchableOpacity>
            <SidebarStatus color={"#397CF2"}
                text={item.status}
                actionFunctions={actionFunctions} item={item} radius={10} />
        </TouchableOpacity> : null}
    </Card>;
}

const QuickFilterComponent = ({ customTabFilter, setCustomTabFilter }) => {
    const [leadStatus, setLeadStatus] = React.useState("ALL");

    useEffect(() => {
        if (leadStatus == "ALL") {
            const filter = {
                ...customTabFilter,
                lead_status: null
            };

            delete filter.lead_status;
            setCustomTabFilter(filter)
        } else {
            setCustomTabFilter({
                ...customTabFilter,
                lead_status: leadStatus
            })
        }
    }, [leadStatus])

    return <View right row marginB-10>
        <View style={{
            width: 300
        }}>
            <CustomSelect value={leadStatus} options={[
                { label: "ALL", value: 'ALL' },
                { label: "Pending", value: 'Pending' },
                { label: "FSCs Assigned", value: 'FSCs Assigned' },
                { label: 'Attempted to Contact', value: 'Attempted to Contact' },
                { label: 'Contacted', value: 'Contacted' },
                { label: 'Replacement', value: 'Replacement' },
                { label: 'Junk Lead', value: 'Junk Lead' },
                { label: 'Lost Lead', value: 'Lost Lead' },
                { label: 'Converted', value: 'Converted' },
                { label: 'Closed', value: 'Closed' },
            ]} onChange={(value) => {
                setLeadStatus(value);
            }} />
        </View>
    </View>;
}

export const leadMain = {
    fields: leadfields,
    mobileCard: leadMobileCard,
    customActions: (moduleName, navigationFn, route, row, setOpenModal, openCustomModal) => {
        let options = [];

        if (row.lead_status == 'FSCs Assigned') {
            options = [
                {
                    label: 'Attempted to Contact',
                    key: 'attempted_to_contact',
                    fn: (props) => {
                        const row = props.row;
                        row.lead_status = 'Attempted to Contact';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                },
                {
                    label: 'Contacted',
                    key: 'contacted',
                    fn: (props) => {
                        const row = props.row;
                        row.lead_status = 'Contacted';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                },
                {
                    label: 'Junk Lead',
                    key: 'junk_lead',
                    fn: (props) => {
                        const row = props.row;
                        row.lead_status = 'Junk Lead';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                },
                {
                    label: 'Lost Lead',
                    key: 'lost_lead',
                    fn: (props) => {
                        const row = props.row;
                        row.lead_status = 'Lost Lead';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                },
                {
                    label: 'Converted',
                    key: 'converted',
                    fn: (props) => {
                        const row = props.row;
                        row.lead_status = 'Converted';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                },
                {
                    label: 'Closed',
                    key: 'closed',
                    fn: (props) => {
                        const row = props.row;
                        row.lead_status = 'Closed';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                },
            ]
        } else if (row.lead_status == 'Attempted to Contact') {
            options = [
                {
                    label: 'Contacted',
                    key: 'contacted',
                    fn: (props) => {
                        const row = props.row;
                        row.lead_status = 'Contacted';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                },
                {
                    label: 'Junk Lead',
                    key: 'junk_lead',
                    fn: (props) => {
                        const row = props.row;
                        row.lead_status = 'Junk Lead';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                },
                {
                    label: 'Lost Lead',
                    key: 'lost_lead',
                    fn: (props) => {
                        const row = props.row;
                        row.lead_status = 'Lost Lead';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                },
                {
                    label: 'Converted',
                    key: 'converted',
                    fn: (props) => {
                        const row = props.row;
                        row.lead_status = 'Converted';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                },
                {
                    label: 'Closed',
                    key: 'closed',
                    fn: (props) => {
                        const row = props.row;
                        row.lead_status = 'Closed';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                },
            ]
        } else if (row.lead_status == 'Contacted') {
            options = [
                {
                    label: 'Junk Lead',
                    key: 'junk_lead',
                    fn: (props) => {
                        const row = props.row;
                        row.lead_status = 'Junk Lead';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                },
                {
                    label: 'Lost Lead',
                    key: 'lost_lead',
                    fn: (props) => {
                        const row = props.row;
                        row.lead_status = 'Lost Lead';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                },

                {
                    label: 'Converted',
                    key: 'converted',
                    fn: (props) => {
                        const row = props.row;
                        row.lead_status = 'Converted';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                },
                {
                    label: 'Closed',
                    key: 'closed',
                    fn: (props) => {
                        const row = props.row;
                        row.lead_status = 'Closed';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                },
            ]
        } else if (row.lead_status == 'Junk Lead') {
            options = [

            ]
        } else if (row.lead_status == 'Lost Lead') {

        } else if (row.lead_status == 'Converted') {
            options = [
                {
                    label: 'Closed',
                    key: 'closed',
                    fn: (props) => {
                        const row = props.row;
                        row.lead_status = 'Closed';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                },
            ]
        }

        options = [
            ...options.map((item) => {
                return { ...item, ignore_permission: true }
            }),
        ]

        return options;
    },
    customColumnRender: {
        lead_status: (value, row, column) => {
            let color = '#007bff';

            if (row.lead_status == 'FSCs Assigned') {
                color = '#ffc93a';
            } else if (row.lead_status == 'Attempted to Contact') {
                color = '#ffc93a';
            } else if (row.lead_status == 'Contacted') {
                color = '#ffc93a';
            } else if (row.lead_status == 'Junk Lead') {
                color = '#cc1010';
            } else if (row.lead_status == 'Lost Lead') {
                color = '#cc1010';
            } else if (row.lead_status == 'Converted') {
                color = '#ffc93a';
            } else if (row.lead_status == 'Closed') {
                color = '#cc1010';
            }

            const tinycolor = require("tinycolor2");
            const lighterColor = tinycolor(color).lighten(45).toString();

            return <View left>
                <View paddingH-8 paddingV-3 style={{ backgroundColor: lighterColor, borderRadius: 5 }}>
                    <Text
                        monoTableBody={false}
                        monoTableHeading={false}
                        style={[
                            column.customCellStyle,
                            { maxWidth: '100%' },
                            { color: color },
                            { fontFamily: 'SourceSansProSemiBold' }
                        ]} ellipsizeMode={'tail'} numberOfLines={1}>
                        {value}
                    </Text>
                </View>
            </View>
        }
    },
    QuickFilterComponent,
}