// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, RefreshControl } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, Avatar, TouchableOpacity } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, DangerButton, Heading, Row, SecondaryButton, CustomTextField, Label, CustomCheckbox } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import dayjs from "dayjs"
import { Camera, CameraType } from 'expo-camera';
import Svg, { G, Path } from "react-native-svg"
import Footer from "./Footer"
import Header from "./Header"
import { FlashList } from "@shopify/flash-list"

const isDesktop = Dimensions.get('window').width >= 768;

const filterIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={800}
        height={800}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <Path
            fill="#000"
            fillRule="evenodd"
            d="M15 10.5A3.502 3.502 0 0 0 18.355 8H21a1 1 0 1 0 0-2h-2.645a3.502 3.502 0 0 0-6.71 0H3a1 1 0 0 0 0 2h8.645A3.502 3.502 0 0 0 15 10.5zM3 16a1 1 0 1 0 0 2h2.145a3.502 3.502 0 0 0 6.71 0H21a1 1 0 1 0 0-2h-9.145a3.502 3.502 0 0 0-6.71 0H3z"
            clipRule="evenodd"
        />
    </Svg>
)

const deleteIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={800}
        height={800}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <Path
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M10 12v5M14 12v5M4 7h16M6 10v8a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3v-8M9 5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2H9V5Z"
        />
    </Svg>
)

const HamIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={800}
        height={800}
        viewBox="0 0 24 24"
        {...props}
    >
        <G fill="none" fillRule="evenodd">
            <Path d="M0 0h24v24H0z" />
            <Path
                stroke="#0C0310"
                strokeLinecap="round"
                strokeWidth={2}
                d="M5 7h14M5 17h14M5 12h14"
            />
        </G>
    </Svg>
)

const plusIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={800}
        height={800}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <Path
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 12h16m-8-8v16"
        />
    </Svg>
)

const minusIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={800}
        height={800}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <Path
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 12h12"
        />
    </Svg>
)

const Shop = (props) => {
    const [search, setSearch] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [sizes, setSizes] = useState([]);
    const [colors, setColors] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [expandedOptions, setExpandedOptions] = useState(["categories", "price", "size", "color", "material"]);

    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [sizeOptions, setSizeOptions] = useState([]);
    const [colorOptions, setColorOptions] = useState([]);
    const [materialOptions, setMaterialOptions] = useState([]);

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const getProducts = async () => {
        const response = await api.customRoute("shop/getProducts", {
            search,
            minPrice,
            maxPrice,
            sizes,
            colors,
            materials,
            page: currentPage,
            perPage,

        });
        setProducts(response.data.data);
        setTotal(response.data.total);
        setCurrentPage(response.data.currentPage);
        setPerPage(response.data.perPage);
        setHasNextPage(response.data.hasNextPage);
    };

    useEffect(() => {
        getProducts();
    }, [search, minPrice, maxPrice, sizes, colors, materials]);

    return (<>
        <ScrollView style={{
            height: Dimensions.get("window").height,
        }}>
            <Card>
                <Header />
                <View row spread marginT-20>
                    <Row style={{ width: 300 }}>
                        <Col>
                            <View paddingL-25>
                                <View style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    paddingBottom: 15,
                                    borderBottom: '1px solid #DCDCDC',
                                }}>
                                    <View>
                                        <View style={{
                                            marginRight: 10,
                                        }}>
                                            {filterIcon({ width: 20, height: 20, fill: "black" })}
                                        </View>
                                    </View>
                                    <View>
                                        <Text style={{
                                            color: '#000',
                                            fontWeight: 'bold',
                                            marginRight: 10,
                                        }}>FILTER BY</Text>
                                    </View>
                                </View>

                                <View style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingBottom: 15,
                                    paddingTop: 15,
                                    width: 240
                                }}>
                                    <View style={{
                                        flexDirection: 'row',
                                    }}>
                                        <View style={{
                                            marginRight: 15,
                                        }}>
                                            {HamIcon({ width: 16, height: 16, fill: "black" })}
                                        </View>
                                        <View>
                                            <Text style={{
                                                color: '#000',
                                                fontWeight: 'bold',

                                            }}>PRICE</Text>
                                        </View>
                                    </View>

                                    <View>
                                        {expandedOptions.includes("price") && <TouchableOpacity onPress={() => {
                                            setExpandedOptions(expandedOptions.filter((el) => el != "price"));
                                        }}
                                        >
                                            {minusIcon({ width: 18, height: 18, fill: "black" })}
                                        </TouchableOpacity>}
                                        {!expandedOptions.includes("price") && <TouchableOpacity onPress={() => {
                                            setExpandedOptions([...expandedOptions, "price"]);
                                        }}
                                        >
                                            {plusIcon({ width: 18, height: 18, fill: "black" })}
                                        </TouchableOpacity>}
                                    </View>
                                </View>

                                {expandedOptions.includes("price") &&
                                    <View style={{
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        paddingBottom: 15,
                                        paddingTop: 15,
                                    }}>
                                        <View style={{
                                            marginRight: 10,
                                            width: 100,
                                        }}>
                                            <CustomNumberInput
                                                placeholder={"Min"}
                                                value={minPrice}
                                                onChange={(val) => {
                                                    setMinPrice(val);
                                                }}
                                            />
                                        </View>
                                        <View style={{
                                            marginRight: 10,
                                        }}>
                                            <Text style={{
                                                fontWeight: 'bold',
                                                color: '#000',
                                                fontSize: 16,
                                            }}>-</Text>
                                        </View>
                                        <View style={{
                                            width: 100,
                                        }}>
                                            <CustomNumberInput
                                                value={maxPrice}
                                                placeholder={"Max"}
                                                onChange={(val) => {
                                                    setMaxPrice(val);
                                                }}
                                            />
                                        </View>
                                    </View>}

                                {sizeOptions.length > 0 && <View style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingBottom: 15,
                                    paddingTop: 15,

                                }}>
                                    <View style={{
                                        flexDirection: 'row',
                                    }}>
                                        <View style={{
                                            marginRight: 15,
                                        }}>
                                            {HamIcon({ width: 16, height: 16, fill: "black" })}
                                        </View>
                                        <View>
                                            <Text style={{
                                                color: '#000',
                                                fontWeight: 'bold',

                                            }}>SIZE</Text>
                                        </View>
                                    </View>

                                    <View>
                                        {expandedOptions.includes("size") && <TouchableOpacity onPress={() => {
                                            setExpandedOptions(expandedOptions.filter((el) => el != "size"));
                                        }}
                                        >
                                            {minusIcon({ width: 18, height: 18, fill: "black" })}
                                        </TouchableOpacity>}
                                        {!expandedOptions.includes("size") && <TouchableOpacity onPress={() => {
                                            setExpandedOptions([...expandedOptions, "size"]);
                                        }}
                                        >
                                            {plusIcon({ width: 18, height: 18, fill: "black" })}
                                        </TouchableOpacity>}
                                    </View>
                                </View>}

                                {/* sizes checkbox---------- */}

                                {(sizeOptions.length > 0 && expandedOptions.includes("size")) && <View style={{
                                    justifyContent: 'flex-start',
                                    paddingBottom: 15,
                                }}>

                                    <View style={{
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                    }}>
                                        <View>
                                            <CustomCheckbox
                                                value={sizes.includes("XS")}
                                                onChange={(val) => {
                                                    if (val) {
                                                        setSizes([...sizes, "XS"]);
                                                    } else {
                                                        setSizes(sizes.filter((el) => el != "XS"));
                                                    }
                                                }}
                                            />
                                        </View>
                                        <View>
                                            <Text style={{
                                                color: '#000',
                                                fontSize: 14,
                                            }}>XS</Text>
                                        </View>
                                    </View>
                                </View>}

                                {/* Color filter-------------- */}

                                {colorOptions.length > 0 && <View style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingBottom: 15,
                                    paddingTop: 15,

                                }}>
                                    <View style={{
                                        flexDirection: 'row',
                                    }}>
                                        <View style={{
                                            marginRight: 15,
                                        }}>
                                            {HamIcon({ width: 16, height: 16, fill: "black" })}
                                        </View>
                                        <View>
                                            <Text style={{
                                                color: '#000',
                                                fontWeight: 'bold',

                                            }}>COLOR</Text>
                                        </View>
                                    </View>

                                    <View>
                                        {expandedOptions.includes("color") && <TouchableOpacity onPress={() => {
                                            setExpandedOptions(expandedOptions.filter((el) => el != "color"));
                                        }}
                                        >
                                            {minusIcon({ width: 18, height: 18, fill: "black" })}
                                        </TouchableOpacity>}
                                        {!expandedOptions.includes("color") && <TouchableOpacity onPress={() => {
                                            setExpandedOptions([...expandedOptions, "color"]);
                                        }}
                                        >
                                            {plusIcon({ width: 18, height: 18, fill: "black" })}
                                        </TouchableOpacity>}
                                    </View>
                                </View>}

                                {(colorOptions.length > 0 && expandedOptions.includes("color")) && <View style={{
                                    justifyContent: 'flex-start',
                                    paddingBottom: 15,
                                }}>

                                    <View style={{
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                    }}>
                                        <View>
                                            <CustomCheckbox
                                                value={colors.includes("RED")}
                                                onChange={(val) => {
                                                    if (val) {
                                                        setColors([...colors, "RED"]);
                                                    } else {
                                                        setColors(colors.filter((el) => el != "RED"));
                                                    }
                                                }}
                                            />
                                        </View>
                                        <View>
                                            <Text style={{
                                                color: '#000',
                                                fontSize: 14,
                                            }}>RED</Text>
                                        </View>
                                    </View>

                                </View>}
                            </View>
                        </Col>
                    </Row>

                    <View row style={{
                        flexWrap: 'wrap',
                        width: Dimensions.get("window").width - 300,
                    }}>
                        <FlashList
                            data={products}
                            numColumns={Platform.OS == 'web' ? 4 : 2}
                            estimatedItemSize={170}
                            renderItem={({ item }) => (<TouchableOpacity
                                style={{
                                    width: '100%'
                                }}
                                onPress={() => {
                                    navigationFn("shop/product/" + item.sku + "/" + item._id);
                                }}><View style={{
                                    width: '100%',
                                    position: 'relative',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingHorizontal: 5
                                }}>
                                    <Image resizeMode="cover" source={{
                                        // uri: `https://apiserver.seasonsindia.com/uploads/feature_images/11-742801.jpg` 
                                        uri: item.image_urls[0] ? item.image_urls[0] : "https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found.jpg"
                                    }}
                                        style={{ width: '100%', height: 380 }} />

                                    <View marginT-10 marginB-20 paddingH-5 style={{
                                        backgroundColor: '#fff',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        width: '100%',
                                    }}>
                                        <View>
                                            <Text style={{ fontSize: 15, fontWeight: 'bold' }}>{item.name}</Text>
                                            <View marginT-5 row>
                                                <Text style={{ fontSize: 13, fontWeight: 'bold' }}>Rs. {item.sales_rate}</Text>
                                                {item.mrp && item.mrp != item.sales_rate && (<>
                                                    <Text style={{ fontSize: 13, marginLeft: 4, textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>Rs. {item.mrp}</Text>
                                                    {/* <Text style={{ fontSize: 13, marginLeft: 4 }}>({15}% Off)</Text> */}
                                                    <Text style={{ fontSize: 13, marginLeft: 4 }}>({(Math.round((item.mrp - item.sales_rate) / item.mrp * 100).toFixed(2))}% Off)</Text>
                                                </>)}
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </TouchableOpacity>)}
                            keyExtractor={(item) => item._id}
                        />
                    </View>
                </View>
                <Footer />
            </Card>
        </ScrollView>
    </>);
};

export default Shop;