// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, DeviceEventEmitter, TouchableOpacity } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, ProgressBar } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomDatePicker, CustomModal, CustomNumberInput, CustomSelect, CustomTextAreaField, CustomTextField, CustomTextFieldWithScanner, CustomUpload, DangerButton, Heading, Label, PrimaryButton, Row, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import { Icon } from "react-native-eva-icons"
import { CustomSelectItemForContacts, TransactionPDFPreview, invoiceItemAdjustment } from "../../../../../models/accountancy/transaction"
import { create } from 'zustand'
import { FlashList } from "@shopify/flash-list";
import { GlobalModalStore } from "../../../../../models/ModalStore"
import { App } from 'antd';
import * as Sharing from 'expo-sharing';
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import CalendarStrip from 'react-native-calendar-strip';
import moment from "moment"
import Svg, { Circle, Path, Rect } from "react-native-svg"
import { Agenda } from 'react-native-calendars';
import { AgendaIcon, DateIcon, InviteIcon } from "../../../../../models/taqwa/Meeting/Meetings"
import RenderHtml from 'react-native-render-html';
import FaceAuth from "../main/FaceAuth/FaceAuth"
import * as Location from 'expo-location';
import EventsScreen from "./EventsScreen"
import ListingScreen from "../../../CRUD/ListingScreen"
import ReportScreenSingle from "../../../CRUD/ReportScreenSingle"

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}));

const DateSvg = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <Path
            fill="#33A9FF"
            d="M6.81 18.756h10.373c1.635 0 2.486-.844 2.486-2.456V7.132c0-1.612-.851-2.456-2.486-2.456H6.81c-1.627 0-2.478.836-2.478 2.456V16.3c0 1.612.851 2.456 2.478 2.456Zm-.008-1.5c-.625 0-.972-.323-.972-.986V9.339c0-.663.347-.987.972-.987H17.19c.625 0 .971.324.971.987v6.93c0 .664-.346.988-.971.988H6.8Zm3.744-6.305h.445c.27 0 .361-.083.361-.346v-.445c0-.271-.09-.354-.361-.354h-.445c-.271 0-.362.083-.362.354v.445c0 .263.09.346.362.346Zm2.463 0h.445c.264 0 .354-.083.354-.346v-.445c0-.271-.09-.354-.354-.354h-.445c-.27 0-.361.083-.361.354v.445c0 .263.09.346.361.346Zm2.456 0h.445c.271 0 .361-.083.361-.346v-.445c0-.271-.09-.354-.361-.354h-.445c-.263 0-.354.083-.354.354v.445c0 .263.09.346.354.346ZM8.09 13.377h.437c.271 0 .362-.083.362-.354v-.445c0-.263-.09-.346-.362-.346H8.09c-.271 0-.361.083-.361.346v.445c0 .271.09.354.361.354Zm2.456 0h.445c.27 0 .361-.083.361-.354v-.445c0-.263-.09-.346-.361-.346h-.445c-.271 0-.362.083-.362.346v.445c0 .271.09.354.362.354Zm2.463 0h.445c.264 0 .354-.083.354-.354v-.445c0-.263-.09-.346-.354-.346h-.445c-.27 0-.361.083-.361.346v.445c0 .271.09.354.361.354Zm2.456 0h.445c.271 0 .361-.083.361-.354v-.445c0-.263-.09-.346-.361-.346h-.445c-.263 0-.354.083-.354.346v.445c0 .271.09.354.354.354ZM8.09 15.795h.437c.271 0 .362-.083.362-.346v-.445c0-.271-.09-.354-.362-.354H8.09c-.271 0-.361.083-.361.354v.445c0 .263.09.346.361.346Zm2.456 0h.445c.27 0 .361-.083.361-.346v-.445c0-.271-.09-.354-.361-.354h-.445c-.271 0-.362.083-.362.354v.445c0 .263.09.346.362.346Zm2.463 0h.445c.264 0 .354-.083.354-.346v-.445c0-.271-.09-.354-.354-.354h-.445c-.27 0-.361.083-.361.354v.445c0 .263.09.346.361.346Z"
        />
    </Svg>
)

const AttendanceScreen = observer(function AttendanceScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    let queryParams = { ...(props?.route?.params || {}) };

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const goBack = () => {
        if (Platform.OS === 'web') {
            window.history.back();
        } else {
            navigation.goBack();
        }
    }

    const moduleName = "DailyActivities";
    const { fields, dataTabs, preferencesFields, ExtraSaveComponent } = api.getForm(moduleName);
    const params = {};

    const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
    const globalData = GlobalMasterStore((state) => state.globalData);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const employee = GlobalMasterStore((state) => state.employee);

    const employee_id = employee._id;

    const [dates, setDates] = useState([
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
    ]);

    const [month, setMonth] = useState(moment().month() + 1);

    const [attendanceType, setAttendanceType] = useState('Course');
    const [updateKey, setUpdateKey] = useState(null);

    useEffect(() => {
        setUpdateKey(Math.random());
    }, [dates, attendanceType]);

    return (
        <View padding-10 style={{
            flex: 1
        }}>
            <View w-100 flex>
                <View spread>
                    {/* <View backgroundColor="white" style={{
                        width: '100%',
                        borderRadius: 10,
                        paddingHorizontal: 10,
                        marginBottom: 10
                    }}>
                        <CustomSelect
                            value={attendanceType}
                            onChange={(value) => {
                                setAttendanceType(value);
                            }}
                            placeholder="Select Attendance Type"
                            label="Select Attendance Type"
                            options={[
                                { label: 'Course', value: 'Course' },
                                { label: 'Meeting', value: 'Meeting' },
                            ]}
                        />
                    </View> */}
                    <SegmentedControl
                        outlineColor={"rgb(41, 69, 156)"} activeColor={"rgb(41, 69, 156)"} borderRadius={4}
                        segments={employee?.enable_shift ? [
                            { label: 'Course', value: 'Course' },
                            { label: 'Meeting', value: 'Meeting' },
                            { label: 'Shift', value: 'Shift' },
                        ] : [
                            { label: 'Course', value: 'Course' },
                            { label: 'Meeting', value: 'Meeting' },
                        ]}
                        onChangeIndex={(index) => {
                            setAttendanceType(index == 0 ? 'Course' : index == 1 ? 'Meeting' : 'Shift');
                        }}
                        value={attendanceType}
                    />

                    <View marginT-10 style={{
                        width: '100%'
                    }}>
                        {/* Back arrow month with year and then next button array */}
                        <View row spread centerV>
                            <TouchableOpacity onPress={() => {
                                setDates([
                                    moment(dates[0]).subtract(1, 'months').toDate(),
                                    moment(dates[1]).subtract(1, 'months').toDate(),
                                ]);
                            }} style={{
                                paddingLeft: 20,
                                paddingRight: 20
                            }}>
                                <Icon name="arrow-back" width={24} height={24} fill={colors.primary} />
                            </TouchableOpacity>
                            <Text h1>{moment(dates[0]).format('MMMM YYYY')}</Text>
                            <TouchableOpacity onPress={() => {
                                setDates([
                                    moment(dates[0]).add(1, 'months').toDate(),
                                    moment(dates[1]).add(1, 'months').toDate(),
                                ]);
                            }} style={{
                                paddingLeft: 20,
                                paddingRight: 20
                            }}>
                                <Icon name="arrow-forward" width={24} height={24} fill={colors.primary} />
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
                {updateKey != null && <>{attendanceType == 'Meeting' ? <ListingScreen
                    key={updateKey}
                    {...{
                        moduleName: 'MeetingAttendances',
                        hideWrapper: true,
                        extraFilters: {
                            'employee_id': employee_id ?? null,
                            'meeting.date': {
                                $gte: dates[0],
                                $lte: dates[1],
                            }
                        },
                        hideViews: true,
                        hideMainHeader: true,
                    }}
                    attendanceType={attendanceType}
                    title={" "}
                    hiddenColumns={["employee.name", "employee.name"]}
                    disabledClone={true}
                    disableDelete={true}
                    default_values={{
                        employee_id: employee_id,
                        is_default_values: true
                    }} /> : 
                    
                    attendanceType == 'Shift' ? <ReportScreenSingle
                    moduleName={"AttendanceByEmployee"}
                    hideWrapper={true}
                    noMono={true}
                    hideHeader={true}
                    hideDates={true}
                    params={{
                        quickFilterKey: 'employee_id',
                        quickFilterValue: employee_id,
                        hideQuickFilter: true,
                        tabFilter: { 'date': { $gte: dates[0], $lte: dates[1] } }
                    }}
                    route={{
                        params: {
                            quickFilterKey: 'employee_id',
                            quickFilterValue: employee_id,
                            hideQuickFilter: true,
                            name: "AttendanceByEmployee",
                            tabFilter: { 'date': { $gte: dates[0], $lte: dates[1] } }
                        }
                    }}
                /> :

                    <ListingScreen
                        key={updateKey}
                        {...{
                            moduleName: 'CourseSessionAttendances',
                            hideWrapper: true,
                            extraFilters: {
                                'employee_id': employee_id ?? null,
                                'course_session.date': {
                                    $gte: dates[0],
                                    $lte: dates[1],
                                }
                            },
                            hideViews: true,
                            hideMainHeader: true,
                        }}
                        attendanceType={attendanceType}
                        title={" "}
                        disabledClone={true}
                        disableDelete={true}
                        default_values={{
                            employee_id: employee_id,
                            is_default_values: true
                        }} />}
                </>}
            </View>
        </View>
    )
})

export default AttendanceScreen