import React, { useEffect } from "react";
import { View, Card, Text, Image, TouchableOpacity } from "react-native-ui-lib";
import ListingScreen from "../../../screens/MainScreens/CRUD/ListingScreen";
import moment from "moment";
import Svg, { Ellipse, G, Path, Defs, ClipPath } from "react-native-svg"
import RenderHtml from 'react-native-render-html';
import { Dimensions, Platform } from "react-native";
import { CustomCheckbox, CustomNumberInput, CustomSelect, CustomTextField, DangerButton, SecondaryButton } from "../../../mycomponents/DynamicForm";
import { App } from 'antd';
import { api } from "../../../services/api";

const DateIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={38}
        height={29}
        fill="none"
        {...props}
    >
        <Ellipse cx={15.09} cy={14.5} fill="#FF9D9D" rx={15.09} ry={14.5} />
        <Path
            fill="#464F60"
            d="M14.78 27h18.3c2.884 0 4.386-1.438 4.386-4.186V7.186C37.466 4.438 35.964 3 33.08 3h-18.3c-2.871 0-4.373 1.425-4.373 4.186v15.628c0 2.748 1.502 4.186 4.373 4.186Zm-.014-2.555c-1.103 0-1.714-.553-1.714-1.683V10.95c0-1.13.611-1.683 1.714-1.683h18.327c1.103 0 1.715.553 1.715 1.683v11.813c0 1.13-.611 1.683-1.715 1.683H14.766Zm6.606-10.748h.784c.478 0 .638-.142.638-.591v-.758c0-.462-.16-.603-.638-.603h-.784c-.479 0-.638.141-.638.603v.758c0 .45.16.59.638.59Zm4.345 0h.785c.465 0 .624-.142.624-.591v-.758c0-.462-.16-.603-.625-.603h-.784c-.478 0-.637.141-.637.603v.758c0 .45.159.59.637.59Zm4.333 0h.784c.479 0 .638-.142.638-.591v-.758c0-.462-.16-.603-.638-.603h-.784c-.465 0-.625.141-.625.603v.758c0 .45.16.59.625.59ZM17.039 17.83h.77c.48 0 .639-.14.639-.603v-.758c0-.45-.16-.59-.638-.59h-.771c-.479 0-.638.14-.638.59v.758c0 .462.16.603.638.603Zm4.333 0h.784c.478 0 .638-.14.638-.603v-.758c0-.45-.16-.59-.638-.59h-.784c-.479 0-.638.14-.638.59v.758c0 .462.16.603.638.603Zm4.345 0h.785c.465 0 .624-.14.624-.603v-.758c0-.45-.16-.59-.625-.59h-.784c-.478 0-.637.14-.637.59v.758c0 .462.159.603.637.603Zm4.333 0h.784c.479 0 .638-.14.638-.603v-.758c0-.45-.16-.59-.638-.59h-.784c-.465 0-.625.14-.625.59v.758c0 .462.16.603.625.603Zm-13.011 4.122h.77c.48 0 .639-.14.639-.59v-.758c0-.462-.16-.603-.638-.603h-.771c-.479 0-.638.14-.638.603v.758c0 .45.16.59.638.59Zm4.333 0h.784c.478 0 .638-.14.638-.59v-.758c0-.462-.16-.603-.638-.603h-.784c-.479 0-.638.14-.638.603v.758c0 .45.16.59.638.59Zm4.345 0h.785c.465 0 .624-.14.624-.59v-.758c0-.462-.16-.603-.625-.603h-.784c-.478 0-.637.14-.637.603v.758c0 .45.159.59.637.59Z"
        />
    </Svg>
)

const AgendaIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={38}
        height={29}
        fill="none"
        {...props}
    >
        <Ellipse cx={15.09} cy={14.5} fill="#FFE99D" rx={15.09} ry={14.5} />
        <Path
            fill="#464F60"
            d="M33.654 5.25h-.904V4.5a.75.75 0 1 0-1.5 0v.75h-4.5V4.5a.75.75 0 1 0-1.5 0v.75h-4.5V4.5a.75.75 0 1 0-1.5 0v.75h-.904A2.85 2.85 0 0 0 15.5 8.095v15.31a2.85 2.85 0 0 0 2.846 2.845h15.308a2.85 2.85 0 0 0 2.846-2.846V8.095a2.85 2.85 0 0 0-2.846-2.845ZM24.28 18.53l-2.25 2.25a.75.75 0 0 1-1.06 0l-1.5-1.5a.75.75 0 1 1 1.06-1.06l.971.97 1.72-1.72a.75.75 0 0 1 1.06 1.06Zm0-6.75-2.25 2.25a.75.75 0 0 1-1.06 0l-1.5-1.5a.75.75 0 1 1 1.06-1.06l.971.97 1.72-1.72a.75.75 0 0 1 1.06 1.06ZM32 20.25h-5.25a.75.75 0 1 1 0-1.5H32a.75.75 0 1 1 0 1.5Zm0-6.75h-5.25a.75.75 0 1 1 0-1.5H32a.75.75 0 1 1 0 1.5Z"
        />
    </Svg>
)

const InviteIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={34}
        height={29}
        fill="none"
        {...props}
    >
        <Ellipse cx={15.09} cy={14.5} fill="#9DE2FF" rx={15.09} ry={14.5} />
        <G fill="#464F60" clipPath="url(#a)">
            <Path d="M31.18 8.066v2.995l1.901-1.413-1.901-1.582ZM23.156 1.42a1.813 1.813 0 0 0-2.312-.006l-.767.652h3.858l-.779-.646Z" />
            <Path d="M23.802 18.053a2.96 2.96 0 0 1-1.793.592c-.646 0-1.28-.206-1.8-.592l-9.616-7.178v12.31a1.806 1.806 0 0 0 1.793 1.817h19.228a1.806 1.806 0 0 0 1.793-1.818V10.875l-9.605 7.178Z" />
            <Path d="m10.925 9.648 1.841 1.37V8.122l-1.841 1.527ZM13.974 3.273v8.645l6.942 5.168c.646.47 1.534.47 2.18 0l6.876-5.125V3.273H13.974Zm4.286 4.83h3.26c.332 0 .604.272.604.604a.605.605 0 0 1-.604.603h-3.26a.605.605 0 0 1-.604-.603c0-.332.272-.604.604-.604Zm7.486 4.226H18.26a.606.606 0 0 1-.604-.604c0-.332.272-.604.604-.604h7.486c.332 0 .603.272.603.604a.605.605 0 0 1-.603.604Z" />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M10 1h24v24H10z" />
            </ClipPath>
        </Defs>
    </Svg>
)

const FilesIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={36}
        height={29}
        fill="none"
        {...props}
    >
        <Ellipse cx={15.09} cy={14.5} fill="#E0FF9D" rx={15.09} ry={14.5} />
        <Path
            fill="#464F60"
            d="M27.469 10.031c-.905 0-1.64-.736-1.64-1.64V3h-8.392a2.581 2.581 0 0 0-2.578 2.578v18.844A2.581 2.581 0 0 0 17.438 27h13.125a2.581 2.581 0 0 0 2.578-2.578v-14.39h-5.672Zm-8.766 9.844h3.409a.703.703 0 0 1 0 1.406h-3.409a.703.703 0 0 1 0-1.406ZM18 16.828c0-.388.315-.703.703-.703h10.313a.703.703 0 1 1 0 1.406H18.703a.703.703 0 0 1-.703-.703Zm11.016-4.453a.703.703 0 1 1 0 1.406H18.703a.703.703 0 0 1 0-1.406h10.313Z"
        />
        <Path
            fill="#464F60"
            d="M27.234 8.388c0 .13.105.234.235.234h5.358a2.571 2.571 0 0 0-.493-.643l-4.52-4.276a2.585 2.585 0 0 0-.58-.414v5.1Z"
        />
    </Svg>
)

export const challengefields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const MCQComponent = ({ field, useForm, tKey, tIndex }) => {
        const lesson_mcqs = useForm((state) => state.formObject['lesson_mcqs']) || [];

        const setObj = useForm((state) => state.setFormObject);
        const getObj = useForm((state) => state.getFormObject);

        return <View flex>
            {lesson_mcqs.map((lesson_mcq, index) => {
                return <Card padding-15 marginB-15 key={index}>
                    <View row spread>
                        <View row centerV>
                            <Text marginR-10>Question</Text>

                            <CustomNumberInput value={index + 1} onChange={(value) => {
                                // reaarange
                                const temp = [...lesson_mcqs];
                                const tempObj = temp[index];
                                temp.splice(index, 1);
                                temp.splice(value - 1, 0, tempObj);
                                setObj({
                                    lesson_mcqs: temp
                                });
                            }} />
                        </View>

                        <View>

                        </View>

                    </View>

                    <View row spread centerV>
                        <View flex-1>
                            <View marginT-15>
                                <CustomTextField value={lesson_mcq.question} onChange={(value) => {
                                    const temp = [...lesson_mcqs];
                                    temp[index].question = value;
                                    setObj({
                                        lesson_mcqs: temp
                                    });
                                }} />
                            </View>
                        </View>

                        <View paddingL-15>
                            <DangerButton
                                style={{
                                    height: 42,
                                    position: 'relative',
                                    top: 7
                                }}
                                label={"Remove Question"}
                                onPress={() => {
                                    const temp = [...lesson_mcqs];
                                    temp.splice(index, 1);
                                    setObj({
                                        lesson_mcqs: temp
                                    });
                                }} />
                        </View>
                    </View>
                    <View marginV-15 >
                        <Text>Options</Text>
                        {lesson_mcq.options.map((option, optionIndex) => {
                            return <View key={optionIndex}>
                                <View row marginT-15>
                                    <View flex-1>
                                        <CustomTextField value={option.option} onChange={(value) => {
                                            const temp = [...lesson_mcqs];
                                            temp[index].options[optionIndex].option = value;
                                            setObj({
                                                lesson_mcqs: temp
                                            });
                                        }} />
                                    </View>
                                    <View paddingL-15>
                                        <DangerButton
                                            style={{
                                                height: 42,
                                                position: 'relative',
                                                top: 7
                                            }}
                                            label="Remove"
                                            onPress={() => {
                                                const temp = [...lesson_mcqs];
                                                temp[index].options.splice(optionIndex, 1);
                                                setObj({
                                                    lesson_mcqs: temp
                                                });
                                            }} />
                                    </View>
                                </View>
                                <View marginT-10 row>
                                    <CustomCheckbox value={option.is_correct} onChange={(value) => {
                                        const temp = [...lesson_mcqs];

                                        temp[index].options.forEach((item) => {
                                            item.is_correct = false;
                                        });

                                        temp[index].options[optionIndex].is_correct = value;
                                        setObj({
                                            lesson_mcqs: temp
                                        });
                                    }} />
                                    <Text>This is the correct answer</Text>
                                </View>
                            </View>
                        })}
                    </View>
                    <View left >
                        <SecondaryButton label="Add Option" onPress={() => {
                            const temp = [...lesson_mcqs];
                            temp[index].options.push({ option: "", is_correct: false });
                            setObj({
                                lesson_mcqs: temp
                            });
                        }} />
                    </View>

                </Card>
            })}

            <View left>
                <SecondaryButton label="Add Question" onPress={() => {
                    const temp = [...lesson_mcqs];
                    temp.push({ question: "", options: [{ option: "", is_correct: false }] });
                    setObj({
                        lesson_mcqs: temp
                    });
                }} />
            </View>
        </View>
    };

    const OverviewComponent = ({ field, useForm, tKey, tIndex }) => {
        const start_date = useForm((state) => state.formObject['start_date']);
        const end_date = useForm((state) => state.formObject['end_date']);
        const duration = useForm((state) => state.formObject['duration']);
        const description = useForm((state) => state.formObject['description']);
        const employees = useForm((state) => state.formObject['employees']);
        const leads = useForm((state) => state.formObject['leads']);
        const send_to = useForm((state) => state.formObject['send_to']);

        return <Card padding-20 flex>
            <View w-100 flex>
                <View row marginB-10>
                    <View row>
                        <DateIcon />
                    </View>

                    <View marginL-10>

                        <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 20 }}>
                            {moment(start_date).format('DD MMM YYYY')} - {end_date ? moment(end_date).format('DD MMM YYYY') : ''}
                        </Text>
                    </View>
                </View>

                <View style={{
                    height: 1,
                    backgroundColor: '#e6e6e6',
                    width: '100%',
                    marginTop: 10,
                    marginBottom: 10
                }} />

                <View row marginB-10>
                    <View row>
                        <AgendaIcon />
                    </View>

                    <View marginL-10>
                        <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 20 }}>Description</Text>
                        {Platform.OS === 'web' ? <div dangerouslySetInnerHTML={{ __html: description }} /> : <RenderHtml contentWidth={Dimensions.get('window').width - 120} source={{ html: (description || "") }} />}
                    </View>
                </View>
            </View>
        </Card>
    };

    const LeadersComponent = ({ field, useForm, tKey, tIndex }) => {
        const { message } = App.useApp();

        const top_fsc_ids = useForm((state) => state.formObject['top_fsc_ids']) || [];
        const no_of_leaders = useForm((state) => state.formObject['no_of_leaders']);

        const setObj = useForm((state) => state.setFormObject);
        const getObj = useForm((state) => state.getFormObject);


        return <Card padding-20 flex>
            <View flex>
                {view ? null : <View row centerV marginB-20>
                    <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 20 }}>No of Leaders</Text>
                    <View marginL-10 style={{
                        width: 300
                    }}>
                        <CustomSelect value={no_of_leaders} onChange={(value) => {
                            setObj({
                                no_of_leaders: value,
                                top_fsc_ids: [...Array(value).fill(null)]
                            });
                        }} options={[{ label: 1, value: 1 }, { label: 3, value: 3 }, { label: 5, value: 5 }, { label: 10, value: 10 }]} />
                    </View>
                </View>}

                {top_fsc_ids.map((item, index) => {
                    return <View row marginB-10 key={index} centerV>
                        <View row>
                            <Image assetGroup="images" assetName={"rank" + (index + 1)} style={{ width: 50, height: 30 }} />
                        </View>

                        <View marginL-20>
                            <View style={{
                                width: 300
                            }}>
                                {!view ? <CustomSelect value={top_fsc_ids[index]} onChange={(value) => {
                                    const temp = [...top_fsc_ids];
                                    temp[index] = value;
                                    setObj({
                                        top_fsc_ids: temp
                                    });
                                }} options={response.Employees ? response.Employees.map((item) => {
                                    return { label: item.name, value: item._id }
                                }) : []} />
                                    : <Text>{response.Employees.find((item) => item._id == top_fsc_ids[index])?.name}</Text>}
                            </View>
                        </View>
                    </View>
                })}

                {top_fsc_ids.length == 0 ? <Text>No Leaders</Text> : null}

                {/* {view ? null : <View row marginV-10>
                    <SecondaryButton label="Save" onPress={async () => {
                        // check if all the leaders are selected and have unique values

                        const top_fsc_ids = getObj()['top_fsc_ids'];

                        if (top_fsc_ids.length != no_of_leaders) {
                            message.error("Please select all the leaders");
                            return;
                        }

                        if (top_fsc_ids.includes(null)) {
                            message.error("Please select all the leaders");
                            return;
                        }

                        const unique = [...new Set(top_fsc_ids)];

                        if (unique.length != top_fsc_ids.length) {
                            message.error("Please select unique leaders");
                            return;
                        }

                        try {
                            const response = await api.customRoute('Challenges/action', {
                                action: 'saveLeaders',
                                _id: getObj()['_id'],
                                no_of_leaders: no_of_leaders,
                                top_fsc_ids: top_fsc_ids,
                            });

                            if (response.success) {
                                message.success("Leaders Saved");
                            } else {
                                message.error(response.message);
                            }
                        } catch (e) {
                            message.error(e.message || "Something went wrong");
                        }
                    }} />
                </View>} */}

            </View>
        </Card>
    };

    const formFields = [
        {
            label: '',
            type: "custom",
            visible: true,
            width: '100%',
            tab: "Overview",
            component: OverviewComponent,
        },
        {
            label: 'Title',
            key: 'title',
            type: 'text',
            placeholder: 'Enter Title',
            visible: true,
            width: '100%',
            required: true,
            tab: 'General',
        },
        {
            label: 'Type',
            key: 'type',
            type: 'select',
            placeholder: 'Select Type',
            visible: true,
            width: '100%',
            options: [
                { label: 'Monthly', value: 'Monthly' },
                { label: 'Quarterly', value: 'Quarterly' },
                { label: 'Yearly', value: 'Yearly' },
                { label: 'Half Yearly', value: 'Half Yearly' },
            ],
            value: 'Monthly',
            tab: 'General',
        },
        {
            label: 'Description',
            key: 'description',
            type: 'editor',
            placeholder: 'Enter Description',
            visible: true,
            width: '100%',
            tab: 'General',
        },
        {
            label: 'Start Date',
            key: 'start_date',
            type: 'date',
            placeholder: 'Enter Start Date',
            visible: true,
            width: '100%',
            required: true,
            tab: 'General',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const type = getObj()['type'];

                if (type == 'Monthly') {
                    const end_date = new Date(value);
                    end_date.setMonth(end_date.getMonth() + 1);
                    setObj({
                        end_date: end_date,
                        updateVisiblity: Math.random()
                    })
                } else if (type == 'Quarterly') {
                    const end_date = new Date(value);
                    end_date.setMonth(end_date.getMonth() + 3);
                    setObj({
                        end_date: end_date,
                        updateVisiblity: Math.random()
                    })
                } else if (type == 'Yearly') {
                    const end_date = new Date(value);
                    end_date.setFullYear(end_date.getFullYear() + 1);
                    setObj({
                        end_date: end_date,
                        updateVisiblity: Math.random()
                    })
                } else if (type == 'Half Yearly') {
                    const end_date = new Date(value);
                    end_date.setMonth(end_date.getMonth() + 6);
                    setObj({
                        end_date: end_date,
                        updateVisiblity: Math.random()
                    })
                } else {
                    const end_date = new Date(value);
                    end_date.setMonth(end_date.getMonth() + 1);
                    setObj({
                        end_date: end_date,
                        updateVisiblity: Math.random()
                    })
                }
            }
        },
        {
            label: 'End Date',
            key: 'end_date',
            type: 'date',
            placeholder: 'Enter End Date',
            visible: true,
            width: '100%',
            required: true,
            tab: 'General',
        },
        {
            label: "Agents",
            key: 'employee_ids',
            type: 'select',
            placeholder: 'Select Agents',
            visible: true,
            width: '100%',
            moduleName: moduleName,
            multiple: true,
            tab: 'General',
            options: response.Employees ? response.Employees : [],
            group_by: "custom_grouping",
        },
        {
            label: 'MCQ',
            key: 'MCQComponent',
            type: 'custom',
            visible: view ? false : true,
            width: '100%',
            component: MCQComponent,
            tab: 'General',
        },
        {
            label: '',
            type: "custom",
            visible: true,
            width: '100%',
            tab: "Leaders",
            component: LeadersComponent,
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    if (view) {
        // remove all "General" tab fields
        return formFields.filter((item) => item.tab !== "General");
    } else {
        return formFields.filter((item) => item.tab !== "Overview");
    }

    return formFields;
}

export const announcementMobileCard = ({ item, index, getFieldValue, onRowClick, valueText, columns, DeleteCard, actionFunctions, SidebarStatus }) => {


    return <Card flex style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: 10 }}>
        <TouchableOpacity padding-10 flex onPress={() => {
            onRowClick(item);
        }}>
            <View marginB-10 row spread style={{ width: '100%' }}>
                <View center backgroundColor="#9ec1e9" style={{ width: 60, height: 60, borderRadius: 10 }}>
                    <Text style={{ fontSize: 24, color: 'white' }}>{moment(item.start_date).format('DD')}</Text>
                    <Text style={{ fontSize: 12, color: 'white' }}>{moment(item.start_date).format('ddd')}</Text>
                </View>

                <View flex marginL-10>
                    <View row>
                        <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                            {item.title}
                        </Text>
                        <View marginL-10>
                            <Text bold style={{ color: moment(item.end_date).isBefore(moment()) ? '#cd1414' : 'green' }}>
                                ({moment(item?.end_date).isBefore(moment()) ? 'Expired' : 'Active'})
                            </Text>
                        </View>
                    </View>

                    <View>
                        <View flex>
                            <View row marginB-6 flex>
                                <View>
                                    <Text lightCardTitle>{"Type"}: </Text>
                                </View>
                                <View flex>
                                    <Text bold>
                                        {item.type}
                                    </Text>
                                </View>
                            </View>
                            <View row flex>
                                <View>
                                    <Text lightCardTitle>{"End Date"}: </Text>
                                </View>
                                <View flex>
                                    <Text bold>
                                        {moment(item.end_date).format('DD MMM YYYY')}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    </Card>;
}

export const CustomViewHeader = (props) => {

    return <View padding-15 margin-10 marginB-0 paddingB-10 backgroundColor="rgb(244, 247, 249)" style={{ borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0, position: 'relative', height: 130 }}>
        <View spread row top>
            <View row >
                <View marginT-10>
                    <Text style={{
                        fontFamily: 'SourceSansProBold',
                        fontSize: 22
                    }}>{props?.connectedObject?.title}</Text>

                    <View>
                        <Text marginV-5 style={{
                            fontStyle: 'italic',
                            color: "#727171",
                        }}>{props?.connectedObject?.type}</Text>
                    </View>


                    <View row centerV>
                        <View style={{
                            backgroundColor: moment(props?.connectedObject?.end_date).isBefore(moment()) ? '#cd1414' : 'green',
                            padding: 5,
                            paddingHorizontal: 20,
                            borderRadius: 5,
                            marginTop: 5,
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: 10
                        }}>
                            <Text white>{moment(props?.connectedObject?.end_date).isBefore(moment()) ? 'Expired' : 'Active'}</Text>
                        </View>

                        {/* <View row>
                            <Text darkCardTitle>Created At - </Text>
                            <Text darkCardTitle>{moment(props?.connectedObject?.createdAt).format('DD MMM YYYY hh:mm A')}</Text>
                        </View> */}
                    </View>
                </View>
            </View>

            {props.HeaderExtra}
        </View>
    </View>;
}

export const challengeMain = {
    fields: challengefields,
    mobileCard: announcementMobileCard,
    CustomViewHeader: CustomViewHeader
}