// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, DeviceEventEmitter, TouchableOpacity, StyleSheet } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, ProgressBar, KeyboardAwareScrollView, Avatar } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomDatePicker, CustomModal, CustomNumberInput, CustomSelect, CustomTextAreaField, CustomTextField, CustomTextFieldWithScanner, CustomUpload, DangerButton, Heading, Label, PrimaryButton, Row, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import { Icon } from "react-native-eva-icons"
import { CustomSelectItemForContacts, TransactionPDFPreview, invoiceItemAdjustment } from "../../../../../models/accountancy/transaction"
import { create } from 'zustand'
import { FlashList } from "@shopify/flash-list";
import { GlobalModalStore } from "../../../../../models/ModalStore"
import { App } from 'antd';
import * as Sharing from 'expo-sharing';
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import CalendarStrip from 'react-native-calendar-strip';
import moment from "moment"
import Svg, { Circle, Path, Rect } from "react-native-svg"
import { Agenda } from 'react-native-calendars';
import { AgendaIcon, DateIcon, InviteIcon } from "../../../../../models/taqwa/Meeting/Meetings"
import RenderHtml from 'react-native-render-html';
import FaceAuth from "../main/FaceAuth/FaceAuth"
import * as Location from 'expo-location';
import EventsScreen from "./EventsScreen"
import { GlobalLoaderStore } from "../../../../../models/GlobalLoaderStore"
import io from 'socket.io-client';
import Config from "../../../../../config"
import { KeyboardAvoidingView } from 'react-native';
import { ErrorMessage } from "./DailyActivityScreen"

const socket = io(Config.API_URL);

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}));

const DateSvg = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <Path
            fill="#33A9FF"
            d="M6.81 18.756h10.373c1.635 0 2.486-.844 2.486-2.456V7.132c0-1.612-.851-2.456-2.486-2.456H6.81c-1.627 0-2.478.836-2.478 2.456V16.3c0 1.612.851 2.456 2.478 2.456Zm-.008-1.5c-.625 0-.972-.323-.972-.986V9.339c0-.663.347-.987.972-.987H17.19c.625 0 .971.324.971.987v6.93c0 .664-.346.988-.971.988H6.8Zm3.744-6.305h.445c.27 0 .361-.083.361-.346v-.445c0-.271-.09-.354-.361-.354h-.445c-.271 0-.362.083-.362.354v.445c0 .263.09.346.362.346Zm2.463 0h.445c.264 0 .354-.083.354-.346v-.445c0-.271-.09-.354-.354-.354h-.445c-.27 0-.361.083-.361.354v.445c0 .263.09.346.361.346Zm2.456 0h.445c.271 0 .361-.083.361-.346v-.445c0-.271-.09-.354-.361-.354h-.445c-.263 0-.354.083-.354.354v.445c0 .263.09.346.354.346ZM8.09 13.377h.437c.271 0 .362-.083.362-.354v-.445c0-.263-.09-.346-.362-.346H8.09c-.271 0-.361.083-.361.346v.445c0 .271.09.354.361.354Zm2.456 0h.445c.27 0 .361-.083.361-.354v-.445c0-.263-.09-.346-.361-.346h-.445c-.271 0-.362.083-.362.346v.445c0 .271.09.354.362.354Zm2.463 0h.445c.264 0 .354-.083.354-.354v-.445c0-.263-.09-.346-.354-.346h-.445c-.27 0-.361.083-.361.346v.445c0 .271.09.354.361.354Zm2.456 0h.445c.271 0 .361-.083.361-.354v-.445c0-.263-.09-.346-.361-.346h-.445c-.263 0-.354.083-.354.346v.445c0 .271.09.354.354.354ZM8.09 15.795h.437c.271 0 .362-.083.362-.346v-.445c0-.271-.09-.354-.362-.354H8.09c-.271 0-.361.083-.361.354v.445c0 .263.09.346.361.346Zm2.456 0h.445c.27 0 .361-.083.361-.346v-.445c0-.271-.09-.354-.361-.354h-.445c-.271 0-.362.083-.362.354v.445c0 .263.09.346.362.346Zm2.463 0h.445c.264 0 .354-.083.354-.346v-.445c0-.271-.09-.354-.354-.354h-.445c-.27 0-.361.083-.361.354v.445c0 .263.09.346.361.346Z"
        />
    </Svg>
)

const GroupChatMessages = observer(function GroupChatMessages(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    let route = props?.route || {};
    let queryParams = { ...(props?.route?.params || {}) };

    if (Platform.OS === 'web') {
        navigate = useNavigate();
        let path = window?.location?.pathname;
        route = { name: path.split("/")[1] || 'Items' }

        const params = window.location.search.substring(1).split('&')
        params.map((param) => {
            let [key, value] = param.split('=')
            if (key) {
                key = key.replace(/%20/g, ' ');
                value = value.replace(/%20/g, ' ');

                queryParams[key] = value;
            }
        })
    }

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const goBack = () => {
        if (Platform.OS === 'web') {
            window.history.back();
        } else {
            navigation.goBack();
        }
    }

    const moduleName = "ChatMessages";
    const { fields, dataTabs, preferencesFields, ExtraSaveComponent } = api.getForm(moduleName);
    const params = {};

    const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
    const globalData = GlobalMasterStore((state) => state.globalData);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const employee = GlobalMasterStore((state) => state.employee);
    const setEmployee = GlobalMasterStore((state) => state.setEmployee);
    const setLoading = GlobalLoaderStore((state) => state.setLoading);

    // employee_id: { type: Types.ObjectId, default: null, ref: 'Employees' },
    // employee: { type: new mongoose.Schema({ 
    //     _id: Types.ObjectId, 
    //     name: String, 
    //     image: String 
    // }, { _id: false }), default: {} },
    // system_message: { type: Boolean, default: false },
    // message_type: { type: String, required: true },
    // message: { type: String, required: true },
    // message_data: { type: mongoose.Schema.Types.Mixed, default: {} },

    // create 5 sample messages based on the above schema, with some messages sent by the employee and some system messages and some sent by other employees and some with attachments
    const [messages, setMessages] = useState(
        [
            // {
            //     employee_id: "6592cc34251640fcd80ed43c",
            //     employee: { _id: "6592cc34251640fcd80ed43c", name: "Faizan Khan (RED DOT TESTER)", image: "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?w=740&t=st=1711256081~exp=1711256681~hmac=2ea19b1030eae67a8f9e4778b86e184d2f7338eec087e8d4f9c5d077f9b3ca9d" },
            //     message: "Hello",
            //     message_type: "text",
            //     system_message: false,
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // },
            // {
            //     employee_id: "2",
            //     employee: { _id: "2", name: "Jane Doe", image: "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?w=740&t=st=1711256081~exp=1711256681~hmac=2ea19b1030eae67a8f9e4778b86e184d2f7338eec087e8d4f9c5d077f9b3ca9d" },
            //     message: "Hello",
            //     message_type: "text",
            //     system_message: false,
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // },
            // {
            //     employee_id: null,
            //     employee: null,
            //     message_type: "text",
            //     system_message: true,
            //     message: "Jane Doe has joined the chat",
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // }, {
            //     employee_id: "6592cc34251640fcd80ed43c",
            //     employee: { _id: "6592cc34251640fcd80ed43c", name: "Faizan Khan (RED DOT TESTER)", image: "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?w=740&t=st=1711256081~exp=1711256681~hmac=2ea19b1030eae67a8f9e4778b86e184d2f7338eec087e8d4f9c5d077f9b3ca9d" },
            //     message: "Hello",
            //     message_type: "text",
            //     system_message: false,
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // },
            // {
            //     employee_id: "2",
            //     employee: { _id: "2", name: "Jane Doe", image: "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?w=740&t=st=1711256081~exp=1711256681~hmac=2ea19b1030eae67a8f9e4778b86e184d2f7338eec087e8d4f9c5d077f9b3ca9d" },
            //     message: "Hello",
            //     message_type: "text",
            //     system_message: false,
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // },
            // {
            //     employee_id: null,
            //     employee: null,
            //     message_type: "text",
            //     system_message: true,
            //     message: "Jane Doe has joined the chat",
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // }, {
            //     employee_id: "6592cc34251640fcd80ed43c",
            //     employee: { _id: "6592cc34251640fcd80ed43c", name: "Faizan Khan (RED DOT TESTER)", image: "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?w=740&t=st=1711256081~exp=1711256681~hmac=2ea19b1030eae67a8f9e4778b86e184d2f7338eec087e8d4f9c5d077f9b3ca9d" },
            //     message: "Hello",
            //     message_type: "text",
            //     system_message: false,
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // },
            // {
            //     employee_id: "2",
            //     employee: { _id: "2", name: "Jane Doe", image: "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?w=740&t=st=1711256081~exp=1711256681~hmac=2ea19b1030eae67a8f9e4778b86e184d2f7338eec087e8d4f9c5d077f9b3ca9d" },
            //     message: "Hello",
            //     message_type: "text",
            //     system_message: false,
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // },
            // {
            //     employee_id: null,
            //     employee: null,
            //     message_type: "text",
            //     system_message: true,
            //     message: "Jane Doe has joined the chat",
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // }, {
            //     employee_id: "6592cc34251640fcd80ed43c",
            //     employee: { _id: "6592cc34251640fcd80ed43c", name: "Faizan Khan (RED DOT TESTER)", image: "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?w=740&t=st=1711256081~exp=1711256681~hmac=2ea19b1030eae67a8f9e4778b86e184d2f7338eec087e8d4f9c5d077f9b3ca9d" },
            //     message: "Hello",
            //     message_type: "text",
            //     system_message: false,
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // },
            // {
            //     employee_id: "2",
            //     employee: { _id: "2", name: "Jane Doe", image: "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?w=740&t=st=1711256081~exp=1711256681~hmac=2ea19b1030eae67a8f9e4778b86e184d2f7338eec087e8d4f9c5d077f9b3ca9d" },
            //     message: "Hello",
            //     message_type: "text",
            //     system_message: false,
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // },
            // {
            //     employee_id: null,
            //     employee: null,
            //     message_type: "text",
            //     system_message: true,
            //     message: "Jane Doe has joined the chat",
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // }, {
            //     employee_id: "6592cc34251640fcd80ed43c",
            //     employee: { _id: "6592cc34251640fcd80ed43c", name: "Faizan Khan (RED DOT TESTER)", image: "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?w=740&t=st=1711256081~exp=1711256681~hmac=2ea19b1030eae67a8f9e4778b86e184d2f7338eec087e8d4f9c5d077f9b3ca9d" },
            //     message: "Hello",
            //     message_type: "text",
            //     system_message: false,
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // },
            // {
            //     employee_id: "2",
            //     employee: { _id: "2", name: "Jane Doe", image: "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?w=740&t=st=1711256081~exp=1711256681~hmac=2ea19b1030eae67a8f9e4778b86e184d2f7338eec087e8d4f9c5d077f9b3ca9d" },
            //     message: "Hello",
            //     message_type: "text",
            //     system_message: false,
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // },
            // {
            //     employee_id: null,
            //     employee: null,
            //     message_type: "text",
            //     system_message: true,
            //     message: "Jane Doe has joined the chat",
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // }, {
            //     employee_id: "6592cc34251640fcd80ed43c",
            //     employee: { _id: "6592cc34251640fcd80ed43c", name: "Faizan Khan (RED DOT TESTER)", image: "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?w=740&t=st=1711256081~exp=1711256681~hmac=2ea19b1030eae67a8f9e4778b86e184d2f7338eec087e8d4f9c5d077f9b3ca9d" },
            //     message: "Hello",
            //     message_type: "text",
            //     system_message: false,
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // },
            // {
            //     employee_id: "2",
            //     employee: { _id: "2", name: "Jane Doe", image: "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?w=740&t=st=1711256081~exp=1711256681~hmac=2ea19b1030eae67a8f9e4778b86e184d2f7338eec087e8d4f9c5d077f9b3ca9d" },
            //     message: "Hello",
            //     message_type: "text",
            //     system_message: false,
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // },
            // {
            //     employee_id: null,
            //     employee: null,
            //     message_type: "text",
            //     system_message: true,
            //     message: "Jane Doe has joined the chat",
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // }, {
            //     employee_id: "6592cc34251640fcd80ed43c",
            //     employee: { _id: "6592cc34251640fcd80ed43c", name: "Faizan Khan (RED DOT TESTER)", image: "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?w=740&t=st=1711256081~exp=1711256681~hmac=2ea19b1030eae67a8f9e4778b86e184d2f7338eec087e8d4f9c5d077f9b3ca9d" },
            //     message: "Hello",
            //     message_type: "text",
            //     system_message: false,
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // },
            // {
            //     employee_id: "2",
            //     employee: { _id: "2", name: "Jane Doe", image: "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?w=740&t=st=1711256081~exp=1711256681~hmac=2ea19b1030eae67a8f9e4778b86e184d2f7338eec087e8d4f9c5d077f9b3ca9d" },
            //     message: "Hello",
            //     message_type: "text",
            //     system_message: false,
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // },
            // {
            //     employee_id: null,
            //     employee: null,
            //     message_type: "text",
            //     system_message: true,
            //     message: "Jane Doe has joined the chat",
            //     message_data: {},
            //     createdAt: new Date().toISOString()
            // },
        ]
    );

    const [page, setPage] = useState(1);
    const [newMessage, setNewMessage] = useState('');

    const getGroupChatMessages = async () => {
        // setLoading(true);
        const { data } = await api.get(moduleName, {
            "page": page,
            "limit": 10,
            "sortColumn": "createdAt",
            "sortDirection": "desc",
            "filters": [],
            "tabFilter": {
                group_id: queryParams?.group_id
            },
            "search": "",
            "groupBy": null
        });

        setMessages((prevMessages) => [...prevMessages, ...data.data]);
        // setLoading(false);
    }

    useEffect(() => {
        getGroupChatMessages();
    }, [page, queryParams?.group_id]);

    const sendMessage = async (text) => {
        if (newMessage.trim() || text) {
            const messageData = {
                _id: Date.now(),
                employee_id: employee?._id,
                employee: employee,
                message: text ? text : newMessage,
                message_type: 'text',
                message_data: {},
                createdAt: new Date().toISOString(),
            };
            setMessages((prevMessages) => [messageData, ...prevMessages]);

            await api.customRoute("group-chat/send-message", {
                group_id: queryParams?.group_id,
                sent_by: employee?._id,
                message: messageData.message,
                message_type: messageData.message_type,
                message_data: messageData.message_data,
            });

            setNewMessage('');
        }
    };

    const sendAttachment = async (type) => {
        try {
            let result;
            if (type === 'image') {
                result = await ImagePicker.launchImageLibraryAsync({
                    mediaTypes: ImagePicker.MediaTypeOptions.Images,
                    allowsEditing: true,
                    aspect: [4, 3],
                    quality: 1,
                });
            } else if (type === 'document') {
                result = await DocumentPicker.getDocumentAsync({
                    type: '*/*',
                    copyToCacheDirectory: true,
                });
            } else if (type === 'audio') {
                result = await AudioPicker.getAudioAsync();
            }

            if (!result.cancelled) {
                const messageData = {
                    id: Date.now(),
                    sender: 'John', // Replace with the actual sender
                    message: result.uri,
                    type,
                    createdAt: new Date().toISOString(),
                };
                setMessages((prevMessages) => [...prevMessages, messageData]);
            }
        } catch (error) {
            console.error('Error sending attachment:', error);
        }
    };

    const renderMessage = ({ item }) => {
        const isMyMessage = item.employee_id == globalData?.employee_id;

        return (
            <View center={item.system_message} style={{ flexDirection: isMyMessage ? 'row-reverse' : 'row', marginVertical: 8, width: "100%" }}>
                <TouchableOpacity onPress={() => {
                    if (item.message_data?.type == "app_link") {
                        navigationFn(item.message_data?.path, item.message_data?.params);
                    }
                }}
                    style={[item.system_message && {
                        width: "100%",
                    }]}
                >
                    <View
                        style={[
                            {
                                backgroundColor: item.system_message ? "#f5f5f5" : isMyMessage ? '#DCF8C6' : '#E8E8E8',
                                borderRadius: 8,
                                padding: 8,
                                marginLeft: isMyMessage ? 80 : 8,
                            },
                            item.system_message && { alignSelf: 'center', width: '100%', maxWidth: '100%', padding: 8, borderRadius: 8, flex: 1 }
                        ]}
                    >
                        {!item.system_message && !isMyMessage && (
                            <View marginB-4>
                                <Text bold style={{ fontSize: 12, color: '#29459c' }}>
                                    {item.employee?.name}
                                </Text>
                            </View>
                        )}
                        {item.message_type === 'text' && <Text monoTableHeading={item.system_message}>{item.message}</Text>}
                        {item.message_type === 'image' && <Image source={{ uri: item.message }} style={{ width: 200, height: 200 }} />}
                        {item.message_type === 'document' && <Text>Document: {item.message}</Text>}
                        {item.message_type === 'audio' && <Text>Audio: {item.message}</Text>}
                        <Text monoTableHeading={item.system_message} style={{ fontSize: 10, color: 'gray', marginTop: 4 }}>
                            {moment(item.createdAt).format('MMM D, YYYY h:mm A')}
                        </Text>
                    </View>
                </TouchableOpacity>
            </View>
        );
    };

    useEffect(() => {
        socket.on("group-" + queryParams?.group_id, (newMessage) => {
            if (newMessage.sent_by != employee?._id) {
                setMessages((prevMessages) => [newMessage.chatMessage, ...prevMessages]);
            }
        });

        return () => {
            socket.off("group-" + queryParams?.group_id);
        };
    }, []);


    return (
        <KeyboardAvoidingView
            style={{ flex: 1 }}
            behavior={Platform.OS === 'ios' ? 'height' : 'height'}
            keyboardVerticalOffset={Platform.OS === 'ios' ? 100 : 0}
        >
            <View style={{
                flex: 1
            }}>
                <Card padding-20 left
                    style={{
                        flex: 1,
                    }}
                >
                    <View>
                        <Text h1>{queryParams?.group_name} Chat</Text>
                    </View>

                    <View style={{
                        borderBottomWidth: 1,
                        borderBottomColor: "#E5E5E5",
                        borderStyle: "solid",
                        marginVertical: 10,
                        width: "100%"
                    }}></View>

                    {messages.length != 0 && <FlatList
                        style={{
                            flex: 1,
                            width: '100%',
                        }}
                        data={messages}
                        renderItem={renderMessage}
                        keyExtractor={(item) => item._id}
                        inverted
                        keyboardShouldPersistTaps="always"
                        keyboardDismissMode="on-drag"
                        onEndReachedThreshold={0.5}
                        onEndReached={() => setPage((prevPage) => prevPage + 1)}
                    />}

                    {messages.length == 0 && <View marginT-50 center style={{
                        flex: 1,
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <ErrorMessage />
                        <Text marginT-20 center>Start a conversation</Text>
                    </View>}

                    <View row marginT-20 style={{ flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                        <View style={{
                            flex: 1,
                        }}>
                            <CustomTextAreaField
                                value={newMessage}
                                placeholder="Type a message..."
                                onChange={(value) => {
                                    setNewMessage(value);
                                }}
                            />
                        </View>
                        <PrimaryButton onPress={sendMessage} style={{ height: 38, marginLeft: 10 }}>
                            <View row>
                                <Icon name="paper-plane-outline" width={16} height={16} fill="white" />
                            </View>
                        </PrimaryButton>
                    </View>
                </Card>
            </View>
        </KeyboardAvoidingView>
    )
})

export default GroupChatMessages

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: 10,
        paddingHorizontal: 16,
        borderBottomWidth: 1,
        borderBottomColor: "#E5E5E5",
    },
    avatarContainer: {
        position: "relative",
    },
    unreadBadge: {
        backgroundColor: "#FF4500",
        borderRadius: 10,
        position: "absolute",
        bottom: -2,
        right: -4,
        paddingHorizontal: 5,
        paddingVertical: 2,
    },
    unreadBadgeText: {
        color: "#FFFFFF",
        fontWeight: "bold",
        fontSize: 12,
    },
    contentContainer: {
        flex: 1,
        marginLeft: 12,
    },
    groupName: {
        fontWeight: "bold",
        fontSize: 16,
        marginBottom: 4,
    },
    lastMessageContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    lastMessage: {
        flex: 1,
        fontSize: 14,
        color: "#888888",
    },
    lastMessageTime: {
        fontSize: 12,
        color: "#888888",
        marginLeft: 8,
    },
    messageContainer: {
        flexDirection: 'row',
        marginVertical: 8,
    },
    selfMessageContainer: {
        justifyContent: 'flex-end',
    },
    otherMessageContainer: {
        justifyContent: 'flex-start',
    },
    avatarContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 8,
    },
    employeeName: {
        marginLeft: 8,
        fontWeight: 'bold',
    },
    systemMessageContainer: {
        alignSelf: 'center',
        backgroundColor: '#F0F0F0',
        padding: 8,
        borderRadius: 8,
    },
    systemMessage: {
        color: '#888',
    },
    messageContent: {
        maxWidth: '70%',
        padding: 8,
        borderRadius: 8,
    },
    selfMessageContent: {
        backgroundColor: '#DCF8C6',
        alignSelf: 'flex-end',
    },
    otherMessageContent: {
        backgroundColor: '#FFFFFF',
        alignSelf: 'flex-start',
    },
    image: {
        width: 200,
        height: 200,
        resizeMode: 'cover',
        borderRadius: 8,
    },
    documentContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#F0F0F0',
        padding: 8,
        borderRadius: 8,
    },
    documentName: {
        marginLeft: 8,
        color: '#888',
    },
});