// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, DeviceEventEmitter, TouchableOpacity } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, ProgressBar } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomDatePicker, CustomModal, CustomNumberInput, CustomSelect, CustomTextAreaField, CustomTextField, CustomTextFieldWithScanner, CustomUpload, DangerButton, Heading, Label, PrimaryButton, Row, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import { Icon } from "react-native-eva-icons"
import { CustomSelectItemForContacts, TransactionPDFPreview, invoiceItemAdjustment } from "../../../../../models/accountancy/transaction"
import { create } from 'zustand'
import { FlashList } from "@shopify/flash-list";
import { GlobalModalStore } from "../../../../../models/ModalStore"
import { App } from 'antd';
import * as Sharing from 'expo-sharing';
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import CalendarStrip from 'react-native-calendar-strip';
import moment from "moment"
import Svg, { Circle, ClipPath, Defs, G, Path, Rect } from "react-native-svg"
import { Agenda } from 'react-native-calendars';
import { AgendaIcon, DateIcon, InviteIcon } from "../../../../../models/taqwa/Meeting/Meetings"
import RenderHtml from 'react-native-render-html';
import FaceAuth from "../main/FaceAuth/FaceAuth"
import * as Location from 'expo-location';
import EventsScreen from "./EventsScreen"
import ListingScreen from "../../../CRUD/ListingScreen"

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}));

const DateSvg = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <Path
            fill="#33A9FF"
            d="M6.81 18.756h10.373c1.635 0 2.486-.844 2.486-2.456V7.132c0-1.612-.851-2.456-2.486-2.456H6.81c-1.627 0-2.478.836-2.478 2.456V16.3c0 1.612.851 2.456 2.478 2.456Zm-.008-1.5c-.625 0-.972-.323-.972-.986V9.339c0-.663.347-.987.972-.987H17.19c.625 0 .971.324.971.987v6.93c0 .664-.346.988-.971.988H6.8Zm3.744-6.305h.445c.27 0 .361-.083.361-.346v-.445c0-.271-.09-.354-.361-.354h-.445c-.271 0-.362.083-.362.354v.445c0 .263.09.346.362.346Zm2.463 0h.445c.264 0 .354-.083.354-.346v-.445c0-.271-.09-.354-.354-.354h-.445c-.27 0-.361.083-.361.354v.445c0 .263.09.346.361.346Zm2.456 0h.445c.271 0 .361-.083.361-.346v-.445c0-.271-.09-.354-.361-.354h-.445c-.263 0-.354.083-.354.354v.445c0 .263.09.346.354.346ZM8.09 13.377h.437c.271 0 .362-.083.362-.354v-.445c0-.263-.09-.346-.362-.346H8.09c-.271 0-.361.083-.361.346v.445c0 .271.09.354.361.354Zm2.456 0h.445c.27 0 .361-.083.361-.354v-.445c0-.263-.09-.346-.361-.346h-.445c-.271 0-.362.083-.362.346v.445c0 .271.09.354.362.354Zm2.463 0h.445c.264 0 .354-.083.354-.354v-.445c0-.263-.09-.346-.354-.346h-.445c-.27 0-.361.083-.361.346v.445c0 .271.09.354.361.354Zm2.456 0h.445c.271 0 .361-.083.361-.354v-.445c0-.263-.09-.346-.361-.346h-.445c-.263 0-.354.083-.354.346v.445c0 .271.09.354.354.354ZM8.09 15.795h.437c.271 0 .362-.083.362-.346v-.445c0-.271-.09-.354-.362-.354H8.09c-.271 0-.361.083-.361.354v.445c0 .263.09.346.361.346Zm2.456 0h.445c.27 0 .361-.083.361-.346v-.445c0-.271-.09-.354-.361-.354h-.445c-.271 0-.362.083-.362.354v.445c0 .263.09.346.362.346Zm2.463 0h.445c.264 0 .354-.083.354-.346v-.445c0-.271-.09-.354-.354-.354h-.445c-.27 0-.361.083-.361.354v.445c0 .263.09.346.361.346Z"
        />
    </Svg>
)

const DASComponent = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={79}
        height={92}
        fill="none"
        {...props}
    >
        <Path
            fill="#fff"
            d="M49.836 77.52v14.16L70.245 74.4H53.158c-1.66 0-3.322 1.44-3.322 3.12Zm17.087-69.6H3.085C1.424 7.92 0 9.84 0 11.52V88.8c0 1.68 1.187 2.88 2.848 2.88H46.75V74.4c0-1.68 1.661-3.36 3.085-3.36h20.409v-60c0-1.44-1.661-3.36-3.322-3.12Zm-52.21 58.56c-2.135 0-3.797-1.68-3.797-3.84s1.662-3.84 3.797-3.84c2.136 0 3.798 1.68 3.798 3.84 0 1.92-1.662 3.84-3.797 3.84Zm0-17.52c-2.135 0-3.797-1.68-3.797-3.84s1.662-3.84 3.797-3.84c2.136 0 3.798 1.68 3.798 3.84s-1.662 3.84-3.797 3.84Zm0-17.28c-2.135 0-3.797-1.68-3.797-3.84S12.578 24 14.713 24c2.136 0 3.798 1.68 3.798 3.84 0 1.92-1.662 3.84-3.797 3.84Zm41.293 31.44c0 .96-.712 1.68-1.661 1.68H26.342c-.95 0-1.661-.72-1.661-1.68v-1.68c0-.96.712-1.68 1.66-1.68h28.004c.95 0 1.661.72 1.661 1.68v1.68Zm0-17.28c0 .96-.712 1.68-1.661 1.68H26.342c-.95 0-1.661-.72-1.661-1.68v-1.68c0-.96.712-1.68 1.66-1.68h28.004c.95 0 1.661.72 1.661 1.68v1.68Zm0-17.28c0 .96-.712 1.68-1.661 1.68H26.342c-.95 0-1.661-.72-1.661-1.68v-1.68c0-.96.712-1.68 1.66-1.68h28.004c.95 0 1.661.72 1.661 1.68v1.68ZM75.228 0H10.206C7.83 0 6.17 2.4 6.17 4.8h64.075c1.661 0 3.085 1.68 3.085 3.12v58.56c2.373 0 4.746-1.44 4.746-3.84V2.88C77.84 1.2 76.652 0 75.23 0Z"
        />
    </Svg>
)

const SASComponent = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={105}
        height={104}
        fill="none"
        {...props}
    >
        <G clipPath="url(#a)" filter="url(#b)" opacity={0.15}>
            <Path
                fill="#fff"
                d="M23.065 90.302A2.698 2.698 0 0 1 20.37 93H9.696A2.698 2.698 0 0 1 7 90.302V74.227a2.698 2.698 0 0 1 2.696-2.698H20.37c1.46 0 2.696 1.18 2.696 2.698v16.075Zm24.098 0A2.698 2.698 0 0 1 44.468 93H33.794a2.698 2.698 0 0 1-2.696-2.698V55.51a2.698 2.698 0 0 1 2.696-2.698h10.673c1.46 0 2.697 1.18 2.697 2.698v34.792Zm26.739 0A2.698 2.698 0 0 1 71.206 93H60.533a2.698 2.698 0 0 1-2.697-2.698V66.189a2.698 2.698 0 0 1 2.697-2.698h10.673c1.46 0 2.696 1.18 2.696 2.698v24.113Zm24.098 0A2.698 2.698 0 0 1 95.304 93H84.63a2.698 2.698 0 0 1-2.696-2.698v-48.17a2.698 2.698 0 0 1 2.696-2.698h10.673c1.46 0 2.696 1.18 2.696 2.698v48.17Zm-8.426-70.035 5.842 5.846c.955.956 2.584.281 2.584-1.068V3.518A1.51 1.51 0 0 0 96.483 2H74.97c-1.348 0-2.022 1.63-1.067 2.586l6.01 6.014c.618.618.618 1.574 0 2.136L72.16 20.38 60.364 32.183c-.618.619-1.573.619-2.134 0L48.119 22.066l-6.517-6.52c-.617-.562-1.572-.562-2.134 0L7.449 47.528c-.28.281-.449.675-.449 1.068v14.333l.112.056 32.3-32.32c.618-.617 1.573-.617 2.134 0l16.627 16.638c.618.619 1.573.619 2.135 0l6.516-6.52L86.26 21.335l1.123-1.124a1.578 1.578 0 0 1 2.191.056Z"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M7 2h91v91H7z" />
            </ClipPath>
        </Defs>
    </Svg>
)

const ChallengesScreen = observer(function ChallengesScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    let queryParams = { ...(props?.route?.params || {}) };

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const goBack = () => {
        if (Platform.OS === 'web') {
            window.history.back();
        } else {
            navigation.goBack();
        }
    }

    const moduleName = "DailyActivities";
    const { fields, dataTabs, preferencesFields, ExtraSaveComponent } = api.getForm(moduleName);
    const params = {};

    const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
    const globalData = GlobalMasterStore((state) => state.globalData);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const employee = GlobalMasterStore((state) => state.employee);

    const employee_id = employee._id;

    const [dates, setDates] = useState([
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
    ]);

    const [month, setMonth] = useState(moment().month() + 1);

    const [attendanceType, setAttendanceType] = useState('Course');
    const [updateKey, setUpdateKey] = useState(null);
    const [goal, setGoal] = useState(null);

    useEffect(() => {
        setUpdateKey(Math.random());
    }, [dates, attendanceType]);

    useEffect(() => {
        async function getGoal() {
            const responseData = await api.get("Goals", {
                page: 1,
                limit: 1,
                sortColumn: "start_date",
                sortDirection: "desc",
                filters: [],
                tabFilter: { ...{ employee_id: employee_id } },
                search: "",
                groupBy: null
            });

            let { data } = responseData;

            setGoal((data?.data || [])[0]);
        }

        getGoal();
    }, []);

    return (
        <View padding-10 style={{
            flex: 1
        }}>
            <PrimaryButton label="VIEW CURRENT GOAL" onPress={() => {
                if (goal) {
                    console.log("Goal", goal);
                    navigationFn('New', {
                        moduleName: 'Goals',
                        id: goal._id,
                        viewOnly: true,
                    });
                } else {
                    Alert.alert("No Goal Found", "No goal is assigned to you yet.");
                }
            }} />
            <ListingScreen
                {...{
                    moduleName: 'Challenges',
                }}
            />
        </View>
    )
})

export default ChallengesScreen