// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, DeviceEventEmitter, TouchableOpacity } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, ProgressBar, KeyboardAwareScrollView } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomDatePicker, CustomModal, CustomNumberInput, CustomSelect, CustomTextAreaField, CustomTextField, CustomTextFieldWithScanner, CustomUpload, DangerButton, Heading, Label, PrimaryButton, Row, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import { Icon } from "react-native-eva-icons"
import { CustomSelectItemForContacts, TransactionPDFPreview, invoiceItemAdjustment } from "../../../../../models/accountancy/transaction"
import { create } from 'zustand'
import { FlashList } from "@shopify/flash-list";
import { GlobalModalStore } from "../../../../../models/ModalStore"
import { App } from 'antd';
import * as Sharing from 'expo-sharing';
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import CalendarStrip from 'react-native-calendar-strip';
import moment from "moment"
import Svg, { Circle, Path, Rect } from "react-native-svg"
import { Agenda } from 'react-native-calendars';
import { AgendaIcon, DateIcon, InviteIcon } from "../../../../../models/taqwa/Meeting/Meetings"
import RenderHtml from 'react-native-render-html';
import FaceAuth from "../main/FaceAuth/FaceAuth"
import * as Location from 'expo-location';
import EventsScreen from "./EventsScreen"
import { GlobalLoaderStore } from "../../../../../models/GlobalLoaderStore"

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}));

const DateSvg = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <Path
            fill="#33A9FF"
            d="M6.81 18.756h10.373c1.635 0 2.486-.844 2.486-2.456V7.132c0-1.612-.851-2.456-2.486-2.456H6.81c-1.627 0-2.478.836-2.478 2.456V16.3c0 1.612.851 2.456 2.478 2.456Zm-.008-1.5c-.625 0-.972-.323-.972-.986V9.339c0-.663.347-.987.972-.987H17.19c.625 0 .971.324.971.987v6.93c0 .664-.346.988-.971.988H6.8Zm3.744-6.305h.445c.27 0 .361-.083.361-.346v-.445c0-.271-.09-.354-.361-.354h-.445c-.271 0-.362.083-.362.354v.445c0 .263.09.346.362.346Zm2.463 0h.445c.264 0 .354-.083.354-.346v-.445c0-.271-.09-.354-.354-.354h-.445c-.27 0-.361.083-.361.354v.445c0 .263.09.346.361.346Zm2.456 0h.445c.271 0 .361-.083.361-.346v-.445c0-.271-.09-.354-.361-.354h-.445c-.263 0-.354.083-.354.354v.445c0 .263.09.346.354.346ZM8.09 13.377h.437c.271 0 .362-.083.362-.354v-.445c0-.263-.09-.346-.362-.346H8.09c-.271 0-.361.083-.361.346v.445c0 .271.09.354.361.354Zm2.456 0h.445c.27 0 .361-.083.361-.354v-.445c0-.263-.09-.346-.361-.346h-.445c-.271 0-.362.083-.362.346v.445c0 .271.09.354.362.354Zm2.463 0h.445c.264 0 .354-.083.354-.354v-.445c0-.263-.09-.346-.354-.346h-.445c-.27 0-.361.083-.361.346v.445c0 .271.09.354.361.354Zm2.456 0h.445c.271 0 .361-.083.361-.354v-.445c0-.263-.09-.346-.361-.346h-.445c-.263 0-.354.083-.354.346v.445c0 .271.09.354.354.354ZM8.09 15.795h.437c.271 0 .362-.083.362-.346v-.445c0-.271-.09-.354-.362-.354H8.09c-.271 0-.361.083-.361.354v.445c0 .263.09.346.361.346Zm2.456 0h.445c.27 0 .361-.083.361-.346v-.445c0-.271-.09-.354-.361-.354h-.445c-.271 0-.362.083-.362.354v.445c0 .263.09.346.362.346Zm2.463 0h.445c.264 0 .354-.083.354-.346v-.445c0-.271-.09-.354-.354-.354h-.445c-.27 0-.361.083-.361.354v.445c0 .263.09.346.361.346Z"
        />
    </Svg>
)

const ProfileScreen = observer(function ProfileScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    let queryParams = { ...(props?.route?.params || {}) };

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const goBack = () => {
        if (Platform.OS === 'web') {
            window.history.back();
        } else {
            navigation.goBack();
        }
    }

    const moduleName = "Employees";
    const { fields, dataTabs, preferencesFields, ExtraSaveComponent } = api.getForm(moduleName);
    const params = {};

    const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
    const globalData = GlobalMasterStore((state) => state.globalData);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const employee = GlobalMasterStore((state) => state.employee);
    const setEmployee = GlobalMasterStore((state) => state.setEmployee);
    const setLoading = GlobalLoaderStore((state) => state.setLoading);
    const [employeeData, setEmployeeData] = useState();

    const getEmployeeData = async () => {
        setLoading(true);
        const { data } = await api.getSingle(moduleName, {
            _id: employee._id,
            converting: false,
            isClone: false,
            isEdit: true,
            platform: Platform.OS
        });

        const employeeData = {
            _id: data?._id,
            name: data?.name,
            email: data?.email,
            contact_number: data?.contact_number,
            user_id: data?.user_id,
            role_id: data?.role_id,
            employee_code: data?.employee_code,
            account_id: data?.account_id,
            image: data?.image,
            enable_face_auth: data?.enable_face_auth,
            face_auth_image_url: data?.face_auth_image_url,
        }

        setEmployee(employeeData);
        setEmployeeData(data);

        setLoading(false);
    }

    useEffect(() => {
        getEmployeeData();
    }, []);


    return (
        <View style={{
            flex: 1
        }}>
            <Card padding-20 left>
                <KeyboardAwareScrollView
                    style={{
                        height: '100%'
                    }}
                >
                    {Label({ label: "Profile Picture" })}

                    <View left style={{
                        marginLeft: -20,
                        marginTop: 10
                    }}>
                        <CustomUpload
                            value={employeeData?.image}
                            onChange={(value) => {
                                setEmployeeData({ ...employeeData, image: value });
                            }}
                            placeholder="Select Image"
                            label="Select Image"
                            moduleName={moduleName}
                            width="100%"
                            mimeType="image/*"
                        />
                    </View>

                    <View style={{ height: 10 }}></View>

                    {employeeData?.lock_face_auth_image == false ? <>
                        {Label({ label: "Face Auth Image" })}

                        <View left style={{
                            marginLeft: -20,
                            marginTop: 10
                        }}>
                            <CustomUpload
                                value={employeeData?.face_auth_image_url}
                                onChange={(value) => {
                                    setEmployeeData({ ...employeeData, face_auth_image_url: value });
                                }}
                                placeholder="Select Image"
                                label="Select Image"
                                moduleName={moduleName}
                                width="100%"
                                mimeType="image/*"
                            />
                        </View>

                        <View style={{ height: 10 }}></View>
                    </> : <>
                        {Label({ label: "Face Auth Image" })}

                        <View left style={{
                            marginLeft: -20,
                            marginTop: 10
                        }}>
                            <CustomUpload
                                value={employeeData?.face_auth_image_url}
                                onChange={(value) => {
                                    setEmployeeData({ ...employeeData, face_auth_image_url: value });
                                }}
                                placeholder="Select Image"
                                label="Select Image"
                                moduleName={moduleName}
                                width="100%"
                                mimeType="image/*"
                                readOnly={true}
                            />
                        </View>

                        <View style={{ height: 10 }}></View>
                    </>}

                    <View row centerV style={{
                        width: '100%'
                    }}>
                        <View style={{ width: '30%' }}>
                            {Label({ label: "Name" })}
                        </View>

                        <View style={{
                            width: '70%'
                        }}>
                            <CustomTextField
                                value={employeeData?.name}
                                onChange={(value) => {
                                    setEmployeeData({ ...employeeData, name: value });
                                }}
                                placeholder="Name"
                                label="Name"
                                width="100%"
                            />
                        </View>
                    </View>

                    <View row centerV style={{
                        width: '100%'
                    }}>
                        <View style={{ width: '30%' }}>
                            {Label({ label: "Nick Name" })}
                        </View>

                        <View style={{
                            width: '70%'
                        }}>
                            <CustomTextField
                                value={employeeData?.nickname}
                                onChange={(value) => {
                                    setEmployeeData({ ...employeeData, nickname: value });
                                }}
                                placeholder="Nick Name"
                                label="Nick Name"
                                width="100%"
                            />
                        </View>
                    </View>

                    <View style={{ width: '100%', height: 1, backgroundColor: '#f0f0f0', marginVertical: 0 }}></View>

                    <View centerV row style={{
                        width: '100%'
                    }}>
                        <View style={{ width: '30%' }}>
                            {Label({ label: "Email" })}
                        </View>

                        <View style={{
                            width: '70%'
                        }}>
                            <CustomTextField
                                value={employeeData?.email}
                                onChange={(value) => {
                                    setEmployeeData({ ...employeeData, email: value });
                                }}
                                placeholder="Email"
                                label="Email"
                                width="100%"
                            />
                        </View>
                    </View>

                    <View style={{ width: '100%', height: 1, backgroundColor: '#f0f0f0', marginVertical: 0 }}></View>

                    <View centerV row style={{
                        width: '100%'
                    }}>
                        <View style={{ width: '30%' }}>
                            {Label({ label: "Phone" })}
                        </View>

                        <View style={{
                            width: '70%'
                        }}>
                            <CustomTextField
                                value={employeeData?.contact_number}
                                onChange={(value) => {
                                    setEmployeeData({ ...employeeData, contact_number: value });
                                }}
                                placeholder="Phone"
                                label="Phone"
                                width="100%"
                                showCountryCode={true}
                            />
                        </View>
                    </View>

                    <View style={{ width: '100%', height: 1, backgroundColor: '#f0f0f0', marginVertical: 0 }}></View>

                    <PrimaryButton
                        style={{
                            marginTop: 20
                        }}
                        label="Save"
                        onPress={async () => {
                            if (!employeeData?.name) {
                                if (Platform.OS === 'web') {
                                    message.error("Name is required");
                                } else {
                                    Alert.alert("Name is required");
                                }
                                return;
                            }

                            if (!employeeData?.email) {
                                if (Platform.OS === 'web') {
                                    message.error("Email is required");
                                } else {
                                    Alert.alert("Email is required");
                                }
                                return;
                            }

                            setLoading(true);
                            const res = await api.update(moduleName, employeeData, employee._id);

                            if (res.message) {
                                if (Platform.OS === 'web') {
                                    message.success(res.message);
                                } else {
                                    Alert.alert(res.message);
                                }
                            }

                            getEmployeeData();
                        }}
                    />

                </KeyboardAwareScrollView>
            </Card>


        </View>
    )
})

export default ProfileScreen