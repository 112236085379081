import React, { useCallback, useEffect, useState } from "react";
import { View, Card, Text, Button } from "react-native-ui-lib";
import { Col, CustomSelect, CustomTextField, DangerButton, Label, Row, SelectField } from "../../mycomponents/DynamicForm";
import { Platform } from "react-native";
import { addButton } from "../hrms/shiftModel";
import { api } from "../../services/api";
import dayjs from 'dayjs';
import ReportScreenSingle from "../../screens/MainScreens/CRUD/ReportScreenSingle";

export const contactfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const AddressesComponent = ({ field, useForm }) => {
        const addresses = useForm((state) => state.formObject["addresses"]) || [];

        const setFormObject = useForm((state) => state.setFormObject);
        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
        const getFormObject = useForm((state) => state.getFormObject);

        const [states, setStates] = useState({});

        useEffect(() => {
            for (let i = 0; i < addresses.length; i++) {
                const address = addresses[i];

                if (address.country && !states[i]) {
                    (async () => {
                        try {
                            const response = await api.customRoute('Contacts/action', {
                                action: 'getStates',
                                country_id: address.country
                            });

                            const newStates = response;
                            setStates({ ...states, [i]: newStates });
                        } catch (e) {

                        }
                    })();
                }
            }
        }, [addresses]);

        return <>
            <View flex={Platform.OS == 'web'}>
                {addresses && addresses.map((obj, index) => {
                    return <Row key={index} style={{ marginTop: 10, alignItems: 'center' }} >
                        <Col width="100%">
                            <View style={{
                                width: '100%',
                                marginBottom: Platform.OS == 'web' ? 0 : 10,
                            }}>
                                <Text tabHeading>Address {index + 1}</Text>
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }} row={Platform.OS != 'web'}>
                                {Label({ label: 'Country' })}
                                <CustomSelect independent value={obj.country} options={response.Countries || []} onChange={async (value) => {
                                    setFormObjectArray('addresses', index, 'country', value);

                                    try {
                                        const response = await api.customRoute('Contacts/action', {
                                            action: 'getStates',
                                            country_id: value
                                        });

                                        const newStates = response;
                                        setStates({ ...states, [index]: newStates });
                                    } catch (e) {

                                    }
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'State' })}
                                <CustomSelect independent value={obj.state} onChange={(value) => {
                                    setFormObjectArray('addresses', index, 'state', value);
                                }} options={states[index] || []} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Pincode' })}
                                <CustomTextField independent value={addresses[index].pincode} onChange={(value) => {
                                    setFormObjectArray('addresses', index, 'pincode', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'City' })}
                                <CustomTextField independent value={obj.city} onChange={(value) => {
                                    setFormObjectArray('addresses', index, 'city', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Address Line 1' })}
                                <CustomTextField independent value={obj.address_line_1} onChange={(value) => {
                                    setFormObjectArray('addresses', index, 'address_line_1', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Address Line 2' })}
                                <CustomTextField independent value={obj.address_line_2} onChange={(value) => {
                                    setFormObjectArray('addresses', index, 'address_line_2', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Primary Billing Address' })}
                                <CustomSelect independent value={obj.primary_billing} onChange={(value) => {
                                    const newAddresses = [...addresses];
                                    let otherDefault = false;
                                    newAddresses.forEach((data, i) => {
                                        if (i != index && value == "Yes") {
                                            data.primary_billing = "No";
                                        }
                                        if (i != index && value == "No") {
                                            if (!otherDefault) {
                                                data.primary_billing = "Yes";
                                                otherDefault = true;
                                            }
                                        }
                                    });
                                    setFormObjectArray('addresses', index, 'primary_billing', value);
                                }
                                } options={[
                                    { label: 'Yes', value: "Yes" },
                                    { label: 'No', value: "No" },
                                ]} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Primary Shipping Address' })}
                                <CustomSelect independent value={obj.primary_shipping} onChange={(value) => {
                                    const newAddresses = [...addresses];
                                    let otherDefault = false;
                                    newAddresses.forEach((data, i) => {
                                        if (i != index && value == "Yes") {
                                            data.primary_shipping = "No";
                                        }
                                        if (i != index && value == "No") {
                                            if (!otherDefault) {
                                                data.primary_shipping = "Yes";
                                                otherDefault = true;
                                            }
                                        }
                                    });
                                    setFormObjectArray('addresses', index, 'primary_shipping', value);
                                }
                                } options={[
                                    { label: 'Yes', value: "Yes" },
                                    { label: 'No', value: "No" },
                                ]} />
                            </View>
                        </Col>

                        <View style={Platform.OS == 'web' ? { marginLeft: 10 } : {
                            marginTop: 10,
                            width: '100%',
                        }}>
                            <DangerButton
                                style={Platform.OS == 'web' ? {

                                } : {
                                    width: '100%',
                                    height: 42
                                }}
                                label="Delete" onPress={() => {
                                    const newAddresses = [...addresses];
                                    newAddresses.splice(index, 1);
                                    setFormObject({
                                        'addresses': newAddresses
                                    });
                                }} />
                        </View>
                    </Row>
                })}
            </View>
        </>
    }

    const LensDetailsComponent = ({ field, useForm }) => {
        const lens_details = useForm((state) => state.formObject["lens_details"]);
        const setFormObject = useForm((state) => state.setFormObject);
        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
        const getFormObject = useForm((state) => state.getFormObject);
        const [states, setStates] = useState({});

        return <>
            <View flex={Platform.OS == 'web'}>
                {lens_details && [...(lens_details?.reverse() || [])].map((obj, index) => {
                    return <Row key={index} style={{ marginTop: 10, alignItems: 'center' }} >
                        <Col width="100%">
                            <View row centerV spread style={{
                                width: '100%'
                            }}>
                                <Text tabHeading>Eye Details - {obj['date'] ? dayjs(obj['date']).format('DD MMM YYYY hh:mm A') : ''}</Text>
                                <DangerButton
                                    style={{
                                        marginTop: 8
                                    }}
                                    label="Delete" onPress={() => {
                                        const newLensDetails = [...lens_details];
                                        newLensDetails.splice(index, 1);
                                        setFormObject({
                                            'lens_details': newLensDetails
                                        });
                                    }} />
                            </View>
                        </Col>

                        <Col width="100%">
                            <View center style={{
                                width: '100%',
                            }}>
                                <Text text90 style={{ fontWeight: 'bold' }}>Right Eye</Text>
                            </View>
                            <View style={{
                                borderBottomColor: '#d9d9d9',
                                borderBottomWidth: 1,
                                width: '100%',
                                marginBottom: 0,
                                marginTop: 10,
                                borderStyle: 'dashed',
                            }} />
                        </Col>



                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: '' })}
                                <View marginT-40>
                                    {Label({ label: 'Distant' })}
                                </View>
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Sph' })}
                                <CustomTextField independent value={obj.distant_re_sph} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_re_sph', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Cyl' })}
                                <CustomTextField independent value={obj.distant_re_cyl} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_re_cyl', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Axis' })}
                                <CustomTextField independent value={obj.distant_re_axis} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_re_axis', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'VA' })}
                                <CustomTextField independent value={obj.distant_re_va} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_re_va', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: '' })}
                                <View marginT-40>
                                    {Label({ label: 'Add' })}
                                </View>
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Sph' })}
                                <CustomTextField independent value={obj.add_re_sph} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_re_sph', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Cyl' })}
                                <CustomTextField independent value={obj.add_re_cyl} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_re_cyl', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Axis' })}
                                <CustomTextField independent value={obj.add_re_axis} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_re_axis', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'VA' })}
                                <CustomTextField independent value={obj.add_re_va} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_re_va', value);
                                }} />
                            </View>
                        </Col>


                        <Col width="100%">
                            <View center style={{
                                width: '100%',
                            }}>
                                <Text text90 style={{ fontWeight: 'bold' }}>Left Eye</Text>
                            </View>
                            <View style={{
                                borderBottomColor: '#d9d9d9',
                                borderBottomWidth: 1,
                                width: '100%',
                                marginBottom: 0,
                                marginTop: 10,
                                borderStyle: 'dashed',
                            }} />
                        </Col>


                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: '' })}
                                <View marginT-40>
                                    {Label({ label: 'Distant' })}
                                </View>
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Sph' })}
                                <CustomTextField independent value={obj.distant_le_sph} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_le_sph', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Cyl' })}
                                <CustomTextField independent value={obj.distant_le_cyl} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_le_cyl', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Axis' })}
                                <CustomTextField independent value={obj.distant_le_axis} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_le_axis', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'VA' })}
                                <CustomTextField independent value={obj.distant_le_va} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_le_va', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: '' })}
                                <View marginT-40>
                                    {Label({ label: 'Add' })}
                                </View>
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Sph' })}
                                <CustomTextField independent value={obj.add_le_sph} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_le_sph', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Cyl' })}
                                <CustomTextField independent value={obj.add_le_cyl} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_le_cyl', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Axis' })}
                                <CustomTextField independent value={obj.add_le_axis} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_le_axis', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'VA' })}
                                <CustomTextField independent value={obj.add_le_va} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_le_va', value);
                                }} />
                            </View>
                        </Col>

                    </Row>
                })}
            </View>
        </>
    }

    const LedgerAccountComponent = React.memo(({ field, useForm, tKey, tIndex }) => {
        const account_id = useForm((state) => state.formObject['account_id']);

        return <View flex>
            <ReportScreenSingle
                moduleName={"Ledger"}
                hideWrapper={true}
                noMono={true}
                hideHeader={true}
                params={{
                    quickFilterKey: 'account_id',
                    quickFilterValue: account_id,
                    hideQuickFilter: true,
                }}
                route={{
                    params: {
                        quickFilterKey: 'account_id',
                        quickFilterValue: account_id,
                        hideQuickFilter: true,
                        name: "Ledger",
                    }
                }}
            />
        </View>
    });

    const ExtraGSTNumbers = ({ field, useForm }) => {
        const gst_numbers = useForm((state) => state.formObject['gst_numbers']);

        const setFormObject = useForm((state) => state.setFormObject);
        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
        const getFormObject = useForm((state) => state.getFormObject);

        return <>

            <View flex>
                {gst_numbers && gst_numbers.map((obj, index) => {
                    return <Row key={index} style={{ marginTop: 10, alignItems: 'center' }} >
                        <Col width="100%">
                            <Text tabHeading>GST Number {index + 1}</Text>
                        </Col>

                        <Col width="50%">
                            <CustomTextField label="GST Numbers" value={obj.gst_number} onChange={(value) => {
                                setFormObjectArray('gst_numbers', index, 'gst_number', value);
                            }} />
                        </Col>

                        <Col width="50%">
                            <CustomSelect label="Primary" value={obj.primary} options={[
                                { label: 'Yes', value: "Yes" },
                                { label: 'No', value: "No" },
                            ]} onChange={(value) => {
                                setFormObjectArray('gst_numbers', index, 'primary', value);
                                const newArray = [...getFormObject().gst_numbers];
                                let otherDefault = false;
                                newArray.forEach((data, i) => {
                                    if (i != index && value == "Yes") {
                                        data.primary = "No";
                                    }
                                    if (i != index && value == "No") {
                                        if (!otherDefault) {
                                            data.primary = "Yes";
                                            otherDefault = true;
                                        }
                                    }
                                });
                                setFormObject({ ...getFormObject(), gst_numbers: newArray });
                            }} />
                        </Col>

                        <View style={{ marginTop: 18 }}>
                            <Button outline outlineColor={'red'} label="Delete" onPress={() => {
                                const newObject = { ...getFormObject() };
                                const newGstNumbers = [...newObject.gst_numbers];
                                newGstNumbers.splice(index, 1);
                                newObject.gst_numbers = newGstNumbers;
                                setFormObject(newObject);
                            }} />
                        </View>
                    </Row>
                })}
            </View>
        </>
    }

    let formFields = [
        {
            type: 'divider',
            heading: 'Contact Details',
            visible: true,
            span: 24,
            tab: 'General',
        },
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the Contact Name',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Company Name',
            key: 'company_name',
            type: 'text',
            placeholder: 'Enter the Company Name',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        // {
        //     label: 'Website',
        //     key: 'website',
        //     type: 'text',
        //     placeholder: 'Enter the Website',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General'
        // },
        // {
        //     label: 'Status',
        //     key: 'status',
        //     type: 'select',
        //     placeholder: 'Select the Status',
        //     visible: true,
        //     width: '25%',
        //     options: [
        //         { label: 'Active', value: 'active' },
        //         { label: 'Inactive', value: 'inactive' },
        //     ],
        //     tab: 'General'
        // },
        {
            label: 'Email',
            key: 'email',
            type: 'text',
            placeholder: 'Enter the Email',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Phone',
            key: 'phone',
            type: 'text',
            placeholder: 'Enter the Phone Number',
            visible: true,
            width: '25%',
            tab: 'General',
            showCountryCode: true
        },
        {
            label: 'Contact Category',
            key: 'contact_category_id',
            type: 'select',
            placeholder: 'Select the Contact Category',
            visible: true,
            width: '25%',
            options: response.ContactCategories || [],
            tab: 'General',
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };

                OpenModalCustom({ newModule: "Categories", field_key: 'contact_category_id', params })
            },
        },
        {
            label: 'Balance',
            key: 'balance',
            type: 'number',
            placeholder: 'Enter the Balance',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Opening Balance',
            key: 'opening_balance',
            type: 'number',
            placeholder: 'Enter the Opening Balance',
            visible: true,
            width: '25%',
            tab: 'General',
            value: 0
        },
        {
            label: 'Do you sell to this contact?',
            key: 'is_customer',
            type: 'checkbox',
            placeholder: 'Enter the Opening Balance',
            visible: true,
            width: '100%',
            tab: 'General',
            value: true,
        },
        {
            label: 'Do you purchase from this contact?',
            key: 'is_supplier',
            type: 'checkbox',
            placeholder: 'Enter the Opening Balance',
            visible: true,
            width: '100%',
            tab: 'General',
            value: true,
        },
        {
            type: 'divider',
            heading: 'GST Details',
            visible: true,
            span: 24,
            tab: 'General'
        },
        {
            label: 'GST Treatment',
            key: 'gst_treatment',
            type: 'select',
            placeholder: 'Enter the GST Treatment',
            visible: true,
            width: '25%',
            options: [
                { label: 'Registered Business - Regular', value: 'Registered Business - Regular' },
                { label: 'Registered Business - Composition', value: 'Registered Business - Composition' },
                { label: 'Unregistered Business', value: 'Unregistered Business' },
                { label: 'Consumer', value: 'Consumer' },
            ],
            tab: 'General',
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                setObj({
                    updateVisiblity: Math.random()
                });
            },
            value: 'Consumer'
        },
        {
            label: 'PAN Number',
            key: 'pan_number',
            type: 'text',
            placeholder: 'Enter the PAN Number',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {

        },
        {
            type: 'custom',
            key: 'gst_numbers',
            component: ExtraGSTNumbers,
            visible: (connectedObject) => {
                return (connectedObject.gst_treatment == 'Registered Business - Regular' || connectedObject.gst_treatment == 'Registered Business - Composition');
            },
            span: 24,
            value: [{
                gst_number: '',
                primary: 'Yes'
            }],
            tab: 'General'
        },
        addButton('Add GST Number', 'add_gst_number', 'gst_numbers', {
            gst_number: '',
            primary: 'No'
        }, "General", (connectedObject) => {
            return (connectedObject.gst_treatment == 'Registered Business - Regular' || connectedObject.gst_treatment == 'Registered Business - Composition');
        }),
        {
            type: 'divider',
            heading: 'Addresses',
            visible: true,
            span: 24,
            tab: 'Address Details'
        }, {
            type: 'custom',
            key: 'addresses',
            component: AddressesComponent,
            visible: true,
            span: 24,
            value: [{
                primary_billing: 'Yes',
                primary_shipping: 'Yes',
                country: 'India',
                state: 'Madhya Pradesh'
            }],
            tab: 'Address Details'
        },
        addButton('Add Address', 'add_address', 'addresses', {
            primary_billing: 'No',
            primary_shipping: 'No',
            country: 'India',
            state: 'Madhya Pradesh'
        }, "Address Details"),
        {
            type: 'divider',
            heading: 'Eye Details',
            visible: true,
            span: 24,
            tab: 'Eye Details'
        }, {
            type: 'custom',
            key: 'lens_details',
            component: LensDetailsComponent,
            visible: true,
            span: 24,
            value: [],
            tab: 'Eye Details'
        },
        {
            ...addButton('Add Eye Details', 'add_lens_details', 'lens_details', {
                date: "GENERATED_DATE",
            }, "Eye Details")
        },
        {
            label: 'Auto create ledger account with the same name as the contact?',
            key: 'auto_create_ledger_account',
            type: 'checkbox',
            placeholder: 'Enter the Opening Balance',
            visible: isEdit ? false : view ? false : true,
            width: '100%',
            tab: 'Ledger Account',
            value: isEdit ? false : true,
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                setObj({
                    updateVisiblity: Math.random()
                })
            }
        },
        {
            label: 'Ledger Account',
            key: 'account_id',
            type: 'select',
            placeholder: 'Enter the Ledger Account',
            visible: (connectedObject) => {
                if (isEdit) {
                    return true;
                }
                return !connectedObject.auto_create_ledger_account;
            },
            width: '25%',
            tab: 'Ledger Account',
            options: response.LedgerAccounts || []
        },
        {
            label: '',
            type: 'custom',
            placeholder: 'Enter the Ledger Account',
            visible: (connectedObject) => {
                if (view) {
                    return true;
                } else {
                    return false;
                }
            },
            width: '100%',
            tab: 'Ledger Account',
            component: LedgerAccountComponent
        },
    ];

    if (globalData.organization_id != 15) {
        formFields = formFields.filter((f) => f.tab != 'Eye Details');
    }

    const CustomFields = response.CustomFields;

    addCustomFields(CustomFields, formFields);

    return formFields;
}

export const contactMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const contactMain = {
    fields: contactfields,
    mobileCard: null
}

export function addCustomFields(CustomFields, formFields) {
    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {

            const obj = {
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                tab: field.tab,
                onChange: null
            };

            const formFieldOne = formFields[0];

            if (!formFieldOne.tab) {
                delete obj.tab;
            }

            if (field.onChangeFunction) {
                obj.onChange = ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                    try {
                        const fn = api.decryptText(field.onChangeFunction)

                        eval(fn);
                    } catch (e) {

                    }
                }
            }

            if (field.after) {
                const index = formFields.findIndex((f) => f.key === field.after);
                formFields.splice(index + 1, 0, obj);
            } else if (field.before) {
                const index = formFields.findIndex((f) => f.key === field.before);
                formFields.splice(index, 0, obj);
            } else {
                formFields.push(obj)
            }

        });
    }
}
