import * as FaceDetector from "expo-face-detector"
import React, { useEffect, useReducer, useRef, useState } from "react"
import { StyleSheet, Dimensions, PixelRatio, Alert, TouchableWithoutFeedback, Platform, ActivityIndicator } from "react-native"
import { Camera, FaceDetectionResult } from "expo-camera"
import { AnimatedCircularProgress } from "react-native-circular-progress"
import { useNavigation } from "@react-navigation/native"
import Svg, { Path, SvgProps } from "react-native-svg"
import { Text, TouchableOpacity, View } from "react-native-ui-lib"
import mime from 'mime';
import * as FileSystem from 'expo-file-system';
import axios from "axios"
import * as MediaLibrary from 'expo-media-library';

const { width: windowWidth } = Dimensions.get("window")
const PREVIEW_MARGIN_TOP = 100
const PREVIEW_SIZE = 300

const detections = {
  BLINK: { promptText: "", minProbability: 0.4 },
  SMILE: { promptText: "", minProbability: 0.7 }
}

const promptsText = {
  noFaceDetected: "No face detected",
  performActions: "Authenticating..."
}

const detectionsList = [
  "BLINK",
  "SMILE"
]

const initialState = {
  faceDetected: false,
  promptText: promptsText.noFaceDetected,
  detectionsList,
  currentDetectionIndex: 0,
  progressFill: 0,
  processComplete: false
}

export default function FaceAuth(props) {
  const { employee, onAuthenticated, face_auth_image_url, enable_face_auth } = props;

  const navigation = useNavigation()
  const [hasPermission, setHasPermission] = useState(false)
  const [state, dispatch] = useReducer(detectionReducer, initialState)
  const rollAngles = useRef([])
  const rect = useRef(null)
  const cameraRef = useRef(null);

  // // Screen Ratio and image padding
  const [imagePadding, setImagePadding] = useState(0);
  const [ratio, setRatio] = useState('4:3');
  const { height, width } = Dimensions.get('window');
  const screenRatio = height / width;
  const [isRatioSet, setIsRatioSet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageTaken, setImageTaken] = useState(false);
  const [maxAutoFaceAuthTimeOutDone, setMaxAutoFaceAuthTimeOutDone] = useState(false);

  useEffect(() => {
    const requestPermissions = async () => {
      const cameraStatus = await Camera.requestCameraPermissionsAsync();
      const storageStatus = await MediaLibrary.requestPermissionsAsync();
      if (cameraStatus.status === 'granted' && storageStatus.status === 'granted') {
        setHasPermission(true);
      } else {
        Alert.alert("Please allow camera and storage permissions to use this feature")
      }
    }

    requestPermissions()

    const timer = setTimeout(() => {
      setMaxAutoFaceAuthTimeOutDone(true);
    }, 800);

    return () => clearTimeout(timer);
  }, [])

  const drawFaceRect = (face) => {
    // rect.current?.setNativeProps({
    //   width: face.bounds.size.width,
    //   height: face.bounds.size.height,
    //   top: face.bounds.origin.y,
    //   left: face.bounds.origin.x
    // })
  }

  const onFacesDetected = async (result) => {
    if (!isRatioSet) {
      return;
    }
    if (imageTaken) {
      return;
    }

    if (result.faces.length !== 1) {
      dispatch({ type: "FACE_DETECTED", value: "no" })
      return
    }

    const face = result.faces[0]

    // offset used to get the center of the face, instead of top left corner
    const midFaceOffsetY = face.bounds.size.height / 2
    const midFaceOffsetX = face.bounds.size.width / 2

    drawFaceRect(face)
    // make sure face is centered
    const faceMidYPoint = face.bounds.origin.y + midFaceOffsetY
    if (
      // if middle of face is outside the preview towards the top
      faceMidYPoint <= PREVIEW_MARGIN_TOP ||
      // if middle of face is outside the preview towards the bottom
      faceMidYPoint >= PREVIEW_SIZE + PREVIEW_MARGIN_TOP
    ) {
      dispatch({ type: "FACE_DETECTED", value: "no" })
      return
    }

    const faceMidXPoint = face.bounds.origin.x + midFaceOffsetX
    if (
      // if face is outside the preview towards the left
      faceMidXPoint <= windowWidth / 2 - PREVIEW_SIZE / 2 ||
      // if face is outside the preview towards the right
      faceMidXPoint >= windowWidth / 2 + PREVIEW_SIZE / 2
    ) {
      dispatch({ type: "FACE_DETECTED", value: "no" })
      return
    }

    // drawFaceRect(face)

    if (!state.faceDetected) {
      dispatch({ type: "FACE_DETECTED", value: "yes" })
    }

    console.log("Face Auth Data 1", original_face_image, enable_face_auth);

    let photo = null;

    try {
      setImageTaken(true);
      setLoading(true);
      console.log("Taking Picture");
      photo = await cameraRef.current.takePictureAsync({
        quality: 0.3,
        base64: true
      });

      console.log("Picture Taken");
    } catch (error) {
      console.log("error", error);
    }

    console.log("Face Auth Data 2", original_face_image, enable_face_auth);

    let base64File = null;
    let original_base_64_file = null;

    const uri = photo.uri || photo;
    base64File = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
    original_base_64_file = base64File;
    const mimeType = mime.getType(uri);
    base64File = 'data:' + mimeType + ';base64,' + base64File;

    const original_face_image = face_auth_image_url;

    const extension = base64File.split(';')[0].split('/')[1];

    const data = {
      file: base64File,
      extension: extension,
    }

    console.log("Face Auth Data", original_face_image, enable_face_auth);

    await authenticateFace(original_face_image, enable_face_auth, original_base_64_file, onAuthenticated, data);

    const detectionAction = state.detectionsList[state.currentDetectionIndex]

    // switch (detectionAction) {
    //   case "BLINK":
    //     // lower probabiltiy is when eyes are closed
    //     const leftEyeClosed =
    //       face.leftEyeOpenProbability <= detections.BLINK.minProbability
    //     const rightEyeClosed =
    //       face.rightEyeOpenProbability <= detections.BLINK.minProbability
    //     if (leftEyeClosed && rightEyeClosed) {
    //       dispatch({ type: "NEXT_DETECTION", value: null })
    //     }
    //     return
    //   case "SMILE":
    //     if (face.smilingProbability >= detections.SMILE.minProbability) {

    //       dispatch({ type: "NEXT_DETECTION", value: null })
    //     }
    //     return
    // }
  }

  if (hasPermission === false) {
    return <Text>No access to camera</Text>
  }

  const prepareRatio = async () => {
    let desiredRatio = '4:3';
    if (Platform.OS === 'android') {
      const ratios = await cameraRef.current.getSupportedRatiosAsync();
      let distances = {};
      let realRatios = {};
      let minDistance = null;
      for (const ratio of ratios) {
        const parts = ratio.split(':');
        const realRatio = parseInt(parts[0]) / parseInt(parts[1]);
        realRatios[ratio] = realRatio;
        const distance = screenRatio - realRatio;
        distances[ratio] = realRatio;
        if (minDistance == null) {
          minDistance = ratio;
        } else {
          if (distance >= 0 && distance < distances[minDistance]) {
            minDistance = ratio;
          }
        }
      }
      desiredRatio = minDistance;
      const remainder = Math.floor((height - realRatios[desiredRatio] * width) / 2);
      setImagePadding(remainder);
      setRatio(desiredRatio);
      setIsRatioSet(true);
    } else {
      setIsRatioSet(true);
    }
  };

  const setCameraReady = async () => {
    if (!isRatioSet) {
      await prepareRatio();
    }
  };

  const authenticateFace = async (original_face_image, enable_face_auth, original_base_64_file, onAuthenticated, data) => {
    if (original_face_image && enable_face_auth) {
      const fetchResponse = await fetch(original_face_image)
      const blob = await fetchResponse.blob()
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = async () => {
        const original_face_image_in_base64 = reader.result
        const url = 'https://faceapi.regulaforensics.com/api/match'

        const base64FileWithoutHeader = original_face_image_in_base64.substring(original_face_image_in_base64.indexOf(',') + 1)

        const faceAuthData = {
          images: [
            {
              data: original_base_64_file,
              index: 0,
              detectAll: true,
              type: 3
            },
            {
              data: base64FileWithoutHeader,
              index: 1,
              detectAll: true,
              type: 3
            }
          ],
          thumbnails: false
        }

        const response = await axios.post(url, faceAuthData, {
          headers: {
            Accept: '*/*',
            'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
            'Content-Type': 'application/json'
          },
        })

        setLoading(false);

        if (response.data.results && response.data.results[0]?.similarity > 0.7) {
          onAuthenticated(data)
        } else {
          Alert.alert("Face not matched")
        }
      }
    } else {
      onAuthenticated(data)
    }
  }

  return (
    <View style={{
      flex: 1,
      backgroundColor: "#fff",
      position: "relative"
    }}>
      {loading && <View style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: Dimensions.get('window').height,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        zIndex: 9999999999,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <ActivityIndicator size="large" color="#29459C" />
      </View>}
      <View
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: PREVIEW_MARGIN_TOP,
          backgroundColor: "white",
          zIndex: 9999999
        }}
      />
      <View
        style={{
          position: "absolute",
          top: PREVIEW_MARGIN_TOP,
          left: 0,
          width: ((windowWidth - PREVIEW_SIZE) - 20) / 2,
          height: PREVIEW_SIZE + 200,
          backgroundColor: "white",
          zIndex: 10
        }}
      />
      <View
        center
        flex
        style={{
          position: "absolute",
          top: PREVIEW_MARGIN_TOP,
          right: 0,
          width: (windowWidth - PREVIEW_SIZE - 20) / 2 + 1,
          height: PREVIEW_SIZE + 200,
          zIndex: 10
        }}
      />
      <Camera
        ref={cameraRef}
        style={[{ marginBottom: imagePadding }, { width: 300, height: '100%', alignSelf: "center" }]}
        onCameraReady={setCameraReady}
        ratio={ratio}
        type={Camera.Constants.Type.front}
        onFacesDetected={onFacesDetected}
        faceDetectorSettings={{
          mode: FaceDetector.FaceDetectorMode.fast,
          detectLandmarks: FaceDetector.FaceDetectorLandmarks.none,
          runClassifications: FaceDetector.FaceDetectorClassifications.all,
          minDetectionInterval: 0,
          tracking: false
        }}
      >
        <CameraPreviewMask width={'100%'} style={{
          position: "absolute",
          width: 300,
          height: 462,
          top: 100,
          alignSelf: "center"
        }} />
        {/* <AnimatedCircularProgress
          style={styles.circularProgress}
          size={PREVIEW_SIZE}
          width={5}
          backgroundWidth={7}
          fill={state.progressFill}
          tintColor="#3485FF"
          backgroundColor="#e8e8e8"
        /> */}

      </Camera>
      <View
        ref={rect}
        style={{
          position: "absolute",
          borderWidth: 0,
          borderColor: "pink",
          zIndex: 10
        }}
      />
      <View style={styles.promptContainer}>
        <Text style={styles.faceStatus}>
          {!state.faceDetected && promptsText.noFaceDetected}
        </Text>

        {(maxAutoFaceAuthTimeOutDone) && <TouchableOpacity
          center
          onPress={async () => {
            setLoading(true);
            const photo = await cameraRef.current.takePictureAsync({
              quality: 0.3,
              onPictureSaved: async (photo) => {
                let base64File = null;
                let original_base_64_file = null;

                if (Platform.OS != 'web') {
                  const uri = photo.uri || photo;
                  base64File = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
                  original_base_64_file = base64File;
                  const mimeType = mime.getType(uri);
                  base64File = 'data:' + mimeType + ';base64,' + base64File;
                } else {
                  base64File = photo.uri;
                }

                const extension = base64File.split(';')[0].split('/')[1];

                const data = {
                  file: base64File,
                  extension: extension,
                }

                const original_face_image = face_auth_image_url;
                
                await authenticateFace(original_face_image, enable_face_auth, original_base_64_file, onAuthenticated, data);
              }
            });
          }}>
          <Text style={{
            textAlign: 'center',
          }}>
            Take Selfie and Authenticate
          </Text>
        </TouchableOpacity>}

        <Text style={styles.actionPrompt}>
          {state.faceDetected && promptsText.performActions}
        </Text>
        <Text style={styles.action}>
          {state.faceDetected &&
            detections[state.detectionsList[state.currentDetectionIndex]]
              .promptText}
        </Text>
      </View>
    </View>
  )
}


const detectionReducer = (state, action) => {
  const numDetections = state.detectionsList.length
  const newProgressFill = (100 / (numDetections + 1)) * (state.currentDetectionIndex + 1);

  switch (action.type) {
    case "FACE_DETECTED":
      if (action.value === "yes") {
        return { ...state, faceDetected: true, processComplete: true, progressFill: 100 }
      } else {
        // Reset
        return initialState
      }
    case "NEXT_DETECTION":
      const nextIndex = state.currentDetectionIndex + 1
      if (nextIndex === numDetections) {
        // success
        return { ...state, processComplete: true, progressFill: 100 }
      }
      // next
      return {
        ...state,
        currentDetectionIndex: nextIndex,
        progressFill: newProgressFill
      }
    default:
      throw new Error("Unexpeceted action type.")
  }
}

{/* <svg width="300" height="462" viewBox="0 0 300 462" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3901_1329)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M150 0H0V462H300V0H150ZM150 0C232.843 0 300 103.422 300 231C300 358.578 232.843 462 150 462C67.157 462 0 358.578 0 231C0 103.422 67.157 0 150 0Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_3901_1329">
<rect width="300" height="462" fill="white"/>
</clipPath>
</defs>
</svg> */}


const CameraPreviewMask = (props) => (
  <Svg width={400} viewBox="0 0 300 462" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150 0H0V462H300V0H150ZM150 0C232.843 0 300 103.422 300 231C300 358.578 232.843 462 150 462C67.157 462 0 358.578 0 231C0 103.422 67.157 0 150 0Z"
      fill="#fff"
    />
  </Svg>
)





const styles = StyleSheet.create({
  actionPrompt: {
    fontSize: 20,
    textAlign: "center"
  },
  container: {
    flex: 1,
    backgroundColor: "#fff"
  },
  promptContainer: {
    position: "absolute",
    alignSelf: "center",
    top: PREVIEW_MARGIN_TOP + PREVIEW_SIZE + 162,
    height: "100%",
    width: "100%",
    backgroundColor: "white"
  },
  faceStatus: {
    fontSize: 24,
    textAlign: "center",
    marginTop: 10
  },
  cameraPreview: {
    flex: 1
  },
  circularProgress: {
    position: "absolute",
    width: PREVIEW_SIZE,
    height: PREVIEW_SIZE + 250,
    top: PREVIEW_MARGIN_TOP,
    alignSelf: "center"
  },
  action: {
    fontSize: 24,
    textAlign: "center",
    marginTop: 10,
    fontWeight: "bold"
  }
})


