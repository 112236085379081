import moment from "moment";
import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { addButton } from "../../hrms/shiftModel";

let goalTargetMap = {
  500: [100, 400, 800, 1300, 1800, 2400, 3000, 3600, 4200, 4800, 5400, 6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500, 10000, 10500, 11000, 11500, 12000],
  750: [150, 600, 1200, 1900, 2700, 3600, 4500, 5400, 6300, 7200, 8100, 9000, 9750, 10500, 11250, 12000, 12750, 13500, 14250, 15000, 15750, 16500, 17250, 18000],
  1000: [200, 800, 1600, 2600, 3600, 4800, 6000, 7200, 8400, 9600, 10800, 12000, 13000, 14000, 15000, 16000, 17000, 18000, 19000, 20000, 21000, 22000, 23000, 24000],
  1500: [300, 1200, 2400, 3900, 5400, 7200, 9000, 10800, 12600, 14400, 16200, 18000, 19500, 21000, 22500, 24000, 25500, 27000, 28500, 30000, 31500, 33000, 34500, 36000],
  2000: [0, 0, 3200, 5100, 7200, 9600, 12000, 14400, 16800, 19200, 21600, 24000, 26000, 28000, 30000, 32000, 34000, 36000, 38000, 40000, 42000, 44000, 46000, 48000],
  2500: [0, 0, 4000, 6400, 9000, 12000, 15000, 18000, 21000, 24000, 27000, 30000, 32500, 35000, 37500, 40000, 42500, 45000, 47500, 50000, 52500, 55000, 57500, 60000],
  3000: [0, 0, 4800, 7700, 10800, 14400, 18000, 21600, 25200, 28800, 32400, 36000, 39000, 42000, 45000, 48000, 51000, 54000, 57000, 60000, 63000, 66000, 69000, 72000],
  3500: [0, 0, 5600, 9000, 12600, 16800, 21000, 25200, 29400, 33600, 37800, 42000, 45500, 49000, 52500, 56000, 59500, 63000, 66500, 70000, 73500, 77000, 80500, 84000],
  4000: [0, 0, 6400, 10100, 14400, 19200, 24000, 28800, 33600, 38400, 43200, 48000, 52000, 56000, 60000, 64000, 68000, 72000, 76000, 80000, 84000, 88000, 92000, 96000],
  5000: [0, 0, 8000, 13000, 18000, 24000, 30000, 36000, 42000, 48000, 54000, 60000, 65000, 70000, 75000, 80000, 85000, 90000, 95000, 100000, 105000, 110000, 115000, 120000],
  6000: [1200, 4800, 9600, 15400, 21600, 28800, 36000, 43200, 50400, 57600, 64800, 72000, 78000, 84000, 90000, 96000, 102000, 108000, 114000, 120000, 126000, 132000, 138000, 144000],
  7000: [1400, 5600, 11200, 18000, 25200, 33600, 42000, 50400, 58800, 67200, 75600, 84000, 91000, 98000, 105000, 112000, 119000, 126000, 133000, 140000, 147000, 154000, 161000, 168000],
  8000: [1600, 6400, 12800, 20200, 28800, 38400, 48000, 57600, 67200, 76800, 86400, 96000, 104000, 112000, 120000, 128000, 136000, 144000, 152000, 160000, 168000, 176000, 184000, 192000],
  10000: [2000, 8000, 16000, 25500, 36000, 48000, 60000, 72000, 84000, 96000, 108000, 120000, 130000, 140000, 150000, 160000, 170000, 180000, 190000, 200000, 210000, 220000, 230000, 240000],
  12000: [2400, 9600, 19200, 30600, 43200, 57600, 72000, 86400, 100800, 115200, 129600, 144000, 156000, 168000, 180000, 192000, 204000, 216000, 228000, 240000, 252000, 264000, 276000, 288000],
}

export const goalfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
  const formFields = [
    {
      label: 'Agent',
      key: 'employee_id',
      type: 'select',
      placeholder: 'Select Agent',
      visible: false,
      width: '100%',
      required: true,
      options: [],
    },
    {
      label: 'Name',
      key: 'name',
      type: 'text',
      placeholder: 'Enter the goal Name',
      visible: view ? false : true,
      width: '100%',
      tab: 'Goal Details'
    },
    {
      label: 'Description',
      key: 'description',
      type: 'editor',
      placeholder: 'Enter the goal Description',
      visible: true,
      width: '100%',
      tab: 'Description'
    },
    {
      label: 'Attachments',
      key: 'attachments',
      type: 'upload',
      placeholder: 'Enter the goal Attachments',
      visible: true,
      width: '100%',
      tab: 'Attachments',
      mimeType: 'image/*',
      moduleName: moduleName,
    },
    {
      label: 'Is Eps Scheme Goal',
      key: 'is_eps_scheme_goal',
      type: 'checkbox',
      placeholder: 'Enter the goal Is Eps Scheme Goal',
      visible: view ? false : true,
      width: '100%',
      tab: 'Goal Details',
      onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        let final_goal_targets = getTargets({
          start_date: getObj().start_date,
          end_date: getObj().end_date,
          is_eps_scheme_goal: value,
          eps_scheme: getObj().eps_scheme,
          capture_type: getObj().capture_type,
          per_target_amount: getObj().per_target_amount
        });

        setObj({
          updateVisiblity: Math.random(),
          goal_targets: final_goal_targets
        })
      }
    },
    {
      label: 'Eps Scheme',
      key: 'eps_scheme',
      type: 'select',
      placeholder: 'Enter the goal Eps Scheme',
      visible: (connectedObj) => connectedObj.is_eps_scheme_goal ? view ? false : true : false,
      width: '100%',
      tab: 'Goal Details',
      options: [
        { label: '500', value: 500 },
        { label: '750', value: 750 },
        { label: '1000', value: 1000 },
        { label: '1500', value: 1500 },
        { label: '2000', value: 2000 },
        { label: '2500', value: 2500 },
        { label: '3000', value: 3000 },
        { label: '3500', value: 3500 },
        { label: '4000', value: 4000 },
        { label: '5000', value: 5000 },
        { label: '6000', value: 6000 },
        { label: '7000', value: 7000 },
        { label: '8000', value: 8000 },
        { label: '10000', value: 10000 },
        { label: '12000', value: 12000 },
      ],
      onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        let final_goal_targets = getTargets({
          start_date: getObj().start_date,
          end_date: getObj().end_date,
          is_eps_scheme_goal: getObj().is_eps_scheme_goal,
          eps_scheme: value,
          capture_type: getObj().capture_type,
          per_target_amount: getObj().per_target_amount
        });

        setObj({
          goal_targets: final_goal_targets
        });
      }
    },
    {
      label: 'Start Date',
      key: 'start_date',
      type: 'date',
      placeholder: 'Enter the goal Start Date',
      visible: view ? false : true,
      width: '50%',
      tab: 'Goal Details',
      onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        if (getObj().is_eps_scheme_goal == true) {
          const date = moment(value).add(2, 'year').toDate();

          let final_goal_targets = getTargets({
            start_date: value,
            end_date: date,
            is_eps_scheme_goal: getObj().is_eps_scheme_goal,
            eps_scheme: getObj().eps_scheme,
            capture_type: getObj().capture_type,
            per_target_amount: getObj().per_target_amount
          });

          setObj({
            end_date: date,
            goal_targets: final_goal_targets
          });
        } else {
          const date = moment(value).endOf('year');

          let final_goal_targets = getTargets({
            start_date: value,
            end_date: date,
            is_eps_scheme_goal: getObj().is_eps_scheme_goal,
            eps_scheme: null,
            capture_type: getObj().capture_type,
            per_target_amount: getObj().per_target_amount
          });

          setObj({
            end_date: date.toDate(),
            goal_targets: final_goal_targets
          });
        }
      }
    },
    {
      label: 'End Date',
      key: 'end_date',
      type: 'date',
      placeholder: 'Enter the goal End Date',
      visible: view ? false : true,
      width: '50%',
      tab: 'Goal Details',
      onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        let final_goal_targets = getTargets({
          start_date: getObj().start_date,
          end_date: value,
          is_eps_scheme_goal: getObj().is_eps_scheme_goal,
          eps_scheme: getObj().eps_scheme,
          capture_type: getObj().capture_type,
          per_target_amount: getObj().per_target_amount
        });

        setObj({
          goal_targets: final_goal_targets
        });
      }
    },
    {
      label: 'Capture Type',
      key: 'capture_type',
      type: 'select',
      placeholder: 'Enter the goal Capture Type',
      visible: (connectedObj) => connectedObj.is_eps_scheme_goal ? false : view ? false : true,
      width: '100%',
      tab: 'Goal Details',
      options: [
        { label: 'Weekly', value: 'Weekly' },
        { label: 'Monthly', value: 'Monthly' },
      ],
      onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        let final_goal_targets = getTargets({
          start_date: getObj().start_date,
          end_date: getObj().end_date,
          is_eps_scheme_goal: getObj().is_eps_scheme_goal,
          eps_scheme: getObj().eps_scheme,
          capture_type: value,
          per_target_amount: getObj().per_target_amount
        });

        setObj({
          goal_targets: final_goal_targets
        });
      }
    },
    {
      label: 'Per Target Amount',
      key: 'per_target_amount',
      type: 'number',
      placeholder: 'Enter the goal Per Target Amount',
      visible: (connectedObj) => connectedObj.is_eps_scheme_goal ? false : view ? false : true,
      width: '100%',
      tab: 'Goal Details',
      onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        let final_goal_targets = getTargets({
          start_date: getObj().start_date,
          end_date: getObj().end_date,
          is_eps_scheme_goal: getObj().is_eps_scheme_goal,
          eps_scheme: getObj().eps_scheme,
          capture_type: getObj().capture_type,
          per_target_amount: value
        });

        setObj({
          goal_targets: final_goal_targets
        });
      }
    },
    {
      label: 'Goals',
      key: 'goal_targets',
      type: 'table',
      placeholder: 'Select the goal Monthly Targets',
      // visible: (connectedObject) => connectedObject.is_eps_scheme_goal ? true : false,
      visible: true,
      width: '100%',
      tab: 'Goal Details',
      editable: true,
      columns: [{
        "field": "start_date",
        "label": "Start Date",
        "editable": false,
        "type": "date",
        "visible": true,
        "order": 1,
        "Header": "Start Date",
        "accessor": "start_date",
        "placeholder": "Enter the start date",
      }, {
        "field": "end_date",
        "label": "End Date",
        "editable": false,
        "type": "date",
        "visible": true,
        "order": 2,
        "Header": "End Date",
        "accessor": "end_date",
        "placeholder": "Enter the end date",
      }, {
        "field": "target",
        "label": "Target",
        "editable": true,
        "type": "number",
        "visible": true,
        "order": 3,
        "Header": "Target",
        "accessor": "target",
        "placeholder": "Enter the target",
        "value": 0,
      }, {
        "field": "total_das_sales",
        "label": "DAS Sales",
        "editable": true,
        "type": "number",
        "visible": view ? true : false,
        "order": 4,
        "Header": "DAS Sales",
        "accessor": "total_das_sales",
        "placeholder": "Enter the das sales",
        "value": 0,
      }, {
        "field": "total_sales",
        "label": "Actual Sales",
        "editable": true,
        "type": "number",
        "visible": view ? true : false,
        "order": 4,
        "Header": "Actual Sales",
        "accessor": "total_sales",
        "placeholder": "Enter the actual sales",
        "value": 0,
      },
      {
        "field": "short_fall",
        "label": "Short Fall",
        "editable": true,
        "type": "number",
        "visible": view ? true : false,
        "order": 5,
        "Header": "Short Fall",
        "accessor": "short_fall",
        "placeholder": "Enter the short fall",
        "value": 0,
      },
      {
        "field": "progress",
        "label": "Progress",
        "editable": true,
        // "type": "progress",
        "type": "progress",
        "visible": view ? true : false,
        "order": 7,
        "Header": "Progress",
        "accessor": "progress",
        "placeholder": "Enter the progress",
        "value": 0,
      }],
      // actions: true,
      // actionFunctions: (row) => ([{
      //   label: 'Delete',
      //   key: 'delete',
      //   fn: ({ data, updateData, row, row_index }) => {
      //     let newData = [...data];
      //     newData.splice(row_index, 1);
      //     updateData(newData);
      //   }
      // }]),
      value: [],
    },
    // addButton("Add Package Item", "add_mapped_item", "mapped_items", {}, "Goal Details", (connectedObject) => connectedObject.is_eps_scheme_goal == true),
    // {
    //   label: 'Total Target Amount',
    //   key: 'total_target_amount',
    //   type: 'number',
    //   placeholder: 'Enter the goal Total Target Amount',
    //   visible: true,
    //   width: '100%',
    //   tab: 'Goal Details'
    // },
  ];

  if (view) {
    let goal_targets = formFields.find((field) => field.key == 'goal_targets');
    formFields.splice(formFields.indexOf(goal_targets), 1);
    formFields.unshift(goal_targets);
  }

  return formFields;
}

export const goalMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const goalMain = {
  fields: goalfields,
  mobileCard: null
}

function getTargets({ start_date, end_date, is_eps_scheme_goal, eps_scheme, capture_type, per_target_amount }) {
  console.log("start_date, end_date, is_eps_scheme_goal, eps_scheme, capture_type, per_target_amount", start_date, end_date, is_eps_scheme_goal, eps_scheme, capture_type, per_target_amount);


  if (is_eps_scheme_goal && start_date && end_date && eps_scheme) {
    let goal_targets = goalTargetMap[eps_scheme];

    let final_goal_targets = [];

    for (let i = 0; i < goal_targets.length; i++) {
      let start_date_new, end_date;

      if (i == 0) {
        start_date_new = moment(start_date);
      } else {
        start_date_new = moment(start_date).add(i, 'month');
      }

      if (i == 0) {
        end_date = moment(start_date).endOf('month');
      } else {
        end_date = moment(start_date).add(i, 'month').endOf('month');
      }

      final_goal_targets.push({
        start_date: start_date_new.toDate(),
        end_date: end_date.toDate(),
        target: goal_targets[i]
      });
    }
    return final_goal_targets;
  } else if (!is_eps_scheme_goal && start_date && end_date && capture_type && per_target_amount) {
    console.log("start_date, end_date, capture_type, per_target_amount", start_date, end_date, capture_type, per_target_amount);
    let final_goal_targets = [];


    if (capture_type == 'Weekly') {
      let start_date_new = moment(start_date);
      let end_date_new = moment(end_date);

      let cumulative_target = per_target_amount;

      while (start_date_new.isBefore(end_date_new)) {
        let end_date_new = moment(start_date_new).add(6, 'days');

        final_goal_targets.push({
          start_date: start_date_new.toDate(),
          end_date: end_date_new.toDate(),
          target: cumulative_target
        });

        start_date_new = moment(start_date_new).add(7, 'days');

        cumulative_target += per_target_amount;
      }
    } else if (capture_type == 'Monthly') {
      let start_date_new = moment(start_date);
      let end_date_new = moment(end_date);
      let cumulative_target = per_target_amount;

      while (start_date_new.isBefore(end_date_new)) {
        console.log("start_date_new", start_date_new);
        console.log("end_date", end_date_new);

        let end_date_new = moment(start_date_new).endOf('month');

        final_goal_targets.push({
          start_date: start_date_new.toDate(),
          end_date: end_date_new.toDate(),
          target: cumulative_target
        });

        start_date_new = moment(start_date_new).add(1, 'month').startOf('month');

        cumulative_target += per_target_amount;
      }
    }

    return final_goal_targets;
  } else {
    return [];
  }
}
