import React, { useEffect } from "react";
import { View, Card, Text, TouchableOpacity, Chip } from "react-native-ui-lib";
import { Col, CustomAutoComplete, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, CustomUpload, DangerButton, Label, PrimaryButton, Row, SecondaryButton } from "../../mycomponents/DynamicForm";
import { Video, ResizeMode } from 'expo-av';
import { Dimensions, Platform, ScrollView } from "react-native";
import Svg, { Rect, Path } from "react-native-svg";
import { CustomSelectItemForContacts } from "../accountancy/transaction";
import { api } from "../../services/api";
import moment from "moment";
import ListingScreen from "../../screens/MainScreens/CRUD/ListingScreen";
import { Select } from "antd";


export const appointmentfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

  const CourseContent = ({ field, useForm, tKey, tIndex }) => {

    return <View style={{
      width: '100%',
    }}>

    </View>
  }

  const InvoiceComponent = ({ field, useForm, tKey, tIndex }) => {
    const contact_id = useForm((state) => state.formObject['contact_id']);

    const props = {
      moduleName: 'Invoices',
      hideWrapper: true,
      extraFilters: { contact_id: contact_id ?? null },
    }

    const contact = response?.Contacts?.find((contact) => contact._id == contact_id);
    const title = contact ? "Invoices for " + contact.name : "Invoices";

    return <View w-100 flex>
      <ListingScreen {...props} title={title} default_values={{
        contact_id: contact_id,
        is_default_values: true
      }} />
    </View>
  };

  const VitalsComponent = ({ field, useForm, tKey, tIndex }) => {
    const height = useForm((state) => state.formObject['height']);
    const height_unit = useForm((state) => state.formObject['height_unit']) || 'cm';
    const setFormObject = useForm((state) => state.setFormObject);

    return <View style={{
      width: '100%'
    }}>
      <View row style={{
        flexWrap: 'wrap',
        width: '100%'
      }}>
        <View style={{
          width: '25%',
        }}>
          <CustomTextField
            label="Height"
            key="height"
            type="number"
            placeholder="Height"
            visible={true}
            width="100%"
            value={height}
            onChange={(value) => {
              setFormObject({ height: value });
            }}
            suffixComponent={(
              <CustomAutoComplete
                label="Unit"
                key="unit"
                type="autocomplete"
                placeholder="Unit"
                visible={true}
                width="100%"
                value={height_unit}
                onChange={(value) => {
                  setFormObject({ height_unit: value });
                }}
                options={[{ label: 'cm', value: 'cm' }, { label: 'm', value: 'm' }]}
              />
            )}
          />
        </View>
      </View>
    </View>
  }

  const symptomsColumns = [
    {
      "field": "name",
      "accessor": "name",
      "label": "Name",
      "Header": "Name",
      "editable": true,
      "type": "autocomplete",
      "visible": true,
      "order": 1,
      "options": [{ label: 'Symptom 1', value: 'Symptom 1' }, { label: 'Symptom 2', value: 'Symptom 2' }],
    },
    {
      "field": "since",
      "accessor": "since",
      "label": "Since",
      "Header": "Since",
      "editable": true,
      "type": "duration",
      "duration_options": ["weeks", "months", "years"],
      "visible": true,
      "order": 2,
    },
    {
      "field": "severity",
      "accessor": "severity",
      "label": "Severity",
      "Header": "Severity",
      "editable": true,
      "type": "select",
      "visible": true,
      "order": 3,
      "options": [
        { label: 'Mild', value: 'Mild' },
        { label: 'Moderate', value: 'Moderate' },
        { label: 'Severe', value: 'Severe' },
      ]
    },
    {
      "field": "details",
      "accessor": "details",
      "label": "Details",
      "Header": "Details",
      "editable": true,
      "type": "text",
      "visible": true,
      "order": 5,
    }
  ];

  const diagnosisColumns = [
    {
      "field": "name",
      "accessor": "name",
      "label": "Name",
      "Header": "Name",
      "editable": true,
      "type": "autocomplete",
      "visible": true,
      "order": 1,
      "options": [{ label: 'Symptom 1', value: 'Symptom 1' }, { label: 'Symptom 2', value: 'Symptom 2' }],
    },
    {
      "field": "since",
      "accessor": "since",
      "label": "Since",
      "Header": "Since",
      "editable": true,
      "type": "duration",
      "duration_options": ["weeks", "months", "years"],
      "visible": true,
      "order": 2,
    },
    {
      "field": "status",
      "accessor": "status",
      "label": "Status",
      "Header": "Status",
      "editable": true,
      "type": "select",
      "visible": true,
      "order": 3,
      "options": [
        { label: 'Ruled Out', value: 'Ruled Out' },
        { label: 'Suspected', value: 'Suspected' },
        { label: 'Confirmed', value: 'Confirmed' },
      ]
    },
    {
      "field": "details",
      "accessor": "details",
      "label": "Details",
      "Header": "Details",
      "editable": true,
      "type": "text",
      "visible": true,
      "order": 5,
    }
  ];

  const labInvestigationsColumns = [
    {
      "field": "name",
      "accessor": "name",
      "label": "Name",
      "Header": "Name",
      "editable": true,
      "type": "autocomplete",
      "visible": true,
      "order": 1,
      "options": [{ label: 'Symptom 1', value: 'Symptom 1' }, { label: 'Symptom 2', value: 'Symptom 2' }],
    },
    {
      "field": "test_on",
      "accessor": "test_on",
      "label": "Test On",
      "Header": "Test On",
      "editable": true,
      "type": "date",
      "visible": true,
      "order": 2,
    },
    {
      "field": "repeat_on",
      "accessor": "repeat_on",
      "label": "Repeat On",
      "Header": "Repeat On",
      "editable": true,
      "type": "date",
      "visible": true,
      "order": 3,
    },
    {
      "field": "details",
      "accessor": "details",
      "label": "Details",
      "Header": "Details",
      "editable": true,
      "type": "text",
      "visible": true,
      "order": 5,
    }
  ];

  const examinationColumns = [
    {
      "field": "name",
      "accessor": "name",
      "label": "Name",
      "Header": "Name",
      "editable": true,
      "type": "autocomplete",
      "visible": true,
      "order": 1,
      "options": [{ label: 'Symptom 1', value: 'Symptom 1' }, { label: 'Symptom 2', value: 'Symptom 2' }],
    },
    {
      "field": "details",
      "accessor": "details",
      "label": "Details",
      "Header": "Details",
      "editable": true,
      "type": "text",
      "visible": true,
      "order": 5,
    }
  ];

  const medicationsColumns = [
    {
      "field": "medicine",
      "accessor": "medicine",
      "label": "Medicine",
      "Header": "Medicine",
      "editable": true,
      "type": "autocomplete",
      "visible": true,
      "order": 1,
      "options": [{ label: 'Medicine 1', value: 'Medicine 1' }, { label: 'Medicine 2', value: 'Medicine 2' }],
    },
    {
      "field": "dose",
      "accessor": "dose",
      "label": "Dose",
      "Header": "Dose",
      "editable": true,
      "type": "autocomplete",
      "options": [{ label: '1 Tablet', value: '1 Tablet' }, { label: '1 Capsule', value: '1 Capsule' }],
      "suffixOptions": ["Tablet", "Capsule"],
      "visible": true,
      "order": 2,
    },
    {
      "field": "frequency",
      "accessor": "frequency",
      "label": "Frequency",
      "Header": "Frequency",
      "editable": true,
      "type": "autocomplete",
      "options": [
        { label: 'Once a day', value: 'Once a day' },
        { label: 'Once every 2 days', value: 'Once every 2 days' },
      ],
      "visible": true,
      "order": 3,
    },
    {
      "field": "timing",
      "accessor": "timing",
      "label": "Timing",
      "Header": "Timing",
      "editable": true,
      "type": "autocomplete",
      "options": [
        { label: 'Morning', value: 'Morning' },
        { label: 'Afternoon', value: 'Afternoon' },
        { label: 'Evening', value: 'Evening' },
        { label: 'Night', value: 'Night' },
      ],
      "visible": true,
      "order": 4,
    },
    {
      "field": "duration",
      "accessor": "duration",
      "label": "Duration",
      "Header": "Duration",
      "editable": true,
      "type": "text",
      "visible": true,
      "order": 5,
    },
    {
      "field": "start_from",
      "accessor": "start_from",
      "label": "Start From",
      "Header": "Start From",
      "editable": true,
      "type": "text",
      "visible": true,
      "order": 6,
    },
    {
      "field": "instructions",
      "accessor": "instructions",
      "label": "Instructions",
      "Header": "Instructions",
      "editable": true,
      "type": "text",
      "visible": true,
      "order": 7,
    }
  ];

  const formFields = [
    {
      label: 'Contact',
      key: 'contact_id',
      type: 'select',
      placeholder: 'Select the contact',
      visible: true,
      width: '25%',
      options: response.Contacts || [],
      tab: 'General',
      creatableAction: (props) => {
        const params = {
          ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? {
            is_default_values: true,
            phone: props.search,
            is_supplier: false,
          } : {})
        };
        OpenModalCustom({ newModule: "Contacts", field_key: 'contact_id', params });
      },
      customSelectItem: CustomSelectItemForContacts,
      otherSearchKeys: ['phone', 'email'],
      autoFocus: true,
    },
    // start_date_time
    {
      label: 'Date',
      key: 'date',
      type: 'date',
      placeholder: 'Date',
      visible: true,
      width: '25%',
      tab: 'General',
      value: new Date(),
    },

    {
      label: 'Employee',
      key: 'employee_id',
      type: 'select',
      placeholder: 'Select the employee',
      visible: true,
      tab: 'General',
      width: '25%',
      options: (response.Employees || []).map((item) => {
        return {
          ...item,
          label: item.name,
          value: item._id
        }
      }),
      value: globalData.employee_id ? response.Employees.find((item) => item._id == globalData.employee_id)?._id : null
    },

    {
      label: 'Duration',
      key: 'duration',
      type: 'duration',
      duration_options: ["minutes", "hours"],
      placeholder: 'duration',
      visible: true,
      width: '25%',
      tab: 'General',
      value: 1800000,
    },

    {
      label: 'Type',
      key: 'type',
      type: 'select',
      options: [{ label: 'First Visit', value: 'First Visit' }, { label: 'Follow Up', value: 'Follow Up' }],
      placeholder: 'Type',
      visible: true,
      width: '25%',
      tab: 'General',
      value: "First Visit",
    },

    {
      type: 'custom',
      visible: true,
      width: '100%',
      tab: 'General',
      component: ({ field, useForm, tKey, tIndex }) => {
        const date = useForm((state) => state.formObject['date']);
        const duration = useForm((state) => state.formObject['duration']);
        const employee_id = useForm((state) => state.formObject['employee_id']);
        const setFormObject = useForm((state) => state.setFormObject);
        const slots = useForm((state) => state.formObject['slots']);
        const slot = useForm((state) => state.formObject['slot']);

        useEffect(() => {
          getSlots();

          async function getSlots() {
            if (date && duration && globalData.employee_id) {
              await api.customRoute("Appointments/action", {
                action: "getSlots",
                employee_id: globalData.employee_id,
                date: date,
                duration: duration,
              }).then((res) => {
                console.log("res", res);
                const available_slots = res.available_slots;

                const current_time = moment();
                const current_time_ms = current_time.valueOf();
                let closest_slot = null;

                available_slots.forEach((slot) => {
                  if (closest_slot == null) {
                    closest_slot = slot;
                  } else {
                    if (Math.abs(moment(slot).valueOf() - current_time_ms) < Math.abs(moment(closest_slot).valueOf() - current_time_ms)) {
                      closest_slot = slot;
                    }
                  }
                });

                setFormObject({ slots: available_slots, slot: closest_slot });
              });
            }
          }
        }, [date, duration, employee_id]);

        return <View style={{
          width: '100%',
        }}>
          {Label({ label: 'Slot' })}
          <View row style={{
            flexWrap: 'wrap',
            width: '100%',
            marginLeft: -2,
          }}>
            {(slots || []).length > 0 ? (slots || []).map((single_slot) => ({ label: moment(single_slot).format('hh:mm A') + " - " + moment(single_slot).add(duration, 'ms').format('hh:mm A'), value: single_slot })).map((single_slot) => {
              return <TouchableOpacity
                key={single_slot.value}
                margin-5
                style={{
                  width: Platform.OS == 'web' ? 150 : '50%',
                }}
                onPress={() => {
                  setFormObject({ slot: single_slot.value });
                }}>
                <Chip
                  labelStyle={{
                    fontSize: 14,
                    lineHeight: 22,
                    fontFamily: 'SourceSansProSemiBold',
                    color: single_slot.value == slot ? '#5382ca' : '#2c2c2c',
                  }}
                  containerStyle={{
                    borderColor: single_slot.value == slot ? '#8faedd' : '#efeff1',
                    borderWidth: 2,
                    height: 40,
                  }}
                  label={single_slot.label} backgroundColor={single_slot.value == slot ? 'white' : 'white'} borderRadius={4} />
              </TouchableOpacity>
            }) : <View margin-2><Text>No slots available</Text></View>}
          </View>
        </View>;
      }
    },

    {
      type: 'divider',
      heading: 'Symptoms',
      visible: true,
      span: 24,
      tab: 'Prescription Pad'
    },

    {
      key: 'symptoms',
      type: 'table',
      compact: true,
      placeholder: 'duration',
      visible: (connectedObject) => connectedObject.symptoms?.length > 0,
      width: '100%',
      tab: 'Prescription Pad',
      value: [],
      columns: symptomsColumns,
      actions: true,
      forceUpdate: true,
      actionFunctions: (row) => ([{
        label: 'Delete',
        key: 'delete',
        fn: ({ data, updateData, row, row_index }) => {
          let newData = [...data];
          newData.splice(row_index, 1);
          updateData(newData);
        }
      }]),
    },
    {
      label: '',
      key: 'symptoms_add',
      type: 'autocomplete',
      placeholder: 'Add Symptoms',
      visible: true,
      width: '100%',
      auto_fill_key: 'symptoms',
      options: [{ label: 'Symptom 1', value: 'Symptom 1' }, { label: 'Symptom 2', value: 'Symptom 2' }],
      tab: 'Prescription Pad',
      onSelect: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        const symptoms = [...(getObj()["symptoms"] || [])];

        symptoms.push({
          name: value,
          since: null,
          severity: '',
          details: '',
        });

        setObj({
          symptoms: symptoms,
          symptoms_add: '',
          updateVisiblity: Math.random()
        });
      }
    },

    {
      type: 'divider',
      heading: 'Vitals',
      visible: true,
      span: 24,
      tab: 'Prescription Pad'
    },

    {
      label: 'Pulse rate',
      key: 'vitals___pulse_rate',
      type: 'text',
      placeholder: 'Pulse rate',
      visible: true,
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Peripheral oxygen saturation',
      key: 'vitals___peripheral_oxygen_saturation',
      type: 'text',
      placeholder: 'Peripheral oxygen saturation',
      visible: true,
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Blood pressure',
      key: 'vitals___blood_pressure',
      type: 'text',
      placeholder: 'Blood pressure',
      visible: true,
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Respiratory rate',
      key: 'vitals___respiratory_rate',
      type: 'text',
      placeholder: 'vitals___Respiratory rate',
      visible: true,
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Body Temperature',
      key: 'vitals___body_temperature',
      type: 'text',
      placeholder: 'Body Temperature',
      visible: true,
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Body height',
      key: 'vitals___body_height',
      type: 'text',
      placeholder: 'Body height',
      visible: true,
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Body weight',
      key: 'vitals___body_weight',
      type: 'text',
      placeholder: 'Body weight',
      visible: true,
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Body mass index',
      key: 'vitals___body_mass_index',
      type: 'text',
      placeholder: 'Body mass index',
      visible: true,
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Systolic blood pressure',
      key: 'vitals___systolic_blood_pressure',
      type: 'text',
      placeholder: 'Systolic blood pressure',
      visible: true,
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Diastolic blood pressure',
      key: 'vitals___diastolic_blood_pressure',
      type: 'text',
      placeholder: 'Diastolic blood pressure',
      visible: true,
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Blood sugar',
      key: 'vitals___blood_sugar',
      type: 'text',
      placeholder: 'Blood sugar',
      visible: true,
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      type: 'divider',
      heading: 'Diagnosis',
      visible: true,
      span: 24,
      tab: 'Prescription Pad'
    },

    {
      key: 'diagnosis',
      type: 'table',
      compact: true,
      placeholder: 'duration',
      visible: (connectedObject) => connectedObject.diagnosis?.length > 0,
      width: '100%',
      tab: 'Prescription Pad',
      value: [],
      columns: diagnosisColumns,
      actions: true,
      forceUpdate: true,
      actionFunctions: (row) => ([{
        label: 'Delete',
        key: 'delete',
        fn: ({ data, updateData, row, row_index }) => {
          let newData = [...data];
          newData.splice(row_index, 1);
          updateData(newData);
        }
      }]),
    },
    {
      label: '',
      key: 'diagnosis_add',
      type: 'autocomplete',
      placeholder: 'Add Diagnosis',
      visible: true,
      width: '100%',
      options: [{ label: 'Diagnosis 1', value: 'Diagnosis 1' }, { label: 'Diagnosis 2', value: 'Diagnosis 2' }],
      tab: 'Prescription Pad',
      onSelect: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        const diagnosis = [...(getObj()["diagnosis"] || [])];

        diagnosis.push({
          name: value,
          since: null,
          status: '',
          details: '',
        });

        setObj({
          diagnosis: diagnosis,
          diagnosis_add: '',
          updateVisiblity: Math.random()
        });
      }
    },

    {
      type: 'divider',
      heading: 'Medications',
      visible: true,
      span: 24,
      tab: 'Prescription Pad'
    },

    {
      key: 'medications',
      type: 'table',
      compact: true,
      placeholder: 'duration',
      visible: (connectedObject) => connectedObject.medications?.length > 0,
      width: '100%',
      tab: 'Prescription Pad',
      value: [],
      columns: medicationsColumns,
      actions: true,
      forceUpdate: true,
      actionFunctions: (row) => ([{
        label: 'Delete',
        key: 'delete',
        fn: ({ data, updateData, row, row_index }) => {
          let newData = [...data];
          newData.splice(row_index, 1);
          updateData(newData);
        }
      }]),
    },
    {
      label: '',
      key: 'medications_add',
      type: 'autocomplete',
      placeholder: 'Add Medications',
      visible: true,
      width: '100%',
      options: [{ label: 'Diagnosis 1', value: 'Diagnosis 1' }, { label: 'Diagnosis 2', value: 'Diagnosis 2' }],
      tab: 'Prescription Pad',
      onSelect: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        const medications = [...(getObj()["medications"] || [])];

        medications.push({
          name: value,
          since: null,
          status: '',
          details: '',
        });

        setObj({
          medications: medications,
          medications_add: '',
          updateVisiblity: Math.random()
        });
      }
    },

    {
      type: 'custom',
      visible: true,
      width: '100%',
      tab: 'Billing',
      component: InvoiceComponent,
    },

    {
      key: 'active_subscriptions',
      type: 'custom',
      visible: true,
      width: '100%',
      tab: 'Subscriptions',
      component: ({ field, useForm, tKey, tIndex }) => {

        return <>
          <View flex row>
            <View style={{
              width: '50%',
            }}>
              <Card padding-10 flex style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: 10 }}>
                <View marginB-10 row spread style={{ width: '100%' }}>

                  <View flex>
                    <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                      {"Active Subscription"}
                    </Text>

                    {Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                      <View
                        style={{
                          borderStyle: 'dashed',
                          borderWidth: 1,
                          borderColor: '#d9d9d9',
                          margin: -2,
                          marginTop: 5,
                        }}>
                        <View style={{ height: 20, width: '100%' }} />
                      </View>
                    </View> : <View style={{
                      borderBottomColor: '#d9d9d9',
                      borderBottomWidth: 1,
                      width: '100%',
                      marginBottom: 0,
                      marginTop: 5,
                      borderStyle: 'dashed',
                    }} />}

                    <View flex row marginT-10>
                      <View row marginB-6 flex centerV>
                        <View>
                          <Text lightCardTitle>{"Start Date"}: </Text>
                        </View>
                        <View flex>
                          <Text>21st April 2023</Text>
                        </View>
                      </View>
                      <View row marginB-6 flex>
                        <View>
                          <Text lightCardTitle>{"Expiry Date"}: </Text>
                        </View>
                        <View flex>
                          <Text>21st May 2023</Text>
                        </View>
                      </View>
                    </View>

                    {Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                      <View
                        style={{
                          borderStyle: 'dashed',
                          borderWidth: 1,
                          borderColor: '#d9d9d9',
                          margin: -2,
                          marginTop: 5,
                        }}>
                        <View style={{ height: 20, width: '100%' }} />
                      </View>
                    </View> : <View style={{
                      borderBottomColor: '#d9d9d9',
                      borderBottomWidth: 1,
                      width: '100%',
                      marginBottom: 0,
                      marginTop: 5,
                      borderStyle: 'dashed',
                    }} />}

                    <View marginT-10>
                      <View flex>
                        <View row marginB-6 flex centerV>
                          <View>
                            <Text lightCardTitle>{"Consultation"}: </Text>
                          </View>
                          <View flex row>
                            <Text style={{ fontFamily: 'SourceSansProBold' }}>3</Text><Text> remaining out of </Text> <Text style={{ fontFamily: 'SourceSansProBold' }}>5</Text>
                          </View>
                        </View>
                        <View row marginB-6 flex>
                          <View>
                            <Text lightCardTitle>{"PRP"}: </Text>
                          </View>
                          <View flex row>
                            <Text style={{ fontFamily: 'SourceSansProBold' }}>3</Text><Text> remaining out of </Text> <Text style={{ fontFamily: 'SourceSansProBold' }}>5</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

              </Card>
            </View>
          </View>
        </>
      }
    },
  ];

  const CustomFields = response.CustomFields;

  if (CustomFields && CustomFields.length > 0) {
    CustomFields.forEach((field) => {
      formFields.push({
        label: field.name,
        key: 'cf.' + field.key,
        type: field.type,
        placeholder: field.placeholder,
        visible: true,
        width: '25%'
      });
    });
  }

  return formFields;
}

export const appointmentMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const appointmentMain = {
  fields: appointmentfields,
  mobileCard: null
}