// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, RefreshControl } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, Avatar, TouchableOpacity } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, DangerButton, Heading, Row, SecondaryButton, CustomTextField, Label, CustomCheckbox } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import dayjs from "dayjs"
import { Camera, CameraType } from 'expo-camera';
import Svg, { G, Path } from "react-native-svg"
import Header from "./Header"
import Footer from "./Footer"
import { Icon } from "react-native-eva-icons"
import { App } from "antd"
const isDesktop = Dimensions.get('window').width >= 768;


const Product = (props) => {
    const { message, modal } = App.useApp();
    const [product, setProduct] = useState({});
    const [currentImage, setCurrentImage] = useState(0);
    const cart = GlobalMasterStore(state => state.cart);
    const setCart = GlobalMasterStore(state => state.setCart);

    const wishList = GlobalMasterStore(state => state.wishList);
    const setWishList = GlobalMasterStore(state => state.setWishList);

    const params = useParams();

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const getProduct = async () => {
        const response = await api.customRoute("shop/getProduct", {
            id: params.id,
        });

        console.log(response?.data);

        setProduct(response?.data);

        if (response?.data?.image_urls && response?.data?.image_urls?.length > 0) {
            setCurrentImage(0);
        }
    };

    useEffect(() => {
        getProduct();
    }, [params.id]);

    return (<>
        <ScrollView style={{
            height: Dimensions.get("window").height,
        }}>
            <Card>
                <Header />
                <Row>
                    <View style={{
                        width: '100%',
                        padding: 50,
                    }}>
                        <View row={isDesktop} style={{
                            width: '100%'
                        }}>
                            <Col width={isDesktop ? "60%" : '100%'}>
                                <View row style={{
                                    width: '100%',
                                    marginTop: -10
                                }}>
                                    <View>
                                        {product.image_urls && product.image_urls.map((image_url, i) => <View
                                            key={i}
                                            style={{
                                                padding: 10,
                                            }}>
                                            <TouchableOpacity onPress={() => {
                                                setCurrentImage(i);
                                            }}
                                            ><Image resizeMode="cover" source={{ uri: image_url }} style={{ width: 100, height: 100 }} />
                                            </TouchableOpacity>
                                        </View>)}
                                    </View>
                                    <View flexG style={{
                                        padding: 10
                                    }}>
                                        {Platform.OS === 'web' ? <img src={product.image_urls ? product.image_urls[currentImage] : null} style={{ width: '100%', height: "auto" }} /> : <Image resizeMode="cover" source={{ uri: product.image_urls ? product.image_urls[currentImage] : null }} style={{ width: '100%', height: Dimensions.get("window").height - 100 }} />}
                                    </View>
                                </View>
                            </Col>

                            <Col width={isDesktop ? "40%" : '100%'}
                                style={{
                                    paddingLeft: isDesktop ? 40 : 0,
                                }}>
                                <View style={{
                                    paddingBottom: 5,
                                }}>
                                    <Text style={{
                                        color: "#000",
                                        fontSize: 24,
                                        fontFamily: 'SourceSansProBold'
                                    }}>{product.name}</Text>
                                </View>

                                <View style={{
                                    paddingBottom: 15,
                                }}>
                                    <Text style={{
                                        color: "#000",
                                        fontSize: 24,
                                    }}>Rs. {product.sales_rate}</Text>
                                </View>

                                <View style={{
                                    paddingBottom: 15,
                                }}>
                                    <Text>{product.description}</Text>
                                </View>

                                <Text h1>
                                    Specifications
                                </Text>

                                <Row style={{
                                    marginTop: 10,
                                }}>
                                    <Col width="50%">
                                        <View style={{
                                            width: '100%',
                                        }}>
                                            <View style={{
                                            }}>
                                                <Text style={{
                                                    fontSize: 13,
                                                    color: '#7e818c',
                                                }}>
                                                    Sleeve Length
                                                </Text>
                                            </View>
                                            <View marginT-5>
                                                <Text style={{
                                                    fontSize: 15,
                                                }}>
                                                    Short Sleeves
                                                </Text>
                                            </View>

                                            <View marginT-15 style={{
                                                height: 1,
                                                backgroundColor: '#e5e7eb',
                                                width: '100%',
                                            }}></View>
                                        </View>
                                    </Col>
                                    <Col width="50%">
                                        <View style={{
                                            width: '100%',
                                        }}>
                                            <View style={{
                                            }}>
                                                <Text style={{
                                                    fontSize: 13,
                                                    color: '#7e818c',
                                                }}>
                                                    Sleeve Length
                                                </Text>
                                            </View>
                                            <View marginT-5>
                                                <Text style={{
                                                    fontSize: 15,
                                                }}>
                                                    Short Sleeves
                                                </Text>
                                            </View>

                                            <View marginT-15 style={{
                                                height: 1,
                                                backgroundColor: '#e5e7eb',
                                                width: '100%',
                                            }}></View>
                                        </View>
                                    </Col>
                                    <Col width="50%">
                                        <View style={{
                                            width: '100%',
                                        }}>
                                            <View style={{
                                            }}>
                                                <Text style={{
                                                    fontSize: 13,
                                                    color: '#7e818c',
                                                }}>
                                                    Sleeve Length
                                                </Text>
                                            </View>
                                            <View marginT-5>
                                                <Text style={{
                                                    fontSize: 15,
                                                }}>
                                                    Short Sleeves
                                                </Text>
                                            </View>

                                            <View marginT-15 style={{
                                                height: 1,
                                                backgroundColor: '#e5e7eb',
                                                width: '100%',
                                            }}></View>
                                        </View>
                                    </Col>
                                    <Col width="50%">
                                        <View style={{
                                            width: '100%',
                                        }}>
                                            <View style={{
                                            }}>
                                                <Text style={{
                                                    fontSize: 13,
                                                    color: '#7e818c',
                                                }}>
                                                    Sleeve Length
                                                </Text>
                                            </View>
                                            <View marginT-5>
                                                <Text style={{
                                                    fontSize: 15,
                                                }}>
                                                    Short Sleeves
                                                </Text>
                                            </View>

                                            <View marginT-15 style={{
                                                height: 1,
                                                backgroundColor: '#e5e7eb',
                                                width: '100%',
                                            }}></View>
                                        </View>
                                    </Col>
                                </Row>

                                {product?.options?.map((option, i) => <><View key={i} marginB-10>
                                    <Text style={{
                                        fontFamily: 'SourceSansProSemiBold',
                                    }}>Select {option.name}</Text>
                                </View>

                                    <View style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                    }}>
                                        <View style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}>
                                            {option?.value?.map((value, i) => <TouchableOpacity
                                                key={i}
                                                onPress={() => {
                                                    const variant_products = product?.variant_products;

                                                    const currentMeta = product?.meta;
                                                    currentMeta[option.name] = value;

                                                    const matchingVariantProduct = variant_products.find((variant_product) => {
                                                        let isMatching = true;
                                                        Object.keys(currentMeta).map((metaKey) => {
                                                            if (variant_product?.meta?.[metaKey] != currentMeta[metaKey]) {
                                                                isMatching = false;
                                                            }
                                                        });
                                                        return isMatching;
                                                    });

                                                    navigationFn("shop/product/" + matchingVariantProduct?.sku + "/" + matchingVariantProduct?._id);
                                                }}
                                                style={product.meta[option.name] == value ? {
                                                    backgroundColor: 'none',
                                                    height: '40px',
                                                    width: '40px',
                                                    marginRight: 10,
                                                    border: '1px solid rgb(234 212 171)',
                                                    fontWeight: 'bold',
                                                    color: '#000',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    background: "#cab78d1c"
                                                } : {
                                                    height: '40px',
                                                    width: '40px',
                                                    color: '#fff',
                                                    border: '1px solid rgb(229, 231, 235)',
                                                    fontWeight: 'bold',
                                                    marginRight: 10,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>
                                                <Text>
                                                    {value}
                                                </Text>
                                            </TouchableOpacity>
                                            )}
                                        </View>
                                    </View></>)}

                                {/* <View style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingBottom: 15,
                                    paddingTop: 25,
                                }}>
                                    <Text style={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}>QUANTITY: </Text>
                                </View> */}

                                <View row style={{
                                    width: '100%',
                                }}>
                                    {/* <View style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}>
                                        <View style={{
                                            flexDirection: 'row',
                                            width: '30%',
                                        }}>
                                            <TouchableOpacity onPress={() => {

                                            }} style={{
                                                backgroundColor: '#fff',
                                                border: '2px solid #DCDCDC',
                                                color: '#000',
                                                padding: 10,
                                                width: '33%',
                                                alignItems: 'center',
                                                fontWeight: 'bold',
                                                marginRight: 5,
                                            }}
                                            ><Text>-</Text></TouchableOpacity>

                                            <View style={{
                                                width: '34%',
                                            }}>
                                                <CustomTextField
                                                    placeholder={"0"}
                                                    value={quantity}
                                                    onChange={(value) => {
                                                        setQuantity(value);
                                                    }}
                                                    center
                                                    style={{
                                                        borderRadius: 0,
                                                        backgroundColor: '#DCDCDC',
                                                        color: '#000',
                                                    }}
                                                />
                                            </View>

                                            <TouchableOpacity onPress={() => {

                                            }} style={{
                                                backgroundColor: '#fff',
                                                border: '2px solid #DCDCDC',
                                                color: '#000',
                                                padding: 10,
                                                width: '33%',
                                                alignItems: 'center',
                                                fontWeight: 'bold',
                                                marginLeft: 5,
                                            }}
                                            ><Text>+</Text></TouchableOpacity>

                                        </View>

                                        <View style={{
                                            width: '70%',
                                            paddingLeft: 15,
                                        }}>
                                            <TouchableOpacity onPress={() => {

                                            }} style={{
                                                backgroundColor: '#fff',
                                                border: '2px solid #cab78d',
                                                color: '#cab78d',
                                                padding: 10,
                                                width: '100%',
                                                alignItems: 'center',
                                                fontWeight: 'bold',
                                                paddingVertical: 10,
                                                paddingHorizontal: 30,
                                            }}
                                            ><Text>ADD TO BAG</Text></TouchableOpacity>
                                        </View>

                                    </View> */}

                                    <View style={{
                                        flexGrow: 1,
                                        paddingTop: 10,
                                        paddingRight: 10,
                                    }}>
                                        {cart.filter(item => item._id == product._id).length > 0 ?
                                            <TouchableOpacity row center onPress={() => {
                                                navigationFn("shop/checkout");
                                            }} style={{
                                                backgroundColor: '#cab78d',
                                                border: '2px solid #cab78d',
                                                color: '#000',
                                                padding: 10,
                                                width: '100%',
                                                alignItems: 'center',
                                                fontWeight: 'bold',
                                                paddingVertical: 10,
                                                paddingHorizontal: 30,
                                            }}>
                                                <Text color="white">Go To Cart</Text>
                                                <View marginL-5>
                                                    <Icon name="shopping-cart-outline" fill="white" width={20} height={20} />
                                                </View>
                                            </TouchableOpacity> :
                                            <TouchableOpacity row center onPress={() => {
                                                setCart([...cart, {
                                                    _id: product._id,
                                                    name: product.name,
                                                    image_urls: product.image_urls,
                                                    meta: product.meta,
                                                    sales_rate: product.sales_rate,
                                                    quantity: 1
                                                }]);
                                                message.success("Product added to cart");
                                            }} style={{
                                                backgroundColor: '#cab78d',
                                                border: '2px solid #cab78d',
                                                color: '#000',
                                                padding: 10,
                                                width: '100%',
                                                fontWeight: 'bold',
                                                paddingVertical: 10,
                                                paddingHorizontal: 30,
                                                flexDirection: 'row',
                                            }}>
                                                <Text color="white">Add To Cart</Text>
                                                <View marginL-5>
                                                    <Icon name="shopping-cart-outline" fill="white" width={20} height={20} />
                                                </View>
                                            </TouchableOpacity>}
                                    </View>

                                    <View style={{
                                        width: 60,
                                        paddingTop: 10,
                                    }}>
                                        {wishList.filter(item => item._id == product._id).length > 0 ?
                                            <TouchableOpacity row center onPress={() => {
                                                setWishList(wishList.filter(item => item._id != product._id));
                                                message.success("Product removed from wishlist");
                                            }} style={{
                                                backgroundColor: '#fff',
                                                border: '2px solid #cab78d',
                                                color: '#cab78d',
                                                padding: 10,
                                                width: '100%',
                                                alignItems: 'center',
                                                fontWeight: 'bold',
                                                paddingVertical: 10,
                                                paddingHorizontal: 10,
                                            }}
                                            >
                                                {/* <Text color="#cab78d">Wishlist</Text> */}
                                                <View>
                                                    <Icon name="heart" fill="#cab78d" width={20} height={20} />
                                                </View>
                                            </TouchableOpacity> :
                                            <TouchableOpacity row center onPress={() => {
                                                setWishList([...(wishList || []), {
                                                    _id: product._id,
                                                    name: product.name,
                                                    image_urls: product.image_urls,
                                                    meta: product.meta,
                                                    sales_rate: product.sales_rate,
                                                }]);
                                                message.success("Product added to wishlist");
                                            }} style={{
                                                backgroundColor: '#fff',
                                                border: '2px solid #cab78d',
                                                color: '#cab78d',
                                                padding: 10,
                                                width: '100%',
                                                alignItems: 'center',
                                                fontWeight: 'bold',
                                                paddingVertical: 10,
                                                paddingHorizontal: 10,
                                            }}
                                            >
                                                <View>
                                                    <Icon name="heart-outline" fill="#cab78d" width={20} height={20} />
                                                </View>
                                            </TouchableOpacity>}
                                    </View>
                                </View>
                            </Col>
                        </View>
                    </View>
                </Row>
                <Footer />
            </Card >
        </ScrollView >
    </>);
};

export default Product;