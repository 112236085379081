// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, DeviceEventEmitter, TouchableOpacity, ActivityIndicator } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, RadioGroup, RadioButton } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomDatePicker, CustomEditorField, CustomModal, CustomNumberInput, CustomSelect, CustomTextAreaField, CustomTextField, CustomTextFieldWithScanner, CustomUpload, DangerButton, Heading, Label, PrimaryButton, Row, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import { Icon } from "react-native-eva-icons"
import { CustomSelectItemForContacts, TransactionPDFPreview, invoiceItemAdjustment } from "../../../../../models/accountancy/transaction"
import { create } from 'zustand'
import { FlashList } from "@shopify/flash-list";
import { GlobalModalStore } from "../../../../../models/ModalStore"
import { App } from 'antd';
import * as Sharing from 'expo-sharing';
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import CalendarStrip from 'react-native-calendar-strip';
import moment from "moment"
import Svg, { Circle, Path, Rect } from "react-native-svg"
import { Agenda } from 'react-native-calendars';
import { AgendaIcon, DateIcon, FilesIcon, InviteIcon } from "../../../../../models/taqwa/Meeting/Meetings"
import RenderHtml from 'react-native-render-html';
import FaceAuth from "../main/FaceAuth/FaceAuth"
import * as Location from 'expo-location';
import { GlobalLoaderStore } from "../../../../../models/GlobalLoaderStore"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import { useToast } from "react-native-toast-notifications"
import AsyncStorage from "@react-native-async-storage/async-storage"

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}))


const EventsScreen = observer(function EventsScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    let queryParams = { ...(props?.route?.params || {}) };
    const toast = useToast();

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const goBack = () => {
        if (Platform.OS === 'web') {
            window.history.back();
        } else {
            navigation.goBack();
        }
    }

    const moduleName = "DailyActivities";
    const { fields, dataTabs, preferencesFields, ExtraSaveComponent } = api.getForm(moduleName);
    const params = {};

    const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
    const globalData = GlobalMasterStore((state) => state.globalData);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const employee = GlobalMasterStore((state) => state.employee);
    const organization_id = GlobalMasterStore((state) => state.globalData.organization_id);
    const employee_id = GlobalMasterStore((state) => state.globalData.employee_id);
    const setLoading = GlobalLoaderStore((state) => state.setLoading);
    const loading = GlobalLoaderStore((state) => state.loading);

    const [date, setDate] = useState(new Date());
    const [viewOnly, setViewOnly] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [id, setId] = useState(null);
    const [data, setData] = useState(null);
    const [meetingItems, setMeetingItems] = useState({});
    const [showMeetingModal, setShowMeetingModal] = useState(false);
    const [showCourseSessionModal, setShowCourseSessionModal] = useState(false);
    const [meetingId, setMeetingId] = useState(null);
    const [meeting, setMeeting] = useState({});

    const [courseSessionId, setCourseSessionId] = useState(null);
    const [courseSession, setCourseSession] = useState({});
    const [courseSessionAttendance, setCourseSessionAttendance] = useState({});

    const [faceAuthModalVisible, setFaceAuthModalVisible] = useState(false);
    const [mcqModalVisible, setMCQModalVisible] = useState(false);
    const [excuseRequestModalVisible, setExcuseRequestModalVisible] = useState(false);
    const [meetingAttendance, setMeetingAttendance] = useState({});

    const [hasLocationPermission, setHasLocationPermission] = useState(false);

    const [excuse_reason, setExcuseReason] = useState(null);
    const [excuse_files, setExcuseFiles] = useState([]);

    const [location_address, setLocationDetails] = useState({});
    const intervalRef = React.useRef();
    const [yearMonthMap, setYearMonthMap] = useState({});
    const [reloadAgendaKey, setReloadAgendaKey] = useState(0);
    const [face_auth_image_url, setFaceAuthImageUrl] = useState(null);
    const [enable_face_auth, setEnableFaceAuth] = useState(false);
    const [locationRequestedOnced, setLocationRequestedOnced] = useState(false);
    const [calendarExpanded, setCalendarExpanded] = useState(false);
    const setToken = GlobalMasterStore((state) => state.setToken);

    const getEvents = async ({ start, end }) => {
        const data = {
            action: 'getAllEvents',
            employee_id: employee._id,
            start_date: start,
            end_date: end,
            showMeetings: queryParams.showMeetings,
            showTraining: queryParams.showTraining,
            showLeadMeetings: queryParams.showLeadMeetings,
        };

        const response = await api.customRoute('Meetings/action', data);

        if (response.logout_user) {
            Alert.alert('Error', 'Your Membership payment is due. Please pay your membership dues to continue using the app.');

            if (Platform.OS != 'web') {
                await AsyncStorage.removeItem('token');
                await AsyncStorage.removeItem('globalData');
            } else {
                localStorage.removeItem('token');
                localStorage.removeItem('globalData');
            }

            setToken(null);
            navigationFn("login", {});

            return;
        }

        let events = response || [];

        let newEvents = { ...meetingItems };

        setMeetingItems({})

        events.forEach((event) => {
            const date = moment(event.start).format('YYYY-MM-DD');

            if (newEvents[date]) {
                const index = newEvents[date].findIndex((item) => {
                    return item.id == event.id;
                });

                if (index == -1) {
                    newEvents[date].push(event);
                }
            } else {
                newEvents[date] = [event];
            }
        });

        const startMoment = moment(start);
        const endMoment = moment(end);

        while (startMoment.isSameOrBefore(endMoment)) {
            const date = startMoment.format('YYYY-MM-DD');

            if (!newEvents[date]) {
                newEvents[date] = [];
            }

            startMoment.add(1, 'day');
        }

        setMeetingItems(newEvents);

        setTimeout(() => {
            setReloadAgendaKey(Math.random());
        }, 100);
    };

    useEffect(() => {
        for (const yearMonth in yearMonthMap) {
            if (yearMonthMap[yearMonth] == 'fetchData') {
                const start = moment(yearMonth, "YYYY-MM").startOf('month').toDate();
                const end = moment(yearMonth, "YYYY-MM").endOf('month').toDate();
                getEvents({ start: start, end: end });

                const yearMonthMapCopy = { ...yearMonthMap };
                yearMonthMapCopy[yearMonth] = 'fetchedData';
                setYearMonthMap(yearMonthMapCopy);
            }
        }
    }, [yearMonthMap, queryParams]);

    const getLocationDetails = async () => {

        if (!locationRequestedOnced) {
            if (!hasLocationPermission) {
                let { status, canAskAgain } = await Location.requestForegroundPermissionsAsync();

                if (status !== 'granted') {
                    if (!canAskAgain) {
                        Alert.alert(
                            "Location Permission",
                            "Please enable location permission from settings",
                            [
                                {
                                    text: "Cancel",
                                    onPress: () => console.log("Cancel Pressed"),
                                    style: "cancel"
                                },
                                { text: "OK", onPress: () => Location.openSettings() }
                            ]
                        );
                    }
                    setFetchingLocation(false);
                    return;
                } else {
                    setHasLocationPermission(true);
                }
            }

            let location = await Location.getCurrentPositionAsync({
                accuracy: Location.Accuracy.BestForNavigation,
            });

            const address_response = await api.getFormattedAddress(location.coords.latitude, location.coords.longitude);

            setLocationDetails({
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
                location_address: address_response.display_name,
            })
        } else if (showMeetingModal || showCourseSessionModal || faceAuthModalVisible) {
            if (!hasLocationPermission) {
                let { status, canAskAgain } = await Location.requestForegroundPermissionsAsync();

                if (status !== 'granted') {
                    if (!canAskAgain) {
                        Alert.alert(
                            "Location Permission",
                            "Please enable location permission from settings",
                            [
                                {
                                    text: "Cancel",
                                    onPress: () => console.log("Cancel Pressed"),
                                    style: "cancel"
                                },
                                { text: "OK", onPress: () => Location.openSettings() }
                            ]
                        );
                    }
                    setFetchingLocation(false);
                    return;
                } else {
                    setHasLocationPermission(true);
                }
            }

            let location = await Location.getCurrentPositionAsync({
                accuracy: Location.Accuracy.BestForNavigation,
            });

            const address_response = await api.getFormattedAddress(location.coords.latitude, location.coords.longitude);

            setLocationDetails({
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
                location_address: address_response.display_name,
            })
        }
    };

    useEffect(() => {
        const getFaceAuthImage = async () => {
            const res = await api.getPermissions({
                organization_id: organization_id,
                employee_id: employee_id
            })

            if (res) {
                console.log("FACE AUTH IMAGE URL", res.employee?.face_auth_image_url);

                setFaceAuthImageUrl(res.employee?.face_auth_image_url);
                setEnableFaceAuth(res.employee?.enable_face_auth);
            }
        };

        getFaceAuthImage();

        return () => {
            clearInterval(intervalRef);
        }
    }, [organization_id, employee_id]);

    useEffect(() => {
        const getMeetingSingle = async () => {
            const response = await api.customRoute('MeetingAttendances/action', {
                action: 'getMeetingAttendance',
                meeting_id: meetingId,
                employee_id: employee._id,
            });

            setMeetingAttendance(response.data);
            setMeeting(response.meeting);
        };

        if (meetingId) {
            setExcuseReason(null);
            setExcuseFiles([]);
            getMeetingSingle();
        }
    }, [meetingId]);

    useEffect(() => {
        const getCourseSessionSingle = async () => {
            const response = await api.customRoute('MeetingAttendances/action', {
                action: 'getCourseSessionAttendance',
                course_session_id: courseSessionId,
                employee_id: employee._id,
            });

            setCourseSessionAttendance(response.data);
            setCourseSession(response.courseSession);
        };

        if (courseSessionId) {
            setExcuseReason(null);
            setExcuseFiles([]);
            getCourseSessionSingle();
        }
    }, [courseSessionId]);

    const calendarIdNameMap = {
        0: 'Meeting',
        1: 'Training',
        2: 'Lead Meeting',
    };

    return (
        <View style={{
            flex: 1,
            position: 'relative'
        }}>
            <Agenda
                items={meetingItems}
                renderItem={(item, firstItemInDay) => {
                    return (
                        <TouchableOpacity onPress={() => {
                            if (item?.moduleName) {
                                if (item.calendarId == 0) {
                                    setMeetingId(item.id);
                                    setShowMeetingModal(true);
                                }

                                if (item.calendarId == 1) {
                                    setCourseSessionId(item.id);
                                    setShowCourseSessionModal(true);
                                }

                                if (item.calendarId == 2) {
                                    navigationFn('New', {
                                        moduleName: "LeadMeetings",
                                        id: item.id,
                                        viewOnly: true
                                    });
                                }
                            }
                        }}>
                            <View style={[{
                                backgroundColor: 'white',
                                borderRadius: 5,
                                padding: 10,
                                marginRight: 10,
                                marginTop: 17
                            }, firstItemInDay && { marginTop: 10 }]}>
                                <View row spread>

                                    <View style={{
                                        flexGrow: 1,
                                    }}>
                                        <View style={{
                                            flexDirection: 'row',
                                            flexGrow: 1,
                                        }}>
                                            <Text darkCardTitle style={{
                                                flex: 1, flexWrap: 'wrap'
                                            }}>{item.title}</Text>
                                        </View>
                                    </View>
                                    <View backgroundColor="#007C89" paddingH-10 center paddingV-5 style={{
                                        borderRadius: 5,
                                        maxHeight: 24
                                    }}>
                                        <Text white style={{
                                            fontSize: 10,
                                            fontFamily: 'SourceSansProSemiBold',
                                            letterSpacing: 1,
                                            textTransform: 'uppercase'
                                        }}>{calendarIdNameMap[item.calendarId] || 'Unknown'}</Text>
                                    </View>
                                </View>
                                <Text lightCardTitle style={{
                                    fontSize: 12
                                }}>
                                    {moment(item.start).format('hh:mm A')} - {moment(item.end).format('hh:mm A')}
                                </Text>
                            </View>
                        </TouchableOpacity>
                    );
                }}
                // renderEmptyDate={() => {
                //     return (
                //         <View style={{
                //             height: 15,
                //             flex: 1,
                //             paddingTop: 30
                //         }}>
                //             <Text>This is empty date!</Text>
                //         </View>
                //     );
                // }}
                rowHasChanged={(r1, r2) => {
                    return r1.name !== r2.name;
                }}
                loadItemsForMonth={month => {
                    const dateString = month.dateString;
                    const yearMonth = moment(dateString, "YYYY-MM-DD").format('YYYY-MM');
                    const yearMonthMapCopy = { ...yearMonthMap };
                    yearMonthMapCopy[yearMonth] = 'fetchData';
                    setYearMonthMap(yearMonthMapCopy);
                }}
                showClosingKnob={true}
                onCalendarToggled={(calendarOpened) => {
                    if (calendarOpened) {
                        toast.show(`Select a date to close the calendar`, {
                            type: 'normal',
                            position: 'bottom',
                            duration: 3000,
                            offset: 30,
                            animationType: 'slide-in'
                        });
                    }
                }}
            />

            <CustomModal
                visible={showMeetingModal}
                onClose={() => {
                    setShowMeetingModal(false);
                    setMeetingId(null);
                    setMeeting({});
                }}
                title={(meeting?.title) || "Loading..."}
                padding={0}
            >
                <View marginT-20 style={{
                    position: 'relative'
                }}>
                    {loading && <View style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: Dimensions.get('window').height,
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        zIndex: 9999999999,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <ActivityIndicator size="large" color="#29459C" />
                    </View>}
                    <KeyboardAwareScrollView
                        extraHeight={300}
                        enableOnAndroid={true}
                        keyboardShouldPersistTaps='handled'
                        contentContainerStyle={{
                            flexGrow: 1,
                            padding: 10
                        }}
                        style={{
                            height: Dimensions.get('window').height - 100
                        }}>
                        {meeting?.title ? <Card padding-20 >
                            <View w-100>
                                <View row marginB-10>
                                    <View row>
                                        <DateIcon />
                                    </View>

                                    <View marginL-10>

                                        <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 18 }}>
                                            {moment(meeting.date).format('DD MMM YYYY hh:mm A')} - {moment(meeting.date).add(meeting.duration, 'ms').format('hh:mm A')}
                                        </Text>
                                    </View>
                                </View>

                                <View style={{
                                    height: 1,
                                    backgroundColor: '#e6e6e6',
                                    width: '100%',
                                    marginTop: 10,
                                    marginBottom: 10
                                }} />

                                <View row marginB-10>
                                    <View row>
                                        <AgendaIcon />
                                    </View>

                                    <View marginL-10>
                                        <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 18 }}>Agenda</Text>
                                        {Platform.OS === 'web' ? <div dangerouslySetInnerHTML={{ __html: (meeting.description || "") }} /> : <RenderHtml contentWidth={Dimensions.get('window').width - 140} source={{ html: (meeting.description || "") }} />}
                                    </View>
                                </View>

                                <View style={{
                                    height: 1,
                                    backgroundColor: '#e6e6e6',
                                    width: '100%',
                                    marginTop: 10,
                                    marginBottom: 10
                                }} />

                                <View row marginB-10>
                                    <View row>
                                        <InviteIcon />
                                    </View>

                                    <View marginL-10>
                                        <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 18 }}>Invited</Text>
                                        <View row marginT-5 style={{ flexWrap: 'wrap' }}>
                                            {meeting.employees?.length > 0 ? meeting.employees.map((item, i) => {
                                                return <View key={i} row>
                                                    <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>{item.name}</Text>
                                                    {i !== meeting.employees.length - 1 ? <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>, </Text> : null}
                                                </View>
                                            }) : <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>No one invited</Text>}
                                        </View>
                                    </View>
                                </View>

                                <View style={{
                                    height: 1,
                                    backgroundColor: '#e6e6e6',
                                    width: '100%',
                                    marginTop: 10,
                                    marginBottom: 10
                                }} />

                                <View row marginB-10>
                                    <View row>
                                        <FilesIcon />
                                    </View>

                                    <View marginL-10>
                                        <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 18 }}>Files</Text>
                                        <View row marginT-5 style={{ flexWrap: 'wrap' }}>
                                            {meeting.meeting_files?.length > 0 ? <CustomUpload
                                                mimeType={"image/*,application/pdf"}
                                                moduleName="Employees"
                                                multiple={true}
                                                value={meeting.meeting_files || []}
                                                onChange={(value) => {

                                                }}
                                                showNaming={false}
                                                readOnly={true}
                                            /> : <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>No files attached</Text>}
                                        </View>
                                    </View>
                                </View>

                                <View row marginB-10>
                                    {!meetingAttendance?.clock_in ? <DangerButton style={{
                                        flex: 1,
                                        marginTop: 40,
                                    }} onPress={() => {
                                        const now = moment();
                                        const meetingDate = moment(meeting.date);
                                        const diff = meetingDate.diff(now, 'minutes');
                                        getLocationDetails();
                                        // if (diff > 60) {
                                        //     Alert.alert('Meeting not started yet', 'You can join the meeting 1 hour before the meeting');
                                        //     return;
                                        // }

                                        setFaceAuthModalVisible(true)
                                    }}>
                                        <View row center>
                                            <View marginR-5>
                                                <Icon name="log-in-outline" width={20} height={20} fill="rgb(29, 78, 195)" />
                                            </View>
                                            <Text style={{ color: 'rgb(29, 78, 195)', fontFamily: 'SourceSansProSemiBold' }}>
                                                Join Meeting
                                            </Text>
                                        </View>
                                    </DangerButton> : !meetingAttendance?.clock_out ? <DangerButton style={{
                                        flex: 1,
                                        marginTop: 40,
                                    }} onPress={() => {
                                        const now = moment();
                                        const meetingDate = moment(meeting.date);
                                        const diff = meetingDate.diff(now, 'minutes');
                                        getLocationDetails();
                                        // if (diff > 60) {
                                        //     Alert.alert('Meeting not started yet', 'You can join the meeting 1 hour before the meeting');
                                        //     return;
                                        // }

                                        setFaceAuthModalVisible(true)
                                    }}>
                                        <View row center>
                                            <View marginR-5>
                                                <Icon name="log-out-outline" width={20} height={20} fill="rgb(29, 78, 195)" />
                                            </View>
                                            <Text style={{ color: 'rgb(29, 78, 195)', fontFamily: 'SourceSansProSemiBold' }}>
                                                Leave Meeting
                                            </Text>
                                        </View>
                                    </DangerButton> : null}
                                </View>

                                {!meetingAttendance?.clock_in ? <View row marginB-10>
                                    <DangerButton
                                        label={"Request Excuse"}
                                        style={{
                                            flex: 1,
                                            marginTop: 10,
                                        }} onPress={() => {
                                            setExcuseRequestModalVisible(true)
                                        }} />
                                </View> : null}

                                <View marginL-10>
                                    <View marginB-0 marginT-0>
                                        {Heading({ heading: 'My Notes' })}
                                    </View>
                                </View>

                                <View style={{
                                    minHeight: 130,
                                }}>
                                    <CustomEditorField
                                        value={meetingAttendance?.notes || ''}
                                        onChange={(value) => {
                                            // setMeetingAttendance({
                                            //     ...meetingAttendance,
                                            //     notes: value
                                            // });
                                            setMeetingAttendance((prevState) => {
                                                return {
                                                    ...prevState,
                                                    notes: value
                                                }
                                            });
                                        }}
                                    />
                                </View>

                                <DangerButton
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 50
                                    }} onPress={async () => {
                                        const response = await api.customRoute('MeetingAttendances/action', {
                                            action: 'saveNotes',
                                            meeting_id: meeting._id,
                                            employee_id: employee._id,
                                            notes: meetingAttendance?.notes
                                        });

                                        if (response) {
                                            Alert.alert('Success', 'Notes saved successfully');
                                            setShowMeetingModal(false);
                                        }
                                    }}>
                                    <View row center>
                                        <View marginR-5>
                                            <Icon name="save-outline" width={20} height={20} fill="rgb(29, 78, 195)" />
                                        </View>
                                        <Text style={{ fontFamily: 'SourceSansProSemiBold', color: 'rgb(29, 78, 195)' }}>
                                            Save
                                        </Text>
                                    </View>
                                </DangerButton>

                                <CustomModal
                                    title="Excuse Request"
                                    visible={excuseRequestModalVisible}
                                    onClose={() => {
                                        setExcuseRequestModalVisible(false);
                                    }}>
                                    <View padding-10 style={{
                                        flex: 1,
                                    }}>
                                        <View style={{
                                            borderBottomWidth: 1,
                                            borderBottomColor: '#e6e6e6',
                                            paddingBottom: 10,
                                            marginTop: 20,
                                        }}>
                                            <CustomTextAreaField
                                                label="Reason"
                                                value={excuse_reason}
                                                onChange={(value) => {
                                                    setExcuseReason(value)
                                                }}
                                            />
                                        </View>

                                        <View>
                                            <Text
                                                marginV-10
                                                style={{
                                                    flexWrap: 'wrap',
                                                    fontSize: 14,
                                                    lineHeight: 32,
                                                    letterSpacing: 0.4,
                                                    fontWeight: "500",
                                                    color: "#72849A",
                                                    fontFamily: 'SourceSansProSemiBold',
                                                }}>
                                                Files
                                            </Text>

                                            <CustomUpload
                                                mimeType={'*/*'}
                                                value={excuse_files}
                                                onChange={(value) => {
                                                    setExcuseFiles(value)
                                                }}
                                                multiple={true}
                                                moduleName="MeetingAttendances"
                                            />
                                        </View>

                                        <DangerButton
                                            label={"Submit"}
                                            style={{
                                                marginTop: 50,
                                            }} onPress={async () => {
                                                const response = await api.customRoute('MeetingAttendances/action', {
                                                    action: 'requestExcuse',
                                                    meeting_id: meeting._id,
                                                    employee_id: employee._id,
                                                    support_files: excuse_files,
                                                    excused_reason: excuse_reason
                                                });

                                                if (response) {
                                                    Alert.alert('Success', 'Excuse request sent successfully');
                                                    setExcuseRequestModalVisible(false);
                                                }
                                            }} />

                                    </View>
                                </CustomModal>

                                <CustomModal
                                    title={meetingAttendance?.clock_in ? "Leave Meeting" : "Join Meeting"}
                                    visible={faceAuthModalVisible}
                                    onClose={() => {
                                        setFaceAuthModalVisible(false);
                                    }}>
                                    <View style={{
                                        flex: 1,
                                    }}>
                                        <FaceAuth 
                                        employee={employee} 
                                        enable_face_auth={enable_face_auth} 
                                        face_auth_image_url={face_auth_image_url} 
                                        onAuthenticated={(photo) => {
                                            setFaceAuthModalVisible(false);

                                            const checkInCheckout = async () => {
                                                let actionName = '';

                                                if (!meetingAttendance?.clock_in) {
                                                    actionName = "joinMeeting";
                                                }

                                                if (meetingAttendance?.clock_in && !meetingAttendance?.clock_out) {
                                                    actionName = "leaveMeeting";
                                                }

                                                if (actionName == '') {
                                                    Alert.alert('Error', 'You have already joined the meeting');
                                                    return;
                                                }

                                                const response = await api.customRoute('MeetingAttendances/action', {
                                                    action: actionName,
                                                    meeting_id: meeting._id,
                                                    employee_id: employee._id,
                                                    latitude: location_address.latitude,
                                                    longitude: location_address.longitude,
                                                    location_address: location_address.location_address,
                                                    face_auth_image_data: photo,
                                                });


                                                if (response) {
                                                    if (actionName == "joinMeeting") {
                                                        Alert.alert('Success', 'You have successfully joined the meeting');
                                                    } else {
                                                        Alert.alert('Success', 'You have successfully left the meeting');
                                                    }
                                                    setFaceAuthModalVisible(false);
                                                    setMeetingAttendance(response.data);
                                                }
                                            };

                                            checkInCheckout();
                                        }} />
                                    </View>
                                </CustomModal>

                            </View>
                        </Card> : null}
                    </KeyboardAwareScrollView>
                </View>
            </CustomModal>

            <CustomModal
                visible={showCourseSessionModal}
                onClose={() => {
                    setShowCourseSessionModal(false);
                    setCourseSessionId(null);
                    setCourseSession({});
                }}
                title={(courseSession?.name) || "Loading..."}
                padding={0}
            >
                <View marginT-20 style={{
                    position: 'relative',
                }}>
                    {loading && <View style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: Dimensions.get('window').height,
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        zIndex: 9999999999,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <ActivityIndicator size="large" color="#29459C" />
                    </View>}
                    <KeyboardAwareScrollView
                        extraHeight={300}
                        enableOnAndroid={true}
                        keyboardShouldPersistTaps='handled'
                        contentContainerStyle={{
                            flexGrow: 1,
                            padding: 10
                        }}
                        style={{
                            height: Dimensions.get('window').height - 100
                        }}>
                        {courseSession?.name ? <Card padding-20 >
                            <View w-100>
                                <View row marginB-10>
                                    <View row>
                                        <DateIcon />
                                    </View>

                                    <View marginL-10>
                                        <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 20 }}>
                                            {moment(courseSession.date).format('DD MMM YYYY hh:mm A')} - {moment(courseSession.date).add(courseSession.duration, 'ms').format('hh:mm A')}
                                        </Text>
                                    </View>
                                </View>

                                <View style={{
                                    height: 1,
                                    backgroundColor: '#e6e6e6',
                                    width: '100%',
                                    marginTop: 10,
                                    marginBottom: 10
                                }} />

                                <View row marginB-10>
                                    <View row>
                                        <AgendaIcon />
                                    </View>

                                    <View marginL-10>
                                        <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 20 }}>Course Content</Text>
                                        {Platform.OS === 'web' ? <div dangerouslySetInnerHTML={{ __html: (courseSession.description) }} /> : <RenderHtml contentWidth={Dimensions.get('window').width - 120} source={{ html: (courseSession.description) }} />}
                                    </View>
                                </View>

                                <View style={{
                                    height: 1,
                                    backgroundColor: '#e6e6e6',
                                    width: '100%',
                                    marginTop: 10,
                                    marginBottom: 10
                                }} />

                                <View row marginB-10>
                                    <View row>
                                        <FilesIcon />
                                    </View>

                                    <View marginL-10>
                                        <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 18 }}>Files</Text>
                                        <View row marginT-5 style={{ flexWrap: 'wrap' }}>
                                            {courseSession.attachment_files?.length > 0 ? <CustomUpload
                                                mimeType={"image/*,application/pdf"}
                                                moduleName="Employees"
                                                multiple={true}
                                                value={courseSession.attachment_files || []}
                                                onChange={(value) => {

                                                }}
                                                showNaming={false}
                                                readOnly={true}
                                            /> : <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>No files attached</Text>}
                                        </View>
                                    </View>
                                </View>

                                <View style={{
                                    height: 1,
                                    backgroundColor: '#e6e6e6',
                                    width: '100%',
                                    marginTop: 10,
                                    marginBottom: 10
                                }} />

                                <View row marginB-10>
                                    {!courseSessionAttendance?.clock_in ? <DangerButton style={{
                                        flex: 1,
                                        marginTop: 40,
                                    }} onPress={() => {
                                        const now = moment();
                                        const courseSessionDate = moment(courseSession.date);
                                        const diff = courseSessionDate.diff(now, 'minutes');
                                        getLocationDetails();
                                        // if (diff > 60) {
                                        //     Alert.alert('Course session not started yet', 'You can join the session 1 hour before the session');
                                        //     return;
                                        // } 

                                        setFaceAuthModalVisible(true)
                                    }}>
                                        <View row center>
                                            <View marginR-5>
                                                <Icon name="log-in-outline" width={20} height={20} fill="rgb(29, 78, 195)" />
                                            </View>
                                            <Text style={{ color: 'rgb(29, 78, 195)', fontFamily: 'SourceSansProSemiBold' }}>
                                                Join Session
                                            </Text>
                                        </View>
                                    </DangerButton> : !courseSessionAttendance?.clock_out ? <DangerButton style={{
                                        flex: 1,
                                        marginTop: 40,
                                    }} onPress={() => {
                                        const now = moment();
                                        const courseSessionDate = moment(courseSession.date);
                                        const diff = courseSessionDate.diff(now, 'minutes');
                                        getLocationDetails();
                                        // if (diff > 60) {
                                        //     Alert.alert('Session not started yet', 'You can join the session 1 hour before the session');
                                        //     return;
                                        // }

                                        setFaceAuthModalVisible(true)
                                    }}>
                                        <View row center>
                                            <View marginR-5>
                                                <Icon name="log-out-outline" width={20} height={20} fill="rgb(29, 78, 195)" />
                                            </View>
                                            <Text style={{ color: 'rgb(29, 78, 195)', fontFamily: 'SourceSansProSemiBold' }}>
                                                Leave Session
                                            </Text>
                                        </View>
                                    </DangerButton> : null}
                                </View>

                                <View row marginB-10>
                                    {(courseSessionAttendance?.lesson_mcqs?.length != courseSession?.lesson_mcqs?.length && courseSession?.lesson_mcqs?.length > 0) ?
                                        <DangerButton style={{
                                            flex: 1,
                                            marginTop: 10,
                                        }} onPress={() => {
                                            setMCQModalVisible(true);
                                            if (courseSession?.lesson_mcqs?.length != courseSessionAttendance?.lesson_mcqs?.length) {
                                                setCourseSessionAttendance({
                                                    ...courseSessionAttendance,
                                                    lesson_mcqs: courseSession?.lesson_mcqs
                                                });
                                            }
                                        }}>
                                            <View row center>
                                                <View marginR-5>
                                                    <Icon name="checkmark-square-2-outline" width={20} height={20} fill="rgb(29, 78, 195)" />
                                                </View>
                                                <Text style={{ color: 'rgb(29, 78, 195)', fontFamily: 'SourceSansProSemiBold' }}>
                                                    Answer MCQs
                                                </Text>
                                            </View>
                                        </DangerButton> : null}
                                </View>

                                {!courseSessionAttendance?.clock_in ? <View row marginB-10>
                                    <DangerButton
                                        label={"Request Excuse"}
                                        style={{
                                            flex: 1,
                                            marginTop: 10,
                                        }} onPress={() => {
                                            setExcuseRequestModalVisible(true)
                                        }} />
                                </View> : null}

                                <View marginL-10>
                                    <View marginB-0 marginT-0>
                                        {Heading({ heading: 'My Notes' })}
                                    </View>
                                </View>

                                <View style={{
                                    minHeight: 130,
                                }}>
                                    <CustomEditorField
                                        value={courseSessionAttendance?.notes || ''}
                                        onChange={(value) => {
                                            setCourseSessionAttendance({
                                                ...courseSessionAttendance,
                                                notes: value
                                            });
                                        }}
                                    />
                                </View>

                                <DangerButton
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 50
                                    }} onPress={async () => {
                                        const response = await api.customRoute('MeetingAttendances/action', {
                                            action: 'saveNotes',
                                            course_session_id: courseSession._id,
                                            employee_id: employee._id,
                                            notes: courseSessionAttendance?.notes
                                        });

                                        if (response) {
                                            Alert.alert('Success', 'Notes saved successfully');
                                            setShowCourseSessionModal(false);
                                        }
                                    }}>
                                    <View row center>
                                        <View marginR-5>
                                            <Icon name="save-outline" width={20} height={20} fill="rgb(29, 78, 195)" />
                                        </View>
                                        <Text style={{ fontFamily: 'SourceSansProSemiBold', color: 'rgb(29, 78, 195)' }}>
                                            Save
                                        </Text>
                                    </View>
                                </DangerButton>

                                <CustomModal
                                    title="Excuse Request"
                                    visible={excuseRequestModalVisible}
                                    onClose={() => {
                                        setExcuseRequestModalVisible(false);
                                    }}>
                                    <View padding-10 style={{
                                        flex: 1,
                                    }}>
                                        <View style={{
                                            borderBottomWidth: 1,
                                            borderBottomColor: '#e6e6e6',
                                            paddingBottom: 10,
                                            marginTop: 20,
                                        }}>
                                            <CustomTextAreaField
                                                label="Reason"
                                                value={excuse_reason}
                                                onChange={(value) => {
                                                    setExcuseReason(value)
                                                }}
                                            />
                                        </View>

                                        <View>
                                            <Text
                                                marginV-10
                                                style={{
                                                    flexWrap: 'wrap',
                                                    fontSize: 14,
                                                    lineHeight: 32,
                                                    letterSpacing: 0.4,
                                                    fontWeight: "500",
                                                    color: "#72849A",
                                                    fontFamily: 'SourceSansProSemiBold',
                                                }}>
                                                Files
                                            </Text>

                                            <CustomUpload
                                                mimeType={'*/*'}
                                                value={excuse_files}
                                                onChange={(value) => {
                                                    setExcuseFiles(value)
                                                }}
                                                multiple={true}
                                                moduleName="CourseSessionAttendances"
                                            />
                                        </View>

                                        <DangerButton
                                            label={"Submit"}
                                            style={{
                                                marginTop: 50,
                                            }} onPress={async () => {
                                                const response = await api.customRoute('MeetingAttendances/action', {
                                                    action: 'requestExcuse',
                                                    course_session_id: courseSession._id,
                                                    employee_id: employee._id,
                                                    support_files: excuse_files,
                                                    excused_reason: excuse_reason
                                                });

                                                if (response) {
                                                    Alert.alert('Success', 'Excuse request sent successfully');
                                                    setExcuseRequestModalVisible(false);
                                                }
                                            }} />

                                    </View>
                                </CustomModal>

                                <CustomModal
                                    title={courseSessionAttendance?.clock_in ? "Leave Session" : "Join Session"}
                                    visible={faceAuthModalVisible}
                                    onClose={() => {
                                        setFaceAuthModalVisible(false);
                                    }}>
                                    <View style={{
                                        flex: 1,
                                    }}>
                                        <FaceAuth employee={employee} enable_face_auth={enable_face_auth} face_auth_image_url={face_auth_image_url} onAuthenticated={(photo) => {
                                            setFaceAuthModalVisible(false);

                                            const checkInCheckout = async () => {
                                                let actionName = '';

                                                if (!courseSessionAttendance?.clock_in) {
                                                    actionName = "joinMeeting";
                                                }

                                                if (courseSessionAttendance?.clock_in && !courseSessionAttendance?.clock_out) {
                                                    actionName = "leaveMeeting";
                                                }

                                                if (actionName == '') {
                                                    Alert.alert('Error', 'You have already joined the session!');
                                                    return;
                                                }

                                                setLoading(true);

                                                const response = await api.customRoute('MeetingAttendances/action', {
                                                    action: actionName,
                                                    course_session_id: courseSession._id,
                                                    employee_id: employee._id,
                                                    latitude: location_address.latitude,
                                                    longitude: location_address.longitude,
                                                    location_address: location_address.location_address,
                                                    face_auth_image_data: photo,
                                                });

                                                setLoading(false);

                                                if (response.success) {
                                                    if (actionName == "joinMeeting") {
                                                        Alert.alert('Success', 'You have successfully joined the session');
                                                    } else {
                                                        Alert.alert('Success', 'You have successfully left the session');
                                                    }
                                                    setFaceAuthModalVisible(false);
                                                    setCourseSessionAttendance(response.data);
                                                } else {
                                                    Alert.alert('Error', (response.message || response.msg || 'Something went wrong'));
                                                }
                                            };

                                            checkInCheckout();
                                        }} />
                                    </View>
                                </CustomModal>

                                <CustomModal
                                    title="MCQs"
                                    visible={mcqModalVisible}
                                    onClose={() => {
                                        setMCQModalVisible(false);
                                        const newCourseSessionAttendance = { ...courseSessionAttendance };
                                        newCourseSessionAttendance.lesson_mcqs = [];
                                        setCourseSessionAttendance(newCourseSessionAttendance);
                                    }}>
                                    <View padding-10 style={{
                                        flex: 1,
                                    }}>
                                        {courseSession?.lesson_mcqs ? <View>
                                            {courseSession?.lesson_mcqs?.map((mcq, i) => {
                                                return <View key={i} style={{
                                                    borderBottomWidth: 1,
                                                    borderBottomColor: '#e6e6e6',
                                                    paddingBottom: 10,
                                                    marginTop: 20,
                                                }}>
                                                    <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 20 }}>{mcq.question}</Text>
                                                    <View marginT-10>
                                                        <RadioGroup
                                                            initialValue={mcq?.selected_answer}
                                                            onValueChange={(value) => {
                                                                const newCourseSessionAttendance = { ...courseSessionAttendance };
                                                                newCourseSessionAttendance.lesson_mcqs[i].selected_answer = value;
                                                                setCourseSessionAttendance(newCourseSessionAttendance);
                                                            }}>
                                                            {mcq.options?.map((option, j) => {
                                                                return <RadioButton key={j} label={option.option} value={option.option} style={{ marginVertical: 5 }} />
                                                            })}
                                                        </RadioGroup>
                                                    </View>
                                                </View>
                                            })}
                                        </View> : null}

                                        <DangerButton
                                            label={"Submit"}
                                            style={{
                                                marginTop: 50,
                                            }} onPress={async () => {
                                                const response = await api.customRoute('MeetingAttendances/action', {
                                                    action: 'submitMCQs',
                                                    course_session_id: courseSession._id,
                                                    employee_id: employee._id,
                                                    lesson_mcqs: courseSessionAttendance.lesson_mcqs
                                                });

                                                if (response) {
                                                    Alert.alert('Success', 'MCQs submitted successfully');
                                                    setMCQModalVisible(false);
                                                }
                                            }} />
                                    </View>
                                </CustomModal>
                            </View>
                        </Card> : null}
                    </KeyboardAwareScrollView>
                </View>
            </CustomModal>
        </View>
    )
})

export default EventsScreen