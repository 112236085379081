// name: { type: String, required: true },
// description: { type: String, required: false },
// employee_ids: [{ type: Types.ObjectId, default: null, ref: 'Employees' }],
// employees: [{ type: mongoose.Schema.Types.Mixed, default: {} }],
// frequency: { type: String },

import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const courseGroupsfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter Name',
            visible: true,
            width: '100%',
        },
        {
            label: 'Description',
            key: 'description',
            type: 'textarea',
            placeholder: 'Enter Description',
            visible: true,
            width: '100%',
        },
        {
            label: 'Frequency',
            key: 'frequency',
            type: 'select',
            placeholder: 'Select Frequency',
            visible: true,
            width: '100%',
            options: [
                { label: 'Daily', value: 'Daily' },
                { label: 'Weekly', value: 'Weekly' },
                { label: 'Monthly', value: 'Monthly' },
                { label: 'Quarterly', value: 'Quarterly' },
                { label: 'Every 4 Months', value: 'Every 4 Months' },
                { label: 'Yearly', value: 'Yearly' },
            ],
        },
        // {
        //     label: "Employee",
        //     key: 'employee_ids',
        //     type: 'select',
        //     placeholder: 'Select Employee',
        //     visible: true,
        //     width: '100%',
        //     multiple: true,
        //     options: response.Employees ? response.Employees.map((item) => {
        //         return { label: item.name, value: item._id }
        //     }) : [],
        // },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const courseGroupsMain = {
    fields: courseGroupsfields,
    mobileCard: null
}