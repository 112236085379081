// title: { type: String, required: true },
// announcement_type_id: { type: Types.ObjectId, default: null, ref: 'AnnouncementTypes' }, 
// announcement_type: { type: mongoose.Schema.Types.Mixed, default: {} }, 
// description: { type: String, required: false }, 
// publish_date: { type: Date, required: false }, 
// expiry_date: { type: Date, required: false }, 
// share_announcement_via: { type: String, enum: ['Email', 'Notification', 'Both'], default: 'Both' }, 
// send_to: { type: String, enum: ['Employees', 'Lead'], default: 'Employees' },

// employees: [{ type: mongoose.Schema.Types.ObjectId, required: false }],
// leads: [{ type: mongoose.Schema.Types.ObjectId, required: false }],

import React, { useEffect } from "react";
import { View, Card, Text, Image, TouchableOpacity } from "react-native-ui-lib";
import ListingScreen from "../../../screens/MainScreens/CRUD/ListingScreen";
import moment from "moment";
import Svg, { Ellipse, G, Path, Defs, ClipPath } from "react-native-svg"
import RenderHtml from 'react-native-render-html';
import { Dimensions, Platform } from "react-native";
import { CustomCheckbox, CustomNumberInput, CustomTextField, CustomUpload, DangerButton, SecondaryButton } from "../../../mycomponents/DynamicForm";

const DateIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={38}
        height={29}
        fill="none"
        {...props}
    >
        <Ellipse cx={15.09} cy={14.5} fill="#FF9D9D" rx={15.09} ry={14.5} />
        <Path
            fill="#464F60"
            d="M14.78 27h18.3c2.884 0 4.386-1.438 4.386-4.186V7.186C37.466 4.438 35.964 3 33.08 3h-18.3c-2.871 0-4.373 1.425-4.373 4.186v15.628c0 2.748 1.502 4.186 4.373 4.186Zm-.014-2.555c-1.103 0-1.714-.553-1.714-1.683V10.95c0-1.13.611-1.683 1.714-1.683h18.327c1.103 0 1.715.553 1.715 1.683v11.813c0 1.13-.611 1.683-1.715 1.683H14.766Zm6.606-10.748h.784c.478 0 .638-.142.638-.591v-.758c0-.462-.16-.603-.638-.603h-.784c-.479 0-.638.141-.638.603v.758c0 .45.16.59.638.59Zm4.345 0h.785c.465 0 .624-.142.624-.591v-.758c0-.462-.16-.603-.625-.603h-.784c-.478 0-.637.141-.637.603v.758c0 .45.159.59.637.59Zm4.333 0h.784c.479 0 .638-.142.638-.591v-.758c0-.462-.16-.603-.638-.603h-.784c-.465 0-.625.141-.625.603v.758c0 .45.16.59.625.59ZM17.039 17.83h.77c.48 0 .639-.14.639-.603v-.758c0-.45-.16-.59-.638-.59h-.771c-.479 0-.638.14-.638.59v.758c0 .462.16.603.638.603Zm4.333 0h.784c.478 0 .638-.14.638-.603v-.758c0-.45-.16-.59-.638-.59h-.784c-.479 0-.638.14-.638.59v.758c0 .462.16.603.638.603Zm4.345 0h.785c.465 0 .624-.14.624-.603v-.758c0-.45-.16-.59-.625-.59h-.784c-.478 0-.637.14-.637.59v.758c0 .462.159.603.637.603Zm4.333 0h.784c.479 0 .638-.14.638-.603v-.758c0-.45-.16-.59-.638-.59h-.784c-.465 0-.625.14-.625.59v.758c0 .462.16.603.625.603Zm-13.011 4.122h.77c.48 0 .639-.14.639-.59v-.758c0-.462-.16-.603-.638-.603h-.771c-.479 0-.638.14-.638.603v.758c0 .45.16.59.638.59Zm4.333 0h.784c.478 0 .638-.14.638-.59v-.758c0-.462-.16-.603-.638-.603h-.784c-.479 0-.638.14-.638.603v.758c0 .45.16.59.638.59Zm4.345 0h.785c.465 0 .624-.14.624-.59v-.758c0-.462-.16-.603-.625-.603h-.784c-.478 0-.637.14-.637.603v.758c0 .45.159.59.637.59Z"
        />
    </Svg>
)

const AgendaIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={38}
        height={29}
        fill="none"
        {...props}
    >
        <Ellipse cx={15.09} cy={14.5} fill="#FFE99D" rx={15.09} ry={14.5} />
        <Path
            fill="#464F60"
            d="M33.654 5.25h-.904V4.5a.75.75 0 1 0-1.5 0v.75h-4.5V4.5a.75.75 0 1 0-1.5 0v.75h-4.5V4.5a.75.75 0 1 0-1.5 0v.75h-.904A2.85 2.85 0 0 0 15.5 8.095v15.31a2.85 2.85 0 0 0 2.846 2.845h15.308a2.85 2.85 0 0 0 2.846-2.846V8.095a2.85 2.85 0 0 0-2.846-2.845ZM24.28 18.53l-2.25 2.25a.75.75 0 0 1-1.06 0l-1.5-1.5a.75.75 0 1 1 1.06-1.06l.971.97 1.72-1.72a.75.75 0 0 1 1.06 1.06Zm0-6.75-2.25 2.25a.75.75 0 0 1-1.06 0l-1.5-1.5a.75.75 0 1 1 1.06-1.06l.971.97 1.72-1.72a.75.75 0 0 1 1.06 1.06ZM32 20.25h-5.25a.75.75 0 1 1 0-1.5H32a.75.75 0 1 1 0 1.5Zm0-6.75h-5.25a.75.75 0 1 1 0-1.5H32a.75.75 0 1 1 0 1.5Z"
        />
    </Svg>
)

const InviteIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={34}
        height={29}
        fill="none"
        {...props}
    >
        <Ellipse cx={15.09} cy={14.5} fill="#9DE2FF" rx={15.09} ry={14.5} />
        <G fill="#464F60" clipPath="url(#a)">
            <Path d="M31.18 8.066v2.995l1.901-1.413-1.901-1.582ZM23.156 1.42a1.813 1.813 0 0 0-2.312-.006l-.767.652h3.858l-.779-.646Z" />
            <Path d="M23.802 18.053a2.96 2.96 0 0 1-1.793.592c-.646 0-1.28-.206-1.8-.592l-9.616-7.178v12.31a1.806 1.806 0 0 0 1.793 1.817h19.228a1.806 1.806 0 0 0 1.793-1.818V10.875l-9.605 7.178Z" />
            <Path d="m10.925 9.648 1.841 1.37V8.122l-1.841 1.527ZM13.974 3.273v8.645l6.942 5.168c.646.47 1.534.47 2.18 0l6.876-5.125V3.273H13.974Zm4.286 4.83h3.26c.332 0 .604.272.604.604a.605.605 0 0 1-.604.603h-3.26a.605.605 0 0 1-.604-.603c0-.332.272-.604.604-.604Zm7.486 4.226H18.26a.606.606 0 0 1-.604-.604c0-.332.272-.604.604-.604h7.486c.332 0 .603.272.603.604a.605.605 0 0 1-.603.604Z" />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M10 1h24v24H10z" />
            </ClipPath>
        </Defs>
    </Svg>
)

const FilesIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={36}
        height={29}
        fill="none"
        {...props}
    >
        <Ellipse cx={15.09} cy={14.5} fill="#E0FF9D" rx={15.09} ry={14.5} />
        <Path
            fill="#464F60"
            d="M27.469 10.031c-.905 0-1.64-.736-1.64-1.64V3h-8.392a2.581 2.581 0 0 0-2.578 2.578v18.844A2.581 2.581 0 0 0 17.438 27h13.125a2.581 2.581 0 0 0 2.578-2.578v-14.39h-5.672Zm-8.766 9.844h3.409a.703.703 0 0 1 0 1.406h-3.409a.703.703 0 0 1 0-1.406ZM18 16.828c0-.388.315-.703.703-.703h10.313a.703.703 0 1 1 0 1.406H18.703a.703.703 0 0 1-.703-.703Zm11.016-4.453a.703.703 0 1 1 0 1.406H18.703a.703.703 0 0 1 0-1.406h10.313Z"
        />
        <Path
            fill="#464F60"
            d="M27.234 8.388c0 .13.105.234.235.234h5.358a2.571 2.571 0 0 0-.493-.643l-4.52-4.276a2.585 2.585 0 0 0-.58-.414v5.1Z"
        />
    </Svg>
)

export const announcementfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const OverviewComponent = ({ field, useForm, tKey, tIndex }) => {
        const publish_date = useForm((state) => state.formObject['publish_date']);
        const expiry_date = useForm((state) => state.formObject['expiry_date']);
        const duration = useForm((state) => state.formObject['duration']);
        const description = useForm((state) => state.formObject['description']);
        const employees = useForm((state) => state.formObject['employees']);
        const leads = useForm((state) => state.formObject['leads']);
        const send_to = useForm((state) => state.formObject['send_to']);
        const attachment_files = useForm((state) => state.formObject['attachment_files']) || [];

        return <Card padding-20 flex>
            <View w-100 flex>
                <View row marginB-10>
                    <View row>
                        <DateIcon />
                    </View>

                    <View marginL-10>

                        <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 20 }}>
                            {moment(publish_date).format('DD MMM YYYY')} - {expiry_date ? moment(expiry_date).format('DD MMM YYYY') : ''}
                        </Text>
                    </View>
                </View>

                <View style={{
                    height: 1,
                    backgroundColor: '#e6e6e6',
                    width: '100%',
                    marginTop: 10,
                    marginBottom: 10
                }} />

                <View row marginB-10>
                    <View row>
                        <AgendaIcon />
                    </View>

                    <View marginL-10>
                        <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 20 }}>Description</Text>
                        {Platform.OS === 'web' ? <div dangerouslySetInnerHTML={{ __html: description }} /> : <RenderHtml contentWidth={Dimensions.get('window').width - 120} source={{ html: (description || "") }} />}
                    </View>
                </View>

                <View style={{
                    height: 1,
                    backgroundColor: '#e6e6e6',
                    width: '100%',
                    marginTop: 10,
                    marginBottom: 10
                }} />

                <View row marginB-10>
                    <View row>
                        <FilesIcon />
                    </View>

                    <View marginL-10>
                        <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 18 }}>Files</Text>
                        <View row marginT-5 style={{ flexWrap: 'wrap' }}>
                            {attachment_files?.length > 0 ? <CustomUpload
                                moduleName="Employees"
                                multiple={true}
                                value={attachment_files || []}
                                onChange={(value) => {

                                }}
                                showNaming={false}
                                readOnly={true}
                            /> : <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>No files attached</Text>}
                        </View>
                    </View>
                </View>

                {Platform.OS == 'web' && <View style={{
                    height: 1,
                    backgroundColor: '#e6e6e6',
                    width: '100%',
                    marginTop: 10,
                    marginBottom: 10
                }} />}

                {Platform.OS == 'web' && <View row marginB-10>
                    <View row>
                        <InviteIcon />
                    </View>

                    <View marginL-10>
                        <Text style={{ fontFamily: 'SourceSansProBold', fontSize: 20 }}>Send To - {send_to}</Text>
                        <View row marginT-5 style={{ flexWrap: 'wrap' }}>
                            {send_to == 'Employees' && employees?.length > 0 ? employees.map((item, i) => {
                                return <View key={i} row>
                                    <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>{item.name}</Text>
                                    {i !== employees.length - 1 ? <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>, </Text> : null}
                                </View>
                            }) : null}

                            {send_to == 'Lead' && leads?.length > 0 ? leads.map((item, i) => {
                                return <View key={i} row>
                                    <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>{item.name}</Text>
                                    {i !== leads.length - 1 ? <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>, </Text> : null}
                                </View>
                            }) : null}
                        </View>
                    </View>
                </View>}

                {/* <View style={{
                    height: 1,
                    backgroundColor: '#e6e6e6',
                    width: '100%',
                    marginTop: 10,
                    marginBottom: 10
                }} /> */}
            </View>
        </Card>
    };

    const formFields = [
        {
            label: '',
            type: "custom",
            visible: true,
            width: '100%',
            tab: "Overview",
            component: OverviewComponent,
        },
        {
            label: 'Title',
            key: 'title',
            type: 'text',
            placeholder: 'Enter Title',
            visible: true,
            width: '100%',
            required: true,
            tab: "General",
        },
        {
            label: 'Announcement Type',
            key: 'announcement_type_id',
            type: 'select',
            placeholder: 'Select Announcement Type',
            visible: true,
            width: '100%',
            required: true,
            options: response.AnnouncementTypes ? response.AnnouncementTypes.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };

                OpenModalCustom({ newModule: "AnnouncementTypes", field_key: 'announcement_type_id', params })
            },
            tab: "General",
        },
        {
            label: 'Description',
            key: 'description',
            type: 'editor',
            placeholder: 'Enter Description',
            visible: true,
            width: '100%',
            tab: "General",
        },
        {
            label: 'Publish Date',
            key: 'publish_date',
            type: 'date',
            placeholder: 'Enter Publish Date',
            visible: true,
            width: '100%',
            required: true,
            tab: "General",
        },
        {
            label: 'Expiry Date',
            key: 'expiry_date',
            type: 'date',
            placeholder: 'Enter Expiry Date',
            visible: true,
            width: '100%',
            required: true,
            tab: "General",
        },
        {
            label: 'Attachment Files',
            key: 'attachment_files',
            type: 'upload',
            visible: true,
            width: '100%',
            mimeType: '*/*',
            moduleName: moduleName,
            multiple: true,
            tab: 'General',
        },
        {
            label: 'Share Announcement Via',
            key: 'share_announcement_via',
            type: 'select',
            placeholder: 'Select Share Announcement Via',
            visible: true,
            width: '100%',
            required: true,
            options: [
                { label: 'Email', value: 'Email' },
                { label: 'Notification', value: 'Notification' },
                { label: 'Both', value: 'Both' },
            ],
            tab: "General",
        },
        {
            label: 'Send To',
            key: 'send_to',
            type: 'select',
            placeholder: 'Select Send To',
            visible: true,
            width: '100%',
            required: true,
            options: [
                { label: 'Agents', value: 'Employees' },
                { label: 'Lead', value: 'Lead' },
            ],
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                setObj({
                    updateVisiblity: Math.random()
                })
            },
            tab: "General",
        },
        {
            label: 'Agents',
            key: 'employee_ids',
            type: 'select',
            placeholder: 'Select Agents',
            visible: (connectedObject) => connectedObject.send_to == 'Employees',
            width: '100%',
            required: true,
            multiple: true,
            options: response.Employees ? response.Employees.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
            tab: "General",
            group_by: "custom_grouping",
        },
        {
            label: 'Leads',
            key: 'lead_ids',
            type: 'select',
            placeholder: 'Select Leads',
            visible: (connectedObject) => connectedObject.send_to == 'Lead',
            width: '100%',
            required: true,
            multiple: true,
            options: response.Leads ? response.Leads.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
            tab: "General",
        },
    ];

    if (view) {
        // remove all "General" tab fields
        return formFields.filter((item) => item.tab !== "General");
    } else {
        return formFields.filter((item) => item.tab !== "Overview");
    }

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '100%'
            });
        });
    }

    return formFields;
}

export const CustomViewHeader = (props) => {
    // padding: 15px 15px 10px;
    // margin: 10px 10px 0px;
    // background-color: rgb(244, 247, 249);
    // border-radius: 10px 10px 0px 0px;

    return <View padding-15 margin-10 marginB-0 paddingB-10 backgroundColor="rgb(244, 247, 249)" style={{ borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0, position: 'relative', height: 130 }}>
        <View spread row top>
            <View row >
                <View marginT-10>
                    <Text style={{
                        fontFamily: 'SourceSansProBold',
                        fontSize: 22
                    }}>{props?.connectedObject?.title}</Text>

                    <View>
                        <Text marginV-5 style={{
                            fontStyle: 'italic',
                            color: "#727171",
                        }}>{props?.connectedObject?.announcement_type?.name}</Text>
                    </View>


                    <View row centerV>
                        <View style={{
                            backgroundColor: moment(props?.connectedObject?.expiry_date).isBefore(moment()) ? '#cd1414' : 'green',
                            padding: 5,
                            paddingHorizontal: 20,
                            borderRadius: 5,
                            marginTop: 5,
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: 10
                        }}>
                            <Text white>{moment(props?.connectedObject?.expiry_date).isBefore(moment()) ? 'Expired' : 'Active'}</Text>
                        </View>

                        {/* <View row>
                            <Text darkCardTitle>Created At - </Text>
                            <Text darkCardTitle>{moment(props?.connectedObject?.createdAt).format('DD MMM YYYY hh:mm A')}</Text>
                        </View> */}
                    </View>
                </View>
            </View>

            {props.HeaderExtra}
        </View>
    </View>;
}

export const announcementMobileCard = ({ item, index, getFieldValue, onRowClick, valueText, columns, DeleteCard, actionFunctions, SidebarStatus }) => {
    // ignore columns 
    const ignoreColumns = ["expiry_date", "createdAt"]
    const newColumns = columns.filter((item) => !ignoreColumns.includes(item.field));

    return <Card flex style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: 10 }}>
        <TouchableOpacity padding-10 flex onPress={onRowClick ? () => {
            onRowClick && onRowClick(item);
        } : null}>

            <View marginB-10 row spread style={{ width: '100%' }}>
                <View center backgroundColor="#9ec1e9" style={{ width: 60, height: 60, borderRadius: 10 }}>
                    <Text style={{ fontSize: 24, color: 'white' }}>{moment(item.publish_date).format('DD')}</Text>
                    <Text style={{ fontSize: 12, color: 'white' }}>{moment(item.publish_date).format('ddd')}</Text>
                </View>

                <View flex marginL-10>
                    <View row>
                        <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                            {newColumns[0] ? getFieldValue(item, newColumns[0].field, newColumns[0]) : ""}
                        </Text>
                        <View marginL-10>
                            {item.approvalStatus == 'Pending' && <Text style={{ color: '#ffc93a' }}>(Pending)</Text>}
                            {item.approvalStatus == 'Rejected' && <Text style={{ color: '#cc1010' }}>(Rejected)</Text>}
                        </View>
                    </View>

                    <View>
                        <View flex>
                            {newColumns.length > 2 ? <View row marginB-6 flex>
                                <View>
                                    <Text lightCardTitle>{newColumns[1] ? newColumns[1].label : ""}: </Text>
                                </View>
                                <View flex>
                                    {valueText(newColumns[1])}
                                </View>
                            </View> : null}
                            {newColumns.length > 3 ? <View row marginB-6 flex>
                                <View>
                                    <Text lightCardTitle>{newColumns[2] ? newColumns[2].label : ""}: </Text>
                                </View>
                                <View flex>
                                    {valueText(newColumns[2])}
                                </View>
                            </View> : null}
                        </View>
                    </View>
                </View>
            </View>

            {newColumns.length > 4 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
            {newColumns.length > 4 ? <View style={{ width: '100%' }}>
                {newColumns.length > 4 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{newColumns[3] ? newColumns[3].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(newColumns[3])}
                    </View>
                </View> : null}
                {newColumns.length > 5 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{newColumns[4] ? newColumns[4].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(newColumns[4])}
                    </View>
                </View> : null}
            </View> : null}

            {newColumns.length > 6 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
            {newColumns.length > 6 ? <View style={{ width: '100%' }}>
                {newColumns.length > 6 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{newColumns[5] ? newColumns[5].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(newColumns[5])}
                    </View>
                </View> : null}
                {newColumns.length > 7 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{newColumns[6] ? newColumns[6].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(newColumns[6])}
                    </View>
                </View> : null}
            </View> : null}

            {newColumns.length > 8 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
            {newColumns.length > 8 ? <View row spread style={{ width: '100%' }}>
                {newColumns.length > 8 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{newColumns[7] ? newColumns[7].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(newColumns[7])}
                    </View>
                </View> : null}
                {newColumns.length > 9 ? <View row flex>
                    <View>
                        <Text lightCardTitle>{newColumns[8] ? newColumns[8].label : ""}: </Text>
                    </View>
                    <View flex>
                        {valueText(newColumns[8])}
                    </View>
                </View> : null}
            </View> : null}

        </TouchableOpacity>
        {/* {(actionFunctions && actionFunctions(item)?.length > 0) ? <TouchableOpacity>
            <SidebarStatus color={"#397CF2"}
                text={item.status}
                actionFunctions={actionFunctions} item={item} radius={10} />
        </TouchableOpacity> : null} */}
    </Card>;
}

export const announcementMain = {
    fields: announcementfields,
    mobileCard: announcementMobileCard,
    CustomViewHeader: CustomViewHeader,
}