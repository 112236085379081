import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const salefields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Policy',
            key: 'policy_id',
            type: 'select',
            placeholder: 'Select Policy',
            visible: true,
            width: '100%',
            required: true,
            options: response.Policies ? response.Policies.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };

                OpenModalCustom({ newModule: "Policies", field_key: 'policy_id', params })
            },
        },
        {
            label: 'Lead',
            key: 'lead_id',
            type: 'select',
            placeholder: 'Select Lead',
            visible: false,
            width: '100%',
            required: true,
            options: response.Leads ? response.Leads.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
        },
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter Date',
            visible: true,
            width: '100%',
            required: true,
        },
        {
            label: 'Policy Number',
            key: 'policy_number',
            type: 'text',
            placeholder: 'Enter Policy Number',
            visible: true,
            width: '100%',
            required: true,
        },
        {
            label: 'Amount',
            key: 'amount',
            type: 'number',
            placeholder: 'Enter Amount',
            visible: true,
            width: '100%',
            required: true,
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const saleMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const saleMain = {
    fields: salefields,
    mobileCard: null
}