// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, DeviceEventEmitter, TouchableOpacity } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, ExpandableSection } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomDatePicker, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, CustomTextFieldWithScanner, DangerButton, Heading, PrimaryButton, Row, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../CRUD/LayoutScreen"
import FormScreen from "../../../CRUD/FormScreen"
import { Icon } from "react-native-eva-icons"
import { CustomSelectItemForContacts, TransactionPDFPreview, invoiceItemAdjustment } from "../../../../../models/accountancy/transaction"
import { create } from 'zustand'
import { FlashList } from "@shopify/flash-list";
import { GlobalModalStore } from "../../../../../models/ModalStore"
import { App } from 'antd';
import * as Sharing from 'expo-sharing';
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import CalendarStrip from 'react-native-calendar-strip';
import moment from "moment"
import Svg, { Circle, Path, Rect } from "react-native-svg"
import { SalesActivityExpandable } from "../../../../../models/taqwa/Employee/SalesActivities"
import RenderHTML from "react-native-render-html"
import { GlobalLoaderStore } from "../../../../../models/GlobalLoaderStore"

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}))

export const ErrorMessage = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={202}
        height={135}
        fill="none"
        {...props}
    >
        <Circle cx={100} cy={74} r={61} fill="#F1F2F8" />
        <Path
            fill="#fff"
            d="M53.802 108.728v-48.97l13.591-25.145a2 2 0 0 1 1.76-1.048H129.8c.72 0 1.384.386 1.739 1.012l14.292 25.18v48.971a2 2 0 0 1-2 2h-88.03a2 2 0 0 1-2-2Z"
        />
        <Path
            stroke="#D9DBE8"
            d="M53.802 59.758v48.97a2 2 0 0 0 2 2h88.03a2 2 0 0 0 2-2v-48.97m-92.03 0h25.562a2 2 0 0 1 1.849 1.238l3.17 7.7a5 5 0 0 0 4.624 3.096h21.795c1.937 0 3.7-1.118 4.524-2.87l3.772-8.016a2 2 0 0 1 1.81-1.148h24.924m-92.03 0 13.591-25.145a2 2 0 0 1 1.76-1.048H129.8c.72 0 1.384.386 1.739 1.012l14.292 25.18"
        />
        <Rect
            width={12.743}
            height={4.248}
            x={93.445}
            y={97.277}
            fill="#D9DBE8"
            rx={2.124}
        />
        <Rect
            width={5.663}
            height={7.079}
            x={83.535}
            y={80.287}
            fill="#D9DBE8"
            rx={2.832}
        />
        <Rect
            width={5.663}
            height={7.079}
            x={110.436}
            y={80.287}
            fill="#D9DBE8"
            rx={2.832}
        />
        <Path
            fill="#fff"
            stroke="#D9DBE8"
            d="M109.936 18.698c0 6.505 4.174 12.15 10.409 15.363l-.387 6.192c-.068 1.09 1.231 1.703 2.03.958l5.158-4.814a27.5 27.5 0 0 0 5.235.499c12.291 0 22.446-8.052 22.446-18.198S144.672.5 132.381.5c-12.29 0-22.445 8.052-22.445 18.198Z"
        />
        <Path
            fill="#fff"
            d="M154.327 18.698c0 9.774-9.826 17.698-21.946 17.698-12.12 0-21.945-7.924-21.945-17.698C110.436 8.924 120.261 1 132.381 1c12.12 0 21.946 7.924 21.946 17.698Z"
        />
        <Path
            fill="#fff"
            d="m120.457 40.284.55-8.786a.707.707 0 0 1 .806-.657l7.688 1.098a.708.708 0 0 1 .383 1.219l-8.237 7.688a.708.708 0 0 1-1.19-.562Z"
        />
        <Path
            fill="#D9DBE8"
            d="m129.035 9.36-4.552.317a.5.5 0 0 0-.458.581l2.284 13.74a.5.5 0 0 0 .528.418l1.918-.134a.5.5 0 0 0 .465-.487l.35-13.924a.5.5 0 0 0-.535-.512ZM141.114 10.533l-4.469-.926a.5.5 0 0 0-.598.435l-1.522 13.845a.5.5 0 0 0 .396.545l1.882.39a.5.5 0 0 0 .579-.342l4.108-13.31a.5.5 0 0 0-.376-.637ZM126.645 28.186l-.128-1.827a.5.5 0 0 1 .464-.534l1.827-.128a.5.5 0 0 1 .534.464l.128 1.827a.5.5 0 0 1-.464.534l-1.827.128a.5.5 0 0 1-.534-.464ZM133.715 28.009l.371-1.794a.5.5 0 0 1 .591-.388l1.794.372a.5.5 0 0 1 .388.591l-.372 1.794a.5.5 0 0 1-.591.388l-1.793-.372a.499.499 0 0 1-.388-.591Z"
        />
        <Circle cx={25.485} cy={110.02} r={8.495} fill="#F1F2F8" />
        <Circle
            cx={179.074}
            cy={41.322}
            r={8.495}
            fill="#F1F2F8"
            transform="rotate(132.827 179.074 41.322)"
        />
        <Circle cx={4.248} cy={40.644} r={4.248} fill="#F1F2F8" />
        <Circle cx={12.743} cy={125.594} r={4.248} fill="#F1F2F8" />
        <Circle
            cx={195.892}
            cy={35.675}
            r={4.248}
            fill="#F1F2F8"
            transform="rotate(132.827 195.892 35.675)"
        />
    </Svg>
)

const SalesActivityScreen = observer(function SalesActivityScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();

    let queryParams = { ...(props?.route?.params || {}) };

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };

    const goBack = () => {
        if (Platform.OS === 'web') {
            window.history.back();
        } else {
            navigation.goBack();
        }
    }

    const moduleName = "SalesActivities";
    const { fields, dataTabs, preferencesFields, ExtraSaveComponent } = api.getForm(moduleName);
    const params = {};

    const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
    const globalData = GlobalMasterStore((state) => state.globalData);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);

    const [date, setDate] = useState(queryParams.date ? new Date(queryParams.date) : new Date());
    const [viewOnly, setViewOnly] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [id, setId] = useState(null);
    const [data, setData] = useState(null);

    const [employees, setEmployees] = useState([]);
    const [groups, setGroups] = useState([]);
    const [currentEmployee, setCurrentEmployee] = useState(queryParams.employee_id ? queryParams.employee_id : globalData.employee_id);
    const [currentGroup, setCurrentGroup] = useState(null);
    const setLoading = GlobalLoaderStore((state) => state.setLoading);

    const OpenModalCustom = ({ newModule, field_key, array = false, params = null }) => {
        if (Platform.OS == 'web') {
            let data = {
                [newModule]: {
                    isVisible: true,
                    moduleName: [newModule],
                    viewOnly: false,
                    afterSaveEvent: 'reloadData' + "SalesActivity",
                    ignoreQueryParams: true,
                    ...(params ? params : {})
                }
            };

            setOpenModal(data)
        } else {
            navigationFn('New', {
                moduleName: newModule,
                afterSaveEvent: 'reloadData' + "SalesActivity",
                ...(params ? params : {})
            })
        }
    }

    const newModule = (extraParams) => {
        if (Platform.OS === 'web') {
            const query = {}
            const params = window.location.search.substring(1).split('&')
            params.map((param) => {
                const [key, value] = param.split('=')
                if (key) {
                    query[key] = value;
                }
            })

            setOpenModal({
                ["SalesActivities"]: {
                    isVisible: true,
                    moduleName: "SalesActivities",
                    afterSaveEvent: 'reloadData' + "SalesActivity",
                    ...(extraParams || {})
                }
            })
        } else {
            navigationFn('New', {
                moduleName: "SalesActivities",
                afterSaveEvent: 'reloadData' + "SalesActivity",
                goBackAfterSave: true,
                ...(extraParams || {})
            })
        }
    }

    useEffect(() => {
        const getData = async () => {
            const responseData = await api.get("SalesActivities", {
                page: 1,
                limit: 1,
                sortColumn: null,
                sortDirection: null,
                filters: [],
                tabFilter: { employee_id: currentEmployee, date: { $gte: moment(date).startOf('month').toDate(), $lte: moment(date).endOf('month').toDate() } },
                search: null,
                groupBy: null
            });

            const data = responseData?.data?.data?.[0] ?? null;

            console.log("DATA", data);

            if (data) {
                setId(data._id);
                setData(data);
            } else {
                setId(null);
            }
        };
        if (currentEmployee) {
            getData();
        }
    }, [date, currentEmployee]);

    const getCreateData = async () => {
        setLoading(true);
        const response = await api.getCreateData("DailyActivities", params);
        const employees = response?.Employees ?? [];
        const groups = response?.Groups ?? [];
        setEmployees(employees);
        setGroups(groups);
        setLoading(false);
    }

    useEffect(() => {
        getCreateData();

        DeviceEventEmitter.addListener("reloadData" + "SalesActivity", (event) => {
            if (Platform.OS == 'web') {
                setOpenModal({
                    [newModule]: null
                })
            }

            const oldDate = date;
            setDate(null);
            setTimeout(() => {
                setDate(oldDate);
            }, 100);
        })

        return () => {
            DeviceEventEmitter.removeAllListeners("reloadData" + "SalesActivity");
        }
    }, []);

    return (
        <View style={{
            flex: 1,
            //backgroundColor: 'white'
        }}>
            <View style={{
                flex: 1
            }}>
                <View>
                    {/* <CalendarStrip
                        calendarAnimation={{ type: 'sequence', duration: 30 }}
                        daySelectionAnimation={{ type: 'background', duration: 200, highlightColor: '#ffffff6b' }}
                        style={{ height: 90, paddingTop: 10 }}
                        calendarHeaderStyle={{ color: 'white' }}
                        calendarColor={'#29459C'}
                        dateNumberStyle={{ color: 'white' }}
                        dateNameStyle={{ color: 'white' }}
                        highlightDateNumberStyle={{ color: 'white' }}
                        highlightDateNameStyle={{ color: 'white' }}
                        disabledDateNameStyle={{ color: 'grey' }}
                        disabledDateNumberStyle={{ color: 'grey' }}
                        iconContainer={{ flex: 0.1 }}
                        iconLeftStyle={{ tintColor: 'white' }}
                        iconRightStyle={{ tintColor: 'white' }}
                        onDateSelected={(date) => {
                            setDate(date);
                        }}
                        selectedDate={date}
                    /> */}
                    <View marginT-10 style={{
                        width: '100%'
                    }}>
                        {/* Back arrow month with year and then next button array */}
                        <View row spread centerV>
                            <TouchableOpacity onPress={() => {
                                setDate(moment(date).subtract(1, 'month').toDate());
                            }} style={{
                                paddingLeft: 20,
                                paddingRight: 20
                            }}>
                                <Icon name="arrow-back" width={24} height={24} fill={colors.primary} />
                            </TouchableOpacity>
                            <Text h1>{moment(date).format('MMMM YYYY')}</Text>
                            <TouchableOpacity onPress={() => {
                                setDate(moment(date).add(1, 'month').toDate());
                            }} style={{
                                paddingLeft: 20,
                                paddingRight: 20
                            }}>
                                <Icon name="arrow-forward" width={24} height={24} fill={colors.primary} />
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View paddingH-10 paddingT-15>
                        <CustomSelect
                            placeholder="Select Group"
                            value={currentGroup}
                            onChange={(value) => {
                                setCurrentGroup(value);
                                setCurrentEmployee(null);
                            }}
                            options={groups.map((group) => {
                                return {
                                    label: group.name,
                                    value: group._id
                                }
                            })}
                        />

                        <View style={{
                            borderBottomWidth: 1.5,
                            borderBottomColor: '#e0e0e0',
                            marginVertical: 5
                        }} />

                        <CustomSelect
                            placeholder="Select Agent"
                            value={currentEmployee}
                            onChange={(value) => {
                                setCurrentEmployee(value);
                            }}
                            options={employees.filter((employee) => {
                                console.log("CURRENT GROUP", currentGroup);
                                console.log("employee.group_id", employee.group_id);
                                return currentGroup ? employee.group_id == currentGroup : true;
                            })}
                        />
                    </View>
                </View>

                {id ?
                    <ScrollView >
                        <SalesActivityCard data={data} navigationFn={navigationFn} id={id} OpenModalCustom={OpenModalCustom} />
                    </ScrollView>
                    : <View marginT-50 center>
                        <ErrorMessage />
                        {currentEmployee ? <Text marginT-20 center>There is no activity for this date</Text> : <Text marginT-20 center>Select an employee to view their activity</Text>}
                        {currentEmployee == globalData.employee_id && <Text marginT-10 center>Click on the button below to add an activity</Text>}

                        {currentEmployee == globalData.employee_id && <View marginT-20 center>
                            <PrimaryButton
                                label="Add Activity"
                                onPress={async () => {
                                    const response = await api.customRoute("SalesActivities/action", {
                                        action: 'getIdByEmployeeIdAndDate',
                                        employee_id: currentEmployee,
                                        date: date,
                                        created_by_employee: globalData.employee_id,
                                    });

                                    OpenModalCustom({
                                        newModule: 'SalesActivities',
                                        field_key: 'sales_activity',
                                        array: false,
                                        params: {
                                            viewOnly: false,
                                            isEdit: true,
                                            id: response.data,
                                        }
                                    })
                                }}
                            />
                        </View>}
                    </View>}
            </View>
        </View >
    )
})

export default SalesActivityScreen

export const SalesActivityCard = ({ data, navigationFn, id, OpenModalCustom }) => {
    const [expandedSection, setExpandedSection] = useState(null);

    return <View marginT-10 padding-10>
        <Card padding-20>
            <View row>
                <View flex-1>
                    <Text marginB-5 lightCardTitle>Record By</Text>
                    <Text formlabel>{data?.created_by?.name}</Text>
                </View>
                <View flex-1>
                    <Text marginB-5 lightCardTitle>Date Of Report</Text>
                    <Text formlabel>{moment(data?.createdAt).format('DD MMM, YYYY')}</Text>
                </View>
                <View flex-1>
                    <Text marginB-5 lightCardTitle>Time</Text>
                    <Text formlabel>{moment(data?.createdAt).format('hh:mm A')}</Text>
                </View>
            </View>
        </Card>
        <Card padding-20 marginT-10>
            <View row spread>
                <Text h1>Sales Activity</Text>
                <TouchableOpacity onPress={() => {
                    OpenModalCustom({
                        newModule: 'SalesActivities',
                        field_key: 'sales_activity',
                        array: false,
                        params: {
                            viewOnly: false,
                            isEdit: true,
                            id: id,
                        }
                    })
                }}>
                    <Icon name="edit-outline" width={20} height={20} fill={colors.primary} />
                </TouchableOpacity>
            </View>
        </Card>
        <Card padding-20 marginT-10 marginB-80>
            {SalesActivityExpandable.map((item, index) => {
                return <ExpandableSection
                    key={index.toString()}
                    sectionHeader={
                        <View flex centerV marginB-0 style={{
                            width: '100%',
                        }}>
                            <Text marginT-10 h1 style={{
                                fontSize: 15,
                                marginTop: Platform.OS == 'web' ? 10 : 0,
                            }}>{item.label}</Text>
                            {Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                                <View
                                    style={{
                                        borderStyle: 'dashed',
                                        borderWidth: 1,
                                        borderColor: '#d9d9d9',
                                        margin: -2,
                                        marginTop: 10,
                                    }}>
                                    <View style={{ height: 10, width: 200 }} />
                                </View>
                            </View> : <View style={{
                                borderBottomColor: '#d9d9d9',
                                borderBottomWidth: 1,
                                width: '100%',
                                marginBottom: 0,
                                marginTop: 10,
                                borderStyle: 'dashed',
                            }} />}
                        </View>
                    }
                    expanded={true}
                    onPress={() => {
                        if (expandedSection === index) {
                            setExpandedSection(null);
                        } else {
                            setExpandedSection(index);
                        }
                    }}
                >
                    <View >
                        {/* field.label */}
                        {item.fields.filter((field) => field.label).map((field, field_index) => {
                            return field.label ? <View key={field_index.toString()} row spread style={{
                                paddingVertical: 5,
                                backgroundColor: field_index % 2 == 0 ? '#CAF4FF' : 'white',
                            }}>
                                <Text darkCardTitle>{field.label}</Text>
                                {data ? <Text darkCardTitle>{data[field.key] >= 0 || data[field.key] <= 0 ? `${field.currency ? "S$ " : ""}` + data[field.key] : ''}</Text> : <Text darkCardTitle></Text>}
                            </View> : null
                        })}
                        {/* {item.fields.map((field, field_index) => {
                            return <View key={field_index.toString()} row spread marginT-10 style={{
                                marginBottom: field_index == item.fields.length - 1 ? 20 : 0,
                            }}>
                                <Text darkCardTitle>{field.label}</Text>
                                {data ? <Text darkCardTitle>{data[field.key] >= 0 || data[field.key] <= 0 ? `${field.currency ? "S$ " : ""}` + data[field.key] : ''}</Text> : <Text darkCardTitle></Text>}
                            </View>
                        })} */}
                    </View>
                </ExpandableSection>
            })}

            <View marginT-10>
                <Text darkCardTitle>Sales Remarks</Text>
                <Text darkCardTitle marginT-5>{data?.sales_remarks}</Text>
            </View>
        </Card>
    </View>
}
