import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const meetingTypesfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Title',
            key: 'name',
            type: 'text',
            placeholder: 'Enter Title',
            visible: true,
            width: '100%',
            required: true,
        },
        {
            label: 'Description',
            key: 'description',
            type: 'textarea',
            placeholder: 'Enter Description',
            visible: true,
            width: '100%',
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const meetingTypesMain = {
    fields: meetingTypesfields,
    mobileCard: null
}