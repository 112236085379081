// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, RefreshControl } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, Avatar, TouchableOpacity } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, DangerButton, Heading, Row, SecondaryButton, CustomTextField, Label, CustomCheckbox } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import dayjs from "dayjs"
import { Camera, CameraType } from 'expo-camera';
import Svg, { G, Path } from "react-native-svg"
const isDesktop = Dimensions.get('window').width >= 768;


const Footer = (props) => {
    return (
        <View style={{
            backgroundColor: '#F9F5F2',
            width: '100%',
            padding: 40,
        }}>
            <View style={{
                backgroundColor: '#F9F5F2',
                display: 'flex',
                flexDirection: isDesktop ? 'row' : 'column',
                width: '100%',
            }}>
                <View padding-35 style={{
                    width: isDesktop ? '33%' : '100%',
                }}>
                    <Col width={"100%"} style={{
                        textAlign: 'left',
                    }}>
                        <View style={{
                            fontWeight: 'bold',
                            paddingBottom: 15,
                            textTransform: 'uppercase',
                        }}>
                            <Text style={{
                                fontFamily: 'SourceSansProSemiBold',
                                fontSize: 16
                            }}>INFORMATION</Text>
                        </View>
                        <View>
                            <Text>My Account</Text>
                        </View>
                        <View>
                            <Text>My Cart</Text>
                        </View>
                        <View>
                            <Text>Contact Us</Text>
                        </View>
                    </Col>
                </View>

                <View padding-35 style={{
                    width: isDesktop ? '33%' : '100%',
                }}>
                    <Col width={"100%"} style={{
                        textAlign: 'left',
                    }}>
                        <View style={{
                            fontWeight: 'bold',
                            paddingBottom: 15,
                            textTransform: 'uppercase',
                        }}>
                            <Text style={{
                                fontFamily: 'SourceSansProSemiBold',
                                fontSize: 16
                            }}>CUSTOMER SERVICE</Text>
                        </View>
                        <View>
                            <Text>Privacy Policy</Text>
                        </View>
                        <View>
                            <Text>Terms & Conditions</Text>
                        </View>
                        <View>
                            <Text>Customer Service</Text>
                        </View>
                    </Col>
                </View>

                <View padding-35 style={{
                    width: isDesktop ? '33%' : '100%',
                }}>
                    <Col width={"100%"} style={{
                        textAlign: 'left',
                    }}>
                        <View style={{
                            fontWeight: 'bold',
                            paddingBottom: 15,
                            textTransform: 'uppercase',
                        }}>
                            <Text style={{
                                fontFamily: 'SourceSansProSemiBold',
                                fontSize: 16
                            }}>CONTACT US</Text>
                        </View>
                        <View>
                            <Text>E-4/90 Arera Colony, 10 No. Market, Bhopal, Madhya Pradesh 462039</Text>
                        </View>
                        <View>
                            <Text>Phone: +91-9503340504</Text>
                        </View>
                        <View>
                            <Text>Email: hello@ikaki.co</Text>
                        </View>
                    </Col>
                </View>
            </View>

            <View style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
            }}>

                <View marginB-15 style={{
                    backgroundColor: '#CBCBCB',
                    height: '2px',
                    width: '100%',
                }}></View>
                <Text>© 2023, Ikaki. All Rights Reserved.</Text>
            </View>
        </View>
    );
};

export default Footer;