import React, { useEffect } from "react";
import { View, Card, Text, Image, TouchableOpacity } from "react-native-ui-lib";
import { CustomDatePicker, CustomSelect } from "../../mycomponents/DynamicForm";
import dayjs from 'dayjs';
import ImageView from "react-native-image-viewing";
import { Platform } from "react-native";
import axios from "axios";

export const attendanceRegisterfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const CheckInImages = React.memo(({ field, useForm, tKey, tIndex }) => {
        const check_in_images = useForm((state) => state.formObject['check_in_images']);
        const [visible, setIsVisible] = React.useState(false);

        const images = check_in_images.map((check_in_image) => {
            return {
                uri: check_in_image.url,
            }
        });

        return <View row spread style={{
            flexWrap: 'wrap',
        }}>
            {check_in_images.map((check_in_image, index) => <TouchableOpacity
                onPress={() => setIsVisible(true)}
                marginR-5 marginB-5 key={index}>
                <Image source={{ uri: check_in_image.url }} style={{
                    width: 100,
                    height: 100,
                    resizeMode: 'cover',
                }} />
            </TouchableOpacity>)}
            <ImageView
                images={images}
                imageIndex={0}
                visible={visible}
                onRequestClose={() => setIsVisible(false)}
            />
        </View>
    });

    const formFields = [
        {
            type: 'divider',
            heading: 'Basic Details',
            visible: true,
            span: 24,
        },

        {
            label: 'Employee',
            key: 'employee.name',
            type: 'text',
            placeholder: 'Enter the user ID',
            visible: true,
            width: '50%',
            readOnly: true,
            disabled: true,
        },
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter the date',
            visible: true,
            width: '50%',
            readOnly: true,
            disabled: true,
        },
        {
            label: 'Status',
            key: 'status',
            type: 'select',
            placeholder: 'Select the status',
            visible: true,
            width: '50%',
            options: [
                { label: 'Present', value: 'Present' },
                { label: 'Absent', value: 'Absent' },
                { label: 'Rest Day', value: 'Rest Day' },
                { label: 'Holiday', value: 'Holiday' },
                { label: 'Paid Leave', value: 'Paid Leave' },
                { label: 'Unpaid Leave', value: 'Unpaid Leave' },
                { label: 'Half Day Paid Leave', value: 'Half Day Paid Leave' },
                { label: 'Half Day Unpaid Leave', value: 'Half Day Unpaid Leave' },
            ],
        },
        // {
        //     label: 'Is Present',
        //     key: 'is_present',
        //     type: 'checkbox',
        //     placeholder: 'Select if present',
        //     visible: true,
        //     width: '50%',
        // },
        // {
        //     label: 'Check In Time',
        //     key: 'check_in_time',
        //     type: 'text',
        //     placeholder: 'Enter the check in time',
        //     visible: true,
        //     width: '50%',
        // },
        // {
        //     label: 'Check Out Time',
        //     key: 'check_out_time',
        //     type: 'text',
        //     placeholder: 'Enter the check out time',
        //     visible: true,
        //     width: '50%',
        // },

        // {
        //     label: 'Check In Remark',
        //     key: 'check_in_remark',
        //     type: 'text',
        //     placeholder: 'Enter the check in remark',
        //     visible: true,
        //     width: '50%',
        // },
        // {
        //     label: 'Check Out Remark',
        //     key: 'check_out_remark',
        //     type: 'text',
        //     placeholder: 'Enter the check out remark',
        //     visible: true,
        //     width: '50%',
        // },

        {
            label: 'Total Time Worked (Based on actual check in/out)',
            key: 'total_time_worked',
            type: 'text',
            placeholder: 'Enter the total time worked',
            visible: true,
            width: '50%',
            disabled: true,
        },

        {
            label: 'Checked In From Unauthorized Location',
            key: 'is_out_of_location',
            type: 'checkbox',
            placeholder: 'Select if out of radius',
            visible: true,
            width: '50%',
        },

        {
            type: 'divider',
            heading: 'Log Details',
            visible: true,
            span: 24,
        },

        // {
        //     "check_in_time": "2023-10-07 21:58:06",
        //     "check_in_location_name": null,
        //     "check_out_time": null,
        //     "check_out_location_name": null,
        //  }

        {
            label: '',
            key: 'timings',
            type: 'table',
            placeholder: '',
            visible: true,
            editable: true,
            width: '100%',
            columns: [
                {
                    "field": "check_in_time",
                    "accessor": "check_in_time",
                    "label": "Check In Time",
                    "Header": "Check In Time",
                    "editable": false,
                    "type": "time",
                    "visible": true,
                    "order": 1
                },
                {
                    "field": "check_in_location_name",
                    "accessor": "check_in_location_name",
                    "label": "Check In Location",
                    "Header": "Check In Location",
                    "editable": true,
                    "type": "text",
                    "visible": true,
                    "order": 2
                },
                {
                    "field": "check_out_time",
                    "accessor": "check_out_time",
                    "label": "Check Out Time",
                    "Header": "Check Out Time",
                    "editable": false,
                    "type": "time",
                    "visible": true,
                    "order": 3
                },
                {
                    "field": "check_out_location_name",
                    "accessor": "check_out_location_name",
                    "label": "Check Out Location",
                    "Header": "Check Out Location",
                    "editable": true,
                    "type": "text",
                    "visible": true,
                    "order": 4
                },
            ]
        },

        {
            type: 'divider',
            heading: 'Check In Images',
            visible: true,
            span: 24,
        },

        {
            type: 'custom',
            key: 'check_in_images',
            visible: true,
            width: '100%',
            component: CheckInImages,
        },

        {
            type: 'divider',
            heading: 'Advance Details',
            visible: true,
            span: 24,
        },

        {
            label: 'Late Check In Timing',
            key: 'late_check_in_timing',
            type: 'text',
            placeholder: 'Enter the late check in timing',
            visible: true,
            width: '25%',
        },

        // {
        //     label: 'Early Check Out Timing',
        //     key: 'early_check_in_timing',
        //     type: 'number',
        //     placeholder: 'Enter the early check out timing',
        //     visible: true,
        //     width: '25%',
        // },

        {
            label: 'Early Check Out Timing',
            key: 'early_check_out_timing',
            type: 'text',
            placeholder: 'Enter the early check out timing',
            visible: true,
            width: '25%',
        },

        {
            label: 'UT Timing',
            key: 'ut_timing',
            type: 'text',
            placeholder: 'Enter the UT timing',
            visible: true,
            width: '25%',
        },
        {
            label: 'OT Timing',
            key: 'ot_timing',
            type: 'text',
            placeholder: 'Enter the OT timing',
            visible: true,
            width: '25%',
        },


        //   {
        //       label: 'Is Approved',
        //       key: 'is_approved',
        //       type: 'checkbox',
        //       placeholder: 'Select if approved',
        //       visible: true,
        //       width: '50%',
        //   },
        //   {
        //       label: 'Approved By',
        //       key: 'approved_by',
        //       type: 'text',
        //       placeholder: 'Enter the approved by',
        //       visible: true,
        //       width: '50%',
        //   },
        //   {
        //       label: 'Approved At',
        //       key: 'approved_at',
        //       type: 'datetime',
        //       placeholder: 'Enter the approved at',
        //       visible: true,
        //       width: '50%',
        //   }
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const attendanceRegisterMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

const QuickFilterComponent = ({ customTabFilter, setCustomTabFilter }) => {
    const [value, setValue] = React.useState({
        startDate: dayjs().startOf('day').toDate(),
        endDate: dayjs().endOf('day').toDate(),
        employee_id: null,
    });

    const [dateTime, setDateTime] = React.useState(null);
    const [allowClear, setAllowClear] = React.useState(true);
    const [onBlur, setOnBlur] = React.useState(null);

    useEffect(() => {
        let filter = {
            ...customTabFilter,
        };

        if (value.startDate && value.endDate) {
            filter['date'] = {
                $gte: value.startDate,
                $lte: value.endDate,
            };
        }

        if (value.employee_id) {
            filter['employee_id'] = value.employee_id;
        }

        setCustomTabFilter(filter);
    }, [value]);

    return <View spread row marginB-10>
        <View style={{
            width: 300
        }}>
            <CustomDatePicker allowClear={false} range={true} value={[value.startDate, value.endDate]} onChange={(date) => {
                setValue({
                    ...value,
                    startDate: date[0],
                    endDate: date[1],
                });
            }} />
        </View>

        <View style={{
            width: 300
        }}>
            <CustomSelect value={value.employee_id} options={[]} onChange={() => {

            }} />
        </View>
    </View>;
}

export const attendanceRegisterMain = {
    fields: attendanceRegisterfields,
    mobileCard: null,
    QuickFilterComponent: QuickFilterComponent,
}

