// title: { type: String, required: true },
// notification_type_id: { type: Types.ObjectId, default: null, ref: 'AnnouncementTypes' }, 
// notification_type: { type: mongoose.Schema.Types.Mixed, default: {} }, 
// description: { type: String, required: false }, 
// publish_date: { type: Date, required: false }, 
// expiry_date: { type: Date, required: false }, 
// share_notification_via: { type: String, enum: ['Email', 'Notification', 'Both'], default: 'Both' }, 
// send_to: { type: String, enum: ['Employees', 'Lead'], default: 'Employees' },

// employees: [{ type: mongoose.Schema.Types.ObjectId, required: false }],
// leads: [{ type: mongoose.Schema.Types.ObjectId, required: false }],

import React, { useEffect } from "react";
import { View, Card, Text, Image, TouchableOpacity } from "react-native-ui-lib";
import ListingScreen from "../../../screens/MainScreens/CRUD/ListingScreen";
import moment from "moment";
import Svg, { Ellipse, G, Path, Defs, ClipPath } from "react-native-svg"
import RenderHtml from 'react-native-render-html';
import { Dimensions, Platform } from "react-native";
import { CustomCheckbox, CustomNumberInput, CustomTextField, DangerButton, SecondaryButton } from "../../../mycomponents/DynamicForm";


export const notificationfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const formFields = [
        {
            label: 'Title',
            key: 'title',
            type: 'text',
            placeholder: 'Enter Title',
            visible: true,
            width: '100%',
            required: true,
            tab: "General",
        },
    ];


    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '100%'
            });
        });
    }

    return formFields;
}

export const notificationMobileCard = ({ item, index, getFieldValue, onRowClick, valueText, columns, DeleteCard, actionFunctions, SidebarStatus }) => {

    return <Card flex padding-10 style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: 10 }}>
        <View padding-10 flex>

            <View marginB-10 row spread style={{ width: '100%' }}>
                <View center backgroundColor="#9ec1e9" style={{ width: 60, height: 60, borderRadius: 10 }}>
                    <Text style={{ fontSize: 24, color: 'white' }}>{moment(item.createdAt).format('DD')}</Text>
                    <Text style={{ fontSize: 12, color: 'white' }}>{moment(item.createdAt).format('ddd')}</Text>
                </View>

                <View flex marginL-10>
                    <View row>
                        <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                            {moment(item.createdAt).format('MMMM YYYY')}
                        </Text>
                    </View>

                    <View>
                        <View flex>
                            <View row flex>
                                <View>
                                    <Text lightCardTitle>{"Title"}: </Text>
                                </View>
                                <View flex>
                                    <Text>{item.title}</Text>
                                </View>
                            </View>
                            <View row flex>
                                <View>
                                    <Text lightCardTitle>{"Body"}: </Text>
                                </View>
                                <View flex>
                                    <Text>{item.body}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>

        </View>
        {/* </TouchableOpacity> */}
        {/* {(actionFunctions && actionFunctions(item)?.length > 0) ? <TouchableOpacity>
            <SidebarStatus color={"#397CF2"}
                text={item.status}
                actionFunctions={actionFunctions} item={item} radius={10} />
        </TouchableOpacity> : null} */}
    </Card>;
}

export const notificationMain = {
    fields: notificationfields,
    mobileCard: notificationMobileCard,
}