import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { Col, CustomCheckbox, Row } from "../../mycomponents/DynamicForm";

export const rolefields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

  const PermissionsComponent = ({ field, useForm, tKey, tIndex }) => {
    const defaultPermissions = {
      Employees: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Groups: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      AgencyTeams: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      CftBatches: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Roles: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Goals: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Announcements: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      AnnouncementTypes: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      ChallengeAnswers: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Challenges: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Employees: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      MembershipPaymentFees: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      PaymentTypes: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      KpiCategories: {
        view: true,
        // create: true,
        update: true,
        // delete: true
      },
      JointFieldWorks: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      LeadMeetings: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Leads: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      LeadSources: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Policies: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Sales: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Meetings: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      MeetingsAttendance: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      MeetingTypes: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      CourseGroups: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Courses: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      CourseSessionAttendances: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      CourseSessions: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Locations: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Reports: {
        view: true,
      },
    };

    let permissions = useForm((state) => state.formObject['permissions']) || defaultPermissions;
    const setFormObject = useForm((state) => state.setFormObject);

    permissions = { ...defaultPermissions, ...permissions };

    for (let key in permissions) {
      permissions[key] = { ...defaultPermissions[key], ...permissions[key] };
    }

    const formattedText = (text) => {
      return text.split('_').map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');
    }

    const formattedTitle = (text) => {
      text = text.replace('report/', '');

      return text.split(/(?=[A-Z])/).map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');
    }

    return <View style={{ width: '100%' }}>
      <Row style={{ width: '100%' }}>
        {Object.keys(permissions).map((key, index) => {
          return <Col width={"20%"} key={index}>
            <View key={index} style={{ width: '100%' }}>

              <View row spread paddingB-3 marginB-8 style={{ width: '100%', borderBottomColor: '#d9d9d9', borderBottomWidth: 1, borderStyle: 'dashed' }}>
                <Text h1>{formattedTitle(key)}</Text>

                <View row>
                  {view ? <></> : <CustomCheckbox
                    value={Object.keys(permissions[key]).every((subKey) => permissions[key][subKey])}
                    indeterminate={Object.keys(permissions[key]).some((subKey) => permissions[key][subKey])}
                    onChange={(value) => {
                      const newPermissions = { ...permissions };
                      newPermissions[key] = { ...newPermissions[key] };
                      Object.keys(newPermissions[key]).forEach((subKey) => {
                        newPermissions[key][subKey] = value;
                      });
                      setFormObject({
                        permissions: newPermissions
                      });
                    }} />}
                </View>
              </View>

              {Object.keys(permissions[key]).map((subKey, subindex) => {
                return <View row key={subindex}>
                  <View marginT-3>
                    {!view ? <CustomCheckbox value={permissions[key][subKey]} onChange={(value) => {
                      const newPermissions = { ...permissions };
                      newPermissions[key] = { ...newPermissions[key], [subKey]: value };
                      setFormObject({
                        permissions: newPermissions
                      });
                    }} /> : <Text marginR-10 style={{
                      marginTop: -3,
                    }}>{permissions[key][subKey] ? 'Yes' : 'No'}</Text>}
                  </View>
                  <Text>{formattedText(subKey)}</Text>
                </View>
              })}
            </View>
          </Col>
        })}
      </Row>
    </View >
  }

  const formFields = [
    {
      type: 'divider',
      heading: 'Role Details',
      visible: true,
      span: 24,
    },
    {
      label: 'Name',
      key: 'name',
      type: 'text',
      placeholder: 'Enter role Name',
      visible: true,
      width: '25%',
      required: true,
    },

    {
      width: '100%',
      key: 'permissions',
      type: 'custom',
      visible: true,
      component: PermissionsComponent,
    }
  ];

  const CustomFields = response.CustomFields;

  if (CustomFields && CustomFields.length > 0) {
    CustomFields.forEach((field) => {
      formFields.push({
        label: field.name,
        key: 'cf.' + field.key,
        type: field.type,
        placeholder: field.placeholder,
        visible: true,
        width: '25%'
      });
    });
  }

  return formFields;
}

export const roleMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const roleMain = {
  fields: rolefields,
  mobileCard: null
}